export enum RoleType {

  ADMIN = 'Admin',
  CROWD_CONTROLLER = 'Crowd Controller',
  DATA_ANALYST = 'Data Analyst',
  DUTY_MANAGER = 'Duty Manager',
  GENERAL_MANAGER = 'General Manager',
  LICENSEE = 'Licensee',
  MARKETING = 'Marketing',
  OPERATOR = 'Operator',
}
