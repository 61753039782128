import { UserRoleClaim } from './userRoleClaim'

export interface IUserRole {
  claims: UserRoleClaim[]
  name: string
  id: string
  level: number

  copy(): IUserRole
  hasPermission(claim: string, permission: number): boolean
}

export class UserRole implements IUserRole {
  public claims: UserRoleClaim[]
  public name: string
  public id: string
  public level: number

  constructor(userRole?: IUserRole) {
    this.claims = (userRole?.claims) ? userRole.claims.map(c => new UserRoleClaim(c)) : []
    this.name = userRole?.name ?? ''
    this.id = userRole?.id ?? ''
    this.level = userRole?.level ?? 0
  }

  public copy(): UserRole {
    return new UserRole(this)
  }

  public hasPermission(claim: string, permission: number) {
    const roleClaim = this.claims.find(p => p.claim.toLowerCase() == claim.toLowerCase())
    if (roleClaim != null) {
      // tslint:disable-next-line:no-bitwise
      return (roleClaim.permission & permission) == permission
    }

    return false
  }
}
