import { VerificationType } from '../enums/verificationType'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { EventLog } from './eventLog'
import { IPerson, Person } from './person'

export interface IVerification extends IJsonIdentity {
  id: number
  duplicate?: IPerson | undefined
  duplicateEventLogs: EventLog[]
  eventLog: EventLog
  original: IPerson
  originalEventLogs: EventLog[]
  mergeFingerPin: boolean
  mergeProofOfId: boolean
  mergeProofOfIdPhoto: boolean
  typeId: VerificationType

  capitalize(): void
  copy(): IVerification
  convert(person?: Person): void
}

export class Verification implements IVerification {

  public id: number
  public duplicate?: Person | undefined = undefined
  public duplicateEventLogs: EventLog[]
  public eventLog: EventLog
  public original: Person
  public originalEventLogs: EventLog[]
  public mergeFingerPin: boolean
  public mergeProofOfId: boolean
  public mergeProofOfIdPhoto: boolean
  public typeId: VerificationType

  constructor(verification?: IVerification) {
    this.id = verification?.id ?? 0
    this.duplicate = verification?.duplicate ? new Person(verification.duplicate) : undefined
    this.eventLog = new EventLog(verification?.eventLog)
    this.original = new Person(verification?.original)
    this.originalEventLogs = (verification?.originalEventLogs != null) ? verification.originalEventLogs.map(e => new EventLog(e)) : []
    this.typeId = verification?.typeId ?? VerificationType.MERGE

    this.duplicateEventLogs = (this.duplicate != null) ? [this.eventLog] : []

    this.mergeFingerPin = this.duplicate ? this.duplicate?.hasFingerPin : false
    this.mergeProofOfIdPhoto = this.duplicate ? this.duplicate.proofOfId.photo?.exists ?? false : false
    this.mergeProofOfId = this.duplicate ? this.duplicate.proofOfId.scan?.exists ?? false : false
  }

  public capitalize(): void {
    if (this.duplicate != null) {
      this.duplicate.fullname = this.duplicate.givenNames + ' ' + this.duplicate.surname
      this.duplicate.capitalize()

    }

    this.original.fullname = this.original.givenNames + ' ' + this.original.surname
    this.original.capitalize()
  }

  public copy(): Verification {
    return new Verification(this)
  }

  public convert(person?: Person) {
    if (this.typeId == VerificationType.VERIFY && person != null) {
      this.duplicate = this.original.copy()
      this.duplicateEventLogs = [this.eventLog]

      this.original = person.copy()
      this.originalEventLogs = []
      this.typeId = VerificationType.MERGE
    }
    else if (this.typeId == VerificationType.MERGE && this.duplicate != null) {
      this.original = this.duplicate.copy()
      this.originalEventLogs = [this.eventLog]

      this.duplicate = undefined
      this.duplicateEventLogs = []

      this.typeId = VerificationType.VERIFY
    }
  }
}
