import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IEventType extends IJsonIdentity {
  type: string

  copy(): IEventType
}

export class EventType implements IEventType {
  public id: number
  public type: string

  constructor(eventType?: IEventType) {
    this.id = eventType?.id ?? 0
    this.type = eventType?.type ?? ''
  }

  public copy(): EventType {
    return new EventType(this)
  }
}
