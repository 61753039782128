import { Store } from 'vuex'

import { SelectedPersonsState } from './selectedPersonsState'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { QueryParams } from '@/ts/api/queryParams'
import { Person } from '@/ts/models/person'
import { ViewStatus } from '@/ts/enums/viewStatus'

import { getModule } from 'vuex-module-decorators'

import PersonsModule from '@/ts/store/search/children/personsModule'

import _ from 'lodash'

/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class PersonsState {

  /**
   * The Selected Persons State for displaying a list of Selected People
   * within the UI which should get updated as the selection changes
   * @property
   * @see {@link SelectedPersonsState}
   * @returns {SelectedPersonsState}
   */
  public selected: SelectedPersonsState

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PersonModule}
   * @returns {PersonModule}
   */
  private module: PersonsModule


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(PersonsModule, store)
    this.selected = new SelectedPersonsState(store)

    this.getTotalCount()
    this.setStatus()
  }


  /**
  * The total number of People who match the search criteria
  * @property
  * @returns {number}
  */
  public get count() {
    return this.module.count
  }


  /**
   * Whether there are more Persons that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return this.module.iPersons.length < this.count
  }

  /**
   * Whether the current State has retrieved any Persons to
   * indicate whether a search has already been peformed 
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.module.iPersons.length > 0
  }

  /**
  * The total People that are currently in the Store.
  * This might be less than the selected amount if not all records have been retrieved yet.
  * @property
  * @returns {number}
  */
  public get length() {
    return this.module.iPersons.length
  }

  /**
   * The list of People
   * @property
   * @returns {Person[]} The list of People
   */
  public get people() {
    return this.module.iPersons.map(i => new Person(i))
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }

  /**
  * The current index of the Person carousel slide.
  * @property
  * @returns {number}
  */
  public get slideIndex() {
    return this.module.slideIndex
  }

  public set slideIndex(value: number) {
    this.module.setSlideIndex(value)
  }

  /**
  * The total number of People who are in the database and can be queried.
  * @property
  * @returns {number}
  */
  public get totalCount() {
    return this.module.totalCount
  }


  /**
   * Clears the entire state and child states
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
    this.selected.clear()
    this.status = ViewStatus.NONE
  }

  /**
  * Clears all results and selected people
  * @function
  * @returns {void} 
  */
  public clearResults() {
    this.selected.clear()
    this.module.clearResults()
  }

  /**
   * Retrieves a list of People from the Store.
   * @function
   * @returns {Person[]} The list of People
   */
  public async fetch(queryParams: QueryParams) {
    this.status = (queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getPersons(queryParams)
  }

  /**
  * Retrieves an ID by index
  * @function
  * @returns {number} index
  */
  public idAtIndex(index: number) {
    return this.people[index].id
  }

  /**
   * Retrieves the current index of the person by id
   * @function
   * @returns {number} id
   */
  public indexOf(id: number) {
    return this.module.iPersons.findIndex(p => p.id == id)
  }

  /**
   * Retrieves a Person by Id
   * @function
   * @returns {number} id
   */
  public person(id: number) {
    return this.people.find(p => p.id == id)
  }

  /**
* Retrieves a Person by index
* @function
* @returns {number} index
*/
  public personAtIndex(index: number) {
    return this.people[index]
  }

  /**
   * Retrieves a fresh list of People in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {Person[]} The list of sorted People
   */
  public async sort(context: ISortContext) {
    this.status = ViewStatus.SORTING
    let queryParams = new QueryParams()
    queryParams = this.queryParams.sortedBy(context)
    return await this.getPersons(queryParams)
   
  }

  /**
   * Replaces the Person current stored in State with the new Person
   * @function
   * @param {Person} person - The person to update
   * @return {Person[]} The list of sorted People
   */
  public update(person: Person) {
    this.module.update(person)
  }


  /**
   * Retrieves a list of Person from the Store using the current state
   * Query Params that have been populated from the UI. 
   * @private
   * @function
   * @returns {EventLog[]} The list of Bans for the current page
   */
  private async getPersons(queryParams: QueryParams) {
    try {
      this.queryParams = queryParams
      await this.module.getPersons(queryParams)
      this.status = ViewStatus.SUCCEEDED
      return this.people
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a list of Person from the Store using the current state
   * Query Params that have been populated from the UI.
   * @private
   * @function
   * @returns {EventLog[]} The list of Bans for the current page
   */
  private getTotalCount() {
    try {
      this.module.getTotalCount()
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Sets the current status based on whether People have already been
   * retrieved or not
   * private
   * @function
   * @return {void}
   */
  private setStatus() {
    this.status = (this.module.iPersons.length > 0) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }
}
