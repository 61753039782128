import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Person } from '@/ts/models/person'
import { PersonState } from '@/ts/states/person/personState'
import { PersonStatusType } from '@/ts/enums/personStatusType'
import { QueryParams } from '@/ts/api/queryParams'
import { ToolboxRepository } from '@/ts/repositories/toolboxRepository'
import { Verification } from '@/ts/models/verification'
import { VerificationType } from '@/ts/enums/verificationType'
import { ViewStatus } from '@/ts/enums/viewStatus'

import MergeModule from '@/ts/store/toolbox/children/mergeModule'

export class MergeState {

  /**
  * The LookupState for retrieving and displaying pre-defined
  * values in the UI
  * @property
  * @returns {LookupState}
  */
  public lookupState: LookupState

  /**
 * The current status
 * @property
 * @see {@link ViewStatus}
 * @returns {ViewStatus}
 */
  public status = ViewStatus.NONE

  /**
 * The current Verification
 * @property
 * @returns {Verification}
 */
  public verification = new Verification()


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link MergeModule}
   * @returns {MergeModule}
   */
  private module: MergeModule

  /**
   * The LookupState for retrieving and displaying pre-defined
   * values in the UI
   * @property
   * @returns {LookupState}
   */
  private person: PersonState


  /** CONSTRUCTORS */

  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * and instantiates all child states
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>, person: PersonState) {
    this.module = getModule(MergeModule, store)
    this.lookupState = new LookupState(store)
    this.person = person

    const queryParams = new QueryParams()
    queryParams.verificationType = VerificationType.MERGE
    this.queryParams = queryParams
  }


  /**
   * The total number of verifications in the Store which need processing
   * @property
   * @returns {boolean}
   */
  public get count() {
    return this.module.count
  }

  /**
  * Whether any Event Logs exist in the Verification record for the Original Person
  * @property
  * @returns {boolean}
  */
  public get hasEvents() {
    return (this.verification != null) ? this.verification.originalEventLogs.length > 0 : false
  }

  /**
    * Whether the current State has retrieved any Verifications of the type specified to
    * indicate whether a search has already been peformed and the page already
    * has results loaded.
    * @property
    * @returns {boolean}
    */
  public get hasState() {
    return this.module.iVerifications.length > 0
  }

  /**
   * The percentage of verifications which have been processed so far
   * @property
   * @returns {boolean}
   */
  public get progress() {
    return this.module.progress
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }


  /**
* The list of verifications retrieved from the Store that need processing
* @property
* @see {@link Verification}
* @returns {Verification}
*/
  public get verifications() {
    if (this.module.iVerifications.length > 0) {
      return this.module.iVerifications.map(v => new Verification(v))
    }

    return []
  }

  /**
   * Clears the entire state and resets current person
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
  }

  /**
   * Clears all Verifications
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.module.clearResults()
  }


  /**
 * Creates an ID Query on the current verification
 * @function
 * @returns {void}
 */
  public async createIdQuery() {
    this.status = ViewStatus.SUBMITTING
    try {
      const idQueryStatus = this.lookupState.personStatus(PersonStatusType.IDQUERY)!
      this.verification.original.personStatus = idQueryStatus
      this.verification.duplicate!.personStatus = idQueryStatus
      this.verification.capitalize()

      const repo = new ToolboxRepository()
      await repo.idQuery(this.verification)

      this.status = ViewStatus.SUCCEEDED

    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

  }

  /**
   * Retrieves a list of Verifications from the Store using the current state
   * Query Params that have been populated from the UI. 
   * @function
   * @returns {void}
   */
  public async fetch(queryParams: QueryParams = this.queryParams) {
    try {
      this.status = ViewStatus.IN_PROGRESS
      this.queryParams = queryParams
      await this.module.getVerifications(queryParams)
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Merges the current Verification record into one and moves next if specified.  This will
   * merge the Duplicate data into the Original data and removes the Duplicate from the database.
   * @function
   * @param {boolean} next = Whether to continue to the next record. Defaults to false
   * @returns {void}
  */
  public async submit() {
    this.status = ViewStatus.SUBMITTING
    try {
      if (this.verification.original.validate()) {
        const repo = new ToolboxRepository()
        this.verification.capitalize()
        await repo.merge(this.verification)

        this.status = ViewStatus.SUCCEEDED
      }
      else {
        this.status = ViewStatus.FAILED
      }
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Converts that Verification from a Merge to Verify, or Verify to Merge at the current index.
   * If converting from Verify to Merge, this typically means an existing person has been identified
   * as already existing so the Verification record is actually a Merge as it's a duplicate.
   * @function
   * @param {Person} person = An existing Person that has been identified matching and is thus, the original.
   * @returns {void}
   */
  public swap(person?: Person) {
    this.module.swap(person)
    this.verification = this.verifications[0]
    this.person.setPerson(this.verification.original)
  }

  /** Updates records with new person details
   * @param person
   */
  public update(person: Person) {
    this.module.update(person)
  }

  /**
   * Updates the progress of Verifications
   * @function
   * @returns {void}
   */
  public updateProgress() {
    this.module.updateProgress()
  }


  /**
 * Retrieves the current list of Events from memory or the Store for the current
 * Verification original person
 * @function
 * @returns {void}
 */
  public async fetchEvents() {
    try {
      this.status = ViewStatus.UPDATING
      await this.module.getEvents()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

}
