
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { AuthState } from './ts/states/auth/authState'
  import { LookupState } from './ts/states/lookup/lookupState'
  import { Routes } from './ts/constants/routes'

  import Navigation from '@/components/navigation/Navigation.vue'

  @Component({
    components: {
      Navigation
    }
  })
  export default class App extends Vue {


    /** PRIVATE PROPERTIES */


    private authState = new AuthState(this.$store)
    private lookupState = new LookupState(this.$store)



    /** OBSERVERS */

    @Watch('authState.isAuthenticated', { immediate: true })
    protected onAuthentication() {
      if (this.authState.isAuthenticated) {
        this.lookupState.fetch()
      }
    }

    @Watch('lookupState.isEmpty', { immediate: false })
    protected onLookupStateChanged() {
      if (this.authState.isAuthenticated) {
        this.lookupState.fetch()
      }
    }


    /** COMPUTED PROPERTIES */

    protected get isAuthenticated() {
      return this.authState.isAuthenticated
    }


    /** EVENTS */

    protected onIdle() {
      this.$root.$bvModal.hide('reminder')
      this.authState.logout()
      this.$router.push({ name: Routes.LOGIN })
    }

    protected onRemind() {
      this.$bvModal.msgBoxConfirm('It doesn\'t look like you\'re using NightKey Manager. You will be logged out in 5 minutes.', {
        id: 'reminder',
        okTitle: 'Log out',
        cancelTitle: 'Stay logged in',
      })
        .then(value => {
          if (value) {
            this.onIdle()
          }
        })
        .catch(err => {
          // An error occurred
        })
    }
  }

