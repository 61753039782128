import { Images } from '../constants/images'
import { DateFormatter } from '../formatters/dateFormatter'

export interface IMedia {
  id: number
  createdDate: Date
  expiryDate: Date
  readonly file: string
  modifiedDate: Date
  src: string
  thumbSrc: string

  copy(): IMedia
}

export class Media implements IMedia {
  public id: number
  public createdDate: Date
  public expiryDate: Date
  public file: string
  public modifiedDate: Date
  public src = ''
  public thumbSrc = ''

  constructor(media?: IMedia) {
    this.id = media?.id ?? 0
    this.createdDate = DateFormatter.parse(media?.createdDate, new Date())
    this.expiryDate = DateFormatter.parse(media?.createdDate, new Date())
    this.modifiedDate = this.createdDate
    this.file = media?.file ?? ''
    this.src = media?.src ?? ''
    this.thumbSrc = media?.thumbSrc ?? ''
  }

  public get exists() {
    return this.id > 0
  }

  public get hasImage() {
    return !this.src.endsWith(Images.DEFAULT) && !this.src.endsWith(Images.NOT_FOUND)
      && this.src.length > 0
  }

  public get hasThumbnail() {
    return !this.thumbSrc.endsWith(Images.DEFAULT) && !this.thumbSrc.endsWith(Images.NOT_FOUND)
      && this.thumbSrc.length > 0
  }

  public copy(): Media {
    return new Media(this)
  }
}
