import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { EventLog, IEventLog } from '../models/eventLog'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'

export class EventLogRepository extends ApiRepository<EventLog, IEventLog> {

  constructor(path = Paths.EVENTLOGS, type: new (i?: IEventLog) => EventLog = EventLog) {
    super(path, type)
  }

  public async getEvents(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<EventLog, IEventLog>(null, EventLog);
    }

    const url = this.urlPath(Paths.PERSONS, id, [this.path])
    return this.getResponse(url, queryParams)
  }

  public async getEventLogs(queryParams: QueryParams) {
   return this.getResponse(this.path, queryParams)
  }

  public async getTimeFrameEvents(eventLogId: number): Promise<EventLog[]> {
      const url = this.urlPath(this.path, eventLogId, [Paths.TIMEFRAME])
      const response = await axios.get(url)

      const iEvents = response.data as IEventLog[]
      const eventLogs = iEvents.map(i => new EventLog(i))
      eventLogs.forEach(e => e.timeFrameId = eventLogId)

      return eventLogs
  }
}
