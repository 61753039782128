// Import Modules
import Vue from 'vue'
import Vuex from 'vuex'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Vue2Storage } from 'vue2-storage'
import 'dotenv/config'

import InfiniteLoading from 'vue-infinite-loading'
import InputFacade from 'vue-input-facade'
import fullscreen from 'vue-fullscreen'
import Vidle from 'v-idle'
import VueCropper from 'vue-cropperjs'
import VueLazyLoad from 'vue-lazyload'
import VueLoaders from 'vue-loaders'
import VueSlickCarousel from 'vue-slick-carousel'
import VuePhoneNumberInput from 'vue-phone-number-input'


import VModal from 'vue-js-modal'

// Import Vues
import App from './App.vue'

// Import Routes
import router from './ts/router'
import store from './ts/store'

// Axios Defaults
import axios, { AxiosRequestConfig } from 'axios'
import { AuthState } from './ts/states/auth/authState'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const authState = new AuthState(store)
  if (authState.bearerToken != undefined) {
    axios.defaults.headers.common.Authorization = authState.bearerToken
  }

  return config
})

// AWS defaults
import AWS from 'aws-sdk'

AWS.config.update({
  apiVersion: '2010-12-01',
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.VUE_APP_AWS_DEFAULT_REGION
})


import './ts/font-awesome'
import './ts/pluralize'
import './ts/vee-validate'

import 'cropperjs/dist/cropper.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'


// Install Modules
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(InputFacade)
Vue.use(fullscreen)
Vue.use(Vidle)
Vue.use(Vue2Storage)
Vue.use(VueLazyLoad)
Vue.use(VueLoaders)
Vue.use(Vuex)

Vue.use(VModal, { dynamicDefault: { draggable: true } })

Vue.component('vue-cropper', VueCropper)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('vue-slick-carousel', VueSlickCarousel)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
