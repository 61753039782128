import { Store } from 'vuex'
import { AuthState } from '@/ts/states/auth/authState'
import { IEditable } from '../interfaces/editable'

export class Editor<T extends IEditable<T>> {

  /* PROPERTIES */

  public authState: AuthState
  public edit: T
  public original: T


  /* CONSTRUCTORS */

  constructor(original: T, store: Store<any>) {
    this.original = original
    this.edit = original.copy()
    this.authState = new AuthState(store)
  }

  /* COMPUTED PROPERTIES */

  public get isDirty() {
    return !this.original.isEqual(this.edit)
  }

  /* METHODS */

  public reset() {
    this.edit = this.original.copy()
  }

  public set(original: T) {
    this.original = original
    this.edit = original.copy()
  }
}
