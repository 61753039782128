import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Organisation, IOrganisation } from '@/ts/models/organisation'
import { OrganisationRepository } from '@/ts/repositories/organisationRepository'
import { QueryParams } from '@/ts/api/queryParams'
import { Route } from '@/ts/models/route'

/**
 * Local session storage of Organisations
 * @module
 */
@Module({ name: 'OrganisationsModule', namespaced: true })
export default class OrganisationsModule extends VuexModule {


  /**
   * The total number of Organisations which match the search criteria
   * @property
   * @returns {number}
   */
  public count = 0

  /**
   * The Organisation of the current NightKey user, or the selected Organisation if more than
   * one exists (i.e, for a NightKey Admin user)
   * @property
   * @see {@link Organisation}
   * @returns {Organisation}
   */
  public iOrganisation?= new Organisation()

  /**
   * The Organisations which have been retrieved so far
   * @property
   * @returns {Organisation[]}
   */
  public iOrganisations = new Array<IOrganisation>()

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
   * A list of Routes which form the navigation stack for the application.
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  public returnRoutes = new Array<Route>()

  /**
   * The Y scroll position of the current page
   * @property
   * @returns {number}
   */
  public scrollPosition = 0


  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.count = 0
    this.iOrganisation = undefined
    this.iOrganisations = []
    this.queryParams = new QueryParams()
    this.returnRoutes = []
    this.scrollPosition = 0
  }

  /**
  * Resets the organisation and organisations held in storage
  * @function
  * @returns {void}
  */
  @Mutation
  public clearResults() {
    this.count = 0
    this.iOrganisation = undefined
    this.iOrganisations = []
  }

  /**
  * Empties the navigation stack
  * @function
  * @returns {void}
  */
  @Mutation
  public clearRoutes() {
    this.returnRoutes = []
  }

  /**
  * Adds an Organisation to the Organisations list
  * @function
  * @returns {void}
  */
  @Mutation
  public add(organisations: Organisation[]) {
    this.iOrganisations.push(...organisations)
  }

  /**
  * Removes the last route from the navigation stack and returns it
  * @function
  * @returns {void}
  */
  @Mutation
  public popRoute() {
    return this.returnRoutes.pop()
  }

  /**
  * Adds a new route to the navigation stack.
  * @function
  * @returns {void}
  */
  @Mutation
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }

  /**
  * Stores the Organisation of the current NightKey use
  * @function
  * @returns {void}
  */
  @Mutation
  public setOrganisation(organisation: Organisation | undefined) {
    this.iOrganisation = organisation
  }

  /**
  * Stores an array of  selected Organisations, replacing any that might exist
  * @function
  * @returns {void}
  */
  @Mutation
  public set(organisations: Organisation[]) {
    this.iOrganisations = organisations
  }

  /**
  * Stores the count of results
  * @function
  * @returns {void}
  */
  @Mutation
  public setCount(count: number) {
    this.count = count
  }

  /**
  * Stores a copy of the Query Params
  * @function
  * @returns {void}
  */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
  * Stores the current scroll position
  * @function
  * @returns {void}
  */
  @Mutation
  public setScrollPosition(y: number) {
    this.scrollPosition = y
  }

 
  /**
   * Retrieves a list of Organisations from the API using the current state Query Params
   * that have been populated from the UI.
   * @function
   * @returns {void}
   */
  @Action
  public async getOrganisations(queryParams: QueryParams) {
    const repo = new OrganisationRepository()
    const response = await repo.getOrganisations(queryParams)

    this.context.commit('setCount', response.totalCount)
    this.context.commit((queryParams.currentPage == 1) ? 'set' : 'add', response.result)
  }
}
