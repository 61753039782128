import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthModule from '@/ts/store/auth/authModule'
import EventLogsModule from '@/ts/store/search/children/eventLogsModule'
import LookupModule from '@/ts/store/lookup/lookupModule'
import MergeModule from '@/ts/store/toolbox/children/mergeModule'
import OrganisationsModule from '@/ts/store/admin/organisationsModule'
import PersonModule from '@/ts/store/person/personModule'
import PersonsModule from '@/ts/store/search/children/personsModule'
import PoliceReportModule from '@/ts/store/toolbox/children/policeReportModule'
import SearchModule from '@/ts/store/search/searchModule'
import SelectedEventsModule from '@/ts/store/person/children/selectedEventsModule'
import SelectedPersonsModule from '@/ts/store/search/children/selectedPersonsModule'
import ToolboxModule from '@/ts/store/toolbox/toolboxModule'
import VerifyModule from '@/ts/store/toolbox/children/verifyModule'

import MediaModule from '@/ts/store/media/mediaModule'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    AuthModule,
    EventLogsModule,
    LookupModule,
    MergeModule,
    OrganisationsModule,
    PersonModule,
    PersonsModule,
    PoliceReportModule,
    SearchModule,
    SelectedEventsModule,
    SelectedPersonsModule,
    ToolboxModule,
    VerifyModule,
    MediaModule
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  strict: false //delete this line for production, it helps with debugging by forcing the state to only change with mutations
})

export default store

