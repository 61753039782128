
export interface IMatch {
  onDob: boolean
  onGender: boolean
  onName: boolean
  onPoi: boolean
}

export class Match implements IMatch {
  public onDob = false
  public onGender = false
  public onName = false
  public onPoi = false
}
