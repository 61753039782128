import { isPast } from 'date-fns'
import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { OrganisationType } from './organisationType'
import { TerminalLocation } from './terminalLocation'

export interface IOrganisation extends IJsonIdentity {
  abn?: string
  accessFrom?: Date
  accessTo?: Date
  address: string
  authorisationCode: string
  contact?: string
  country: string
  email?: string
  ipCidr?: string
  readonly isActive: boolean
  name: string
  organisationType: OrganisationType
  phone?: string
  readonly postalAddress: string
  postcode: string
  state: string
  suburb: string
  terminalLocations?: TerminalLocation[]

  copy(): IOrganisation
}

export class Organisation implements IOrganisation {
  
  public abn?: string
  public accessFrom?: Date
  public accessTo?: Date
  public address: string
  public authorisationCode: string
  public contact?: string
  public country: string
  public email?: string
  public id: number
  public ipCidr?: string
  public name: string
  public organisationType: OrganisationType
  public phone?: string
  public postcode: string
  public state: string
  public suburb: string

  public get isActive() {
    return (this.accessTo != null) ? isPast(this.accessTo) : true
  }

  public get postalAddress() {
    let postal = ''

    if (this.address?.trim().length > 0) {
      postal += `${this.address}, `
    }

    if (this.suburb?.trim().length > 0) {
      postal += `${this.suburb}, `
    }

    if (this.state?.trim().length > 0) {
      postal += `${this.state}, `
    }

    if (this.postcode?.trim().length > 0) {
      postal += `${this.postcode}, `
    }

    return postal.substr(0, postal.length - 2)
  }

  constructor(organisation?: IOrganisation)
  {
    this.abn = organisation?.abn
    this.accessFrom = DateFormatter.parse(organisation?.accessFrom)
    this.accessTo = DateFormatter.parse(organisation?.accessTo)
    this.address = organisation?.address ?? ''
    this.authorisationCode = organisation?.authorisationCode ?? ''
    this.contact = organisation?.contact
    this.country = organisation?.country ?? ''
    this.email = organisation?.email
    this.id = organisation?.id ?? 0
    this.ipCidr = organisation?.ipCidr
    this.name = organisation?.name ?? ''
    this.organisationType = new OrganisationType(organisation?.organisationType)
    this.phone = organisation?.phone
    this.postcode = organisation?.postcode ?? ''
    this.state = organisation?.state ?? ''
    this.suburb = organisation?.suburb ?? ''
  }

  public copy(): Organisation {
    return new Organisation(this)
  }
}
