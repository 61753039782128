import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { Pagination } from '@/ts/models/pagination'
import { PoliceReport } from '@/ts/models/policeReport'
import { PoliceReportRepository } from '@/ts/repositories/policeReportRepository'
import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
import { Route } from '@/ts/models/route'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'

import PoliceReportModule from '@/ts/store/toolbox/children/policeReportModule'

import _ from 'lodash'
import { blob } from 'aws-sdk/clients/codecommit'

export class PoliceReportsState {

/**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * A list of Police Reports
   * @property
   * @see {@link PoliceReport}
   * @returns {PoliceReport[]}
   */
  public reports = new Array<PoliceReport>()

  /**
  * The current Query Parameters used for the search which are populated from
  * form fields within the UI.
  * @property
  * @see {@link QueryParams}
  * @returns {QueryParams}
  */
  public queryParams = new QueryParams()

  /**
   * A Ban Editor that is used to store the original values of the editor along with the
   * changed/new values for editing purposes
   * @property
   * @see {@link BanEditor}
   * @returns {BanEditor}
   */
  public policeReport = new PoliceReport()

  /**
  * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PoliceReportModule}
   * @returns {PoliceReportModule}
   */
  private module: PoliceReportModule

  /**
  * The repository to fetch police reports
   * @private
   * @property
   * @see {@link PoliceReportRepository}
   * @returns {PoliceReportRepository}
   */
  private repo = new PoliceReportRepository()

  /**
 * The pagination used to manage the list of Polce Reports in the UI
 * and swapping between pages of results.
 * @private
 * @property
 * @see {@link Pagination}
 * @returns {Pagination}
 */
  private pagination = new Pagination<PoliceReport>()


  /** CONSTRUCTORS */

  /**
 * Instantiates a new State object and immediately retrieves Police Reports
 * from the API
 * @constructor
 * @param {Store} store - the local Vuex Store
 */
  constructor(store: Store<any>) {

    this.module = getModule(PoliceReportModule, store)
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'createdDate'
    this.queryParams.sortDesc = true
    this.status = (this.hasState) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
    if (!this.hasState) {
      this.fetch(this.queryParams)
    }
  }


  /**
  * The total number of Police Reports retrieved
  * @property
  * @returns {number}
  */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more Bans that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return this.count < this.totalPages
  }

  /**
  * Whether the current State has retrieved any Police Reports to
  * indicate whether a search has already been peformed and the page already
  * has results loaded.
  * @property
  * @returns {boolean}
  */
  public get hasState() {
    return this.count > 0
  }

  /**
 * Whether the current status is Loading
 * @property
 * @returns {boolean}
 */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The current mode used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link PoliceReportStatus}
   * @returns {PoliceReportStatus}
   */
  public get mode() {
    return this.module.mode
  }

  public set mode(value: PoliceReportStatus) {
    this.module.setMode(value)
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  /*public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }*/

  /**
  * The list of police reports retrieved from the Store that need processing
  * @property
  * @see {@link PoliceReport}
  * @returns {PoliceReport}
  */
  public get policeReports() {
    return this.pagination.page()
  }

  /**
* A key/value map to display Bans in a table
* @function
* @returns key/value pairs
*/
  public get tableFields() {
    const fields = [
      {
        class: 'wpx-50',
        key: 'statusId',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.Long)
        },
        key: 'createdDate',
        label: 'Created',
        sortable: false,
      },
      {
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.LongTime)
        },
        key: 'event.createdDate',
        label: 'Incident date',
        sortable: false,
      },
      {
        class: 'w-25',
        key: 'event.patron.person.fullname',
        label: 'Patron',
        sortable: false
      },
      {
        key: 'requestingOfficer',
        label: 'Officer',
        sortable: false
      },
      {
        key: 'event.patron.organisation.name',
        label: 'Organisation',
        sortable: false
      }
    ]

    return fields
  }
  /**
 * The total number of pages of resutls
 * @property
 * @returns {number}
 */
  public get totalPages() {
    return this.pagination.totalPages
  }

  /**
   * Clears all Police Reports
   * @function
   * @returns {void}
   */
  public clear() {
    this.reports = []
  }

  /**
   * Retrieves a list of Police Reports from the API.
   * @function
   * @returns {void}
   */
  public async fetch(queryParams: QueryParams = this.queryParams) {
    this.status = (queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING

    queryParams.policeReportStatus = (this.mode == PoliceReportStatus.REQUESTED) ? PoliceReportStatus.REQUESTED : PoliceReportStatus.ARCHIVED
    this.queryParams = queryParams
    return await this.getPoliceReports()
  }

  /**
 * Retrieves a list of Police Reports for the specified page.
 * If the current pages results have already been retrieved from the API it will
 * return them from memory, otherwise the results will be retrieved from the API
 * @function
 * @param {number} page - The page number
 * @returns {PoliceReport[]} The list of Police Reports
 */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.policeReports
      }

      this.status = ViewStatus.UPDATING
      return await this.getPoliceReports()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
 * Retrieves a fresh list of police reports in the new specified order
 * @function
 * @param {ISortContext} context - The property to sort by and the sort order
 * @return {PoliceReport[]} The list of sorted Police reports
 */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getPoliceReports()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  public popRoute() {
    const route = _.last(this.module.returnRoutes)
    this.module.popRoute()
    return route
  }

  /**
  * Adds a new route to the navigation stack.
  * This should be called when the user navigates away from a page but will
  * have the option to return back on the destination page.
  * stack with a new route
  * @function
  * @param {Route} route - The route to add to the stack
  * @param {boolean} replace - Whether to replace all existing routes with this route
  * @return {void}
  */
  public pushRoute(route: Route) {
    this.module.pushRoute(route)
  }

  /**
   * Sets the current Police Report
   * @function
   * @returns {void}
   */
  public setPoliceReport(policeReport: PoliceReport) {
    this.resetForm()
    this.policeReport = policeReport
  }


  /**
   * Resets the Police Report form
   * @function
   * @returns {void}
   */
  public resetForm() {
    this.status = ViewStatus.NONE
    this.policeReport = new PoliceReport()  
  }

  /**
   * Saves a Police Report for review
   * @function
   * @returns {void}
  */
  public async sendPoliceReport() {
    this.status = ViewStatus.IN_PROGRESS

    try {
      const repo = new PoliceReportRepository()
      await repo.save(this.policeReport)
      this.status = ViewStatus.SUCCEEDED
      this.resetForm()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
 * Deny a request of Police Report
 * @function
 * @returns {void}
*/
  public async rejectPoliceReport(policeReportId: number, comment: string) {
    this.status = ViewStatus.IN_PROGRESS

    try {
      await this.repo.rejectPoliceReportRequest(policeReportId, comment)
      this.status = ViewStatus.SUCCEEDED
      this.resetForm()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
* Approve a request Police Report
* @function
* @returns {void}
*/
  public async approvePoliceReport(policereportId: number, iBlob: Blob) {
    this.status = ViewStatus.IN_PROGRESS
 
    try {
      await this.repo.approvePoliceReportRequest(policereportId, iBlob)
      this.status = ViewStatus.SUCCEEDED
      this.resetForm()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  public async getPoliceReports() {
    try {
      const response = await this.repo.getPoliceReports(this.queryParams)
    
      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }


}
