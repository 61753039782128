import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { Editor } from './editor'
import { MessageSuggestion } from '../models/messageSuggestion'
import { Patron } from '../models/patron'
import { TerminalComment } from '../models/terminalComment'
import { TerminalCommentRepository } from '../repositories/terminalCommentRepository'
import { Store } from 'vuex'
import { Person } from '../models/person'

export class TerminalCommentEditor extends Editor<TerminalComment> {

  /* PROPERTIES */

  public isIdQuery = false
  public patrons = new Array<Patron>()
  public person = new Person()


  /* PRIVATE PROPERTIES */

  private dateRange = false
  private repo = new TerminalCommentRepository()
  private selectedSuggestion?: MessageSuggestion
  private terminalCommentSuggestions: MessageSuggestion[]

  constructor(original: TerminalComment, store: Store<any>, suggestions: MessageSuggestion[]) {
    super(original, store)
    this.terminalCommentSuggestions = suggestions
  }

  /* COMPUTED PROPERTIES */

  public get hasPatrons() {
    return this.patrons.length > 1 
  }

  public get isDateRange() {
    return this.dateRange
  }

  public set isDateRange(value: boolean) {
    this.dateRange = value
    if (!value) {
      this.edit.toDate = undefined
    }
  }

  public get showFrom() {
    return DateFormatter.format(this.edit.fromDate, DateStyle.Picker)
  }

  public set showFrom(value: string) {
    this.edit.fromDate = DateFormatter.parse(value)
  }

  public get showTo() {
    return DateFormatter.format(this.edit.toDate, DateStyle.Picker)
  }

  public set showTo(value: string) {
    this.edit.toDate = DateFormatter.parse(value)
  }

  public get suggestion() {
    return this.selectedSuggestion
  }

  public set suggestion(value: MessageSuggestion | undefined) {
    this.selectedSuggestion = value
    this.edit.comment = value?.message ?? ''
  }

  public get suggestions() {
    return this.terminalCommentSuggestions
  }


  /* PUBLIC METHODS */

  public async delete() {
    await this.repo.delete(this.edit)
    this.reset()
  }

  public reset() {
    this.edit = new TerminalComment()
    this.patrons = []
    this.dateRange = false
    this.original = new TerminalComment()
    this.selectedSuggestion = undefined
  }

  public async save() {
    if (this.edit.id == 0) {
      this.edit.createdBy = this.authState.user.userName
    }

    if (this.edit.toDate != null) {
      this.edit.toDate.setHours(23, 59, 59)
    }

    this.edit.modifiedBy = this.authState.user.userName
    this.edit.modifiedDate = new Date()
    this.edit.person = this.person

    const promises = new Array<Promise<any>>()
    if (this.isIdQuery) {
      promises.push(this.repo.save(this.edit))
    }
    else {
      this.patrons.forEach(p => {
        const tc = this.edit.copy()
        tc.patron = p

        const promise = this.repo.save(tc)
        promises.push(promise)
      })
    }

    await Promise.all(promises)
    this.reset()
  }
}
