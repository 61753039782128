import { Module, VuexModule, Mutation, MutationAction } from 'vuex-module-decorators'

import { Person, IPerson } from '@/ts/models/person'
import { PersonRepository } from '@/ts/repositories/personRepository'
import { Route } from '@/ts/models/route'
import { VerificationType } from '@/ts/enums/verificationType'


/**
 * Local session storage of the Toolbox
 * @module
 */
@Module({ name: 'ToolboxModule', namespaced: true })
export default class ToolboxModule extends VuexModule {

  /**
   * Whether the Administrator has entered fullscreen mode
   * @property
   * @returns {boolean}
   */
  public fullscreen = false

  /**
   * A list of people who partially match details of the current Person in the
   * Person State.
   * @property
   * @see {@link Person}
   * @returns {Person[]}
   */
  public iDuplicates = new Array<IPerson>()

  /**
   * The Verification Type for the Verification currently being processed
   * @property
   * @see {@link VerificationType}
   * @returns {VerificationType}
   */
  public mode = VerificationType.VERIFY

  /**
   * The Navigation Stack stored to assist a user in returning back to previous
   * screens.
   * @property
   * @returns {Route} The last route from the navigation stack
   */
  public returnRoutes = new Array<Route>()

  /**
  * The Tab Index selected for the carousel which appears in the UI to show events, duplicates etc...
  * @property
  * @returns {number}
  */
  public tabIndex = 0


  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.fullscreen = false
    this.iDuplicates = []
    this.mode = VerificationType.VERIFY
    this.returnRoutes = []
    this.tabIndex = 0
  }

  /**
   * Resets the duplicates in storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clearDuplicates() {
    this.iDuplicates = []
    this.tabIndex = 0
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  @Mutation
  public popRoute() {
    return this.returnRoutes.pop()
  }

  /**
   * Adds a new route to the navigation stack.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  @Mutation
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }


  /**
   * Stores the duplicates found which match the current Person.
   * @function
   * @returns {void}
  */
  @Mutation
  public setDuplicates(duplicates: Person[]) {
    this.iDuplicates = duplicates
  }

  /**
   * Stores whether the Administrator has entered fullscreen mode
   * @function
   * @returns {void}
   */
  @Mutation
  public setFullscreen(fullscreen: boolean) {
    this.fullscreen = fullscreen
  }

  /**
   * Stores the mode of the current Verification being processed
   * @function
   * @returns {void}
   */
  @Mutation
  public setMode(mode: VerificationType) {
    this.mode = mode
  }

  /**
   * Stores the current Tab Index for the displayed carousels
   * @function
   * @returns {void}
   */
  @Mutation
  public setTabIndex(index: number) {
    this.tabIndex = index
  }


  /**
   * Retrieves a list of duplicate People found by the API and stores them
   * @function
   * @param {number}
   * @returns {void}
   */
  @MutationAction
  public async getDuplicates(personId: number) {
      const repo = new PersonRepository()
      const duplicates = await repo.getDuplicates(personId)

      return { iDuplicates: duplicates }
  }
}
