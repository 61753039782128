/* eslint-disable */
import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { EventLog } from './eventLog'

export interface IPoliceReport extends IJsonIdentity {
  badgeNumber: string
  createdBy: string
  createdDate: Date
  readonly date: string
  event: EventLog
  incidentReportNumber: string
  officerEmailAddress: string
  officerPhoneNumber: string
  managerEmailAddress: string
  modifiedBy?: string
  modifiedDate?: Date
  requestingOfficer: string
  statusId: number
  file: string
  //pdf_id: number
  clear(): void
  copy(): IPoliceReport
  isEqual(policeReport: IPoliceReport): boolean
}

export class PoliceReport implements IPoliceReport  {

  public badgeNumber: string
  public createdBy: string
  public createdDate: Date
  public event: EventLog
  public id: number
  public incidentReportNumber: string
  public managerEmailAddress: string
  public modifiedBy?: string
  public modifiedDate?: Date
  public officerEmailAddress: string
  public officerPhoneNumber: string
  public requestingOfficer: string
  public statusId: number
  public file: string
  //public pdf_id: number
  

  constructor(policeReport?: IPoliceReport) {
    this.badgeNumber = policeReport?.badgeNumber ?? ''
    this.createdBy = policeReport?.createdBy?? ''
    this.createdDate = DateFormatter.parse(policeReport?.createdDate, new Date())
    this.event = new EventLog(policeReport?.event)
    this.id = policeReport?.id ?? 0
    this.incidentReportNumber = policeReport?.incidentReportNumber ?? ''
    this.managerEmailAddress = policeReport?.managerEmailAddress ?? ''
    this.modifiedBy = policeReport?.modifiedBy
    this.modifiedDate = DateFormatter.parse(policeReport?.modifiedDate, null)
    this.officerEmailAddress = policeReport?.officerEmailAddress ?? ''
    this.officerPhoneNumber = policeReport?.officerPhoneNumber ?? ''
    this.requestingOfficer = policeReport?.requestingOfficer ?? ''
    this.statusId = policeReport?.statusId ?? 0
    this.file = policeReport?.file ?? ''
  }

  public get date() {
    return DateFormatter.format(this.createdDate, DateStyle.Ordinal)
  }

  public clear(): void {
    this.badgeNumber = ''
    this.createdBy = ''
    this.createdDate = new Date()
    this.event = new EventLog()
    this.id = 0
    this.incidentReportNumber = ''
    this.managerEmailAddress = ''
    this.modifiedBy = undefined
    this.modifiedDate = undefined
    this.officerEmailAddress =''
    this.officerPhoneNumber = ''
    this.requestingOfficer = ''
    this.statusId = 0
    this.file = ''
  //  this.pdf_id = 0
  }

  public copy(): PoliceReport {
    return new PoliceReport(this)
  }

  public isEqual(policeReport: PoliceReport) {
    return this.badgeNumber == policeReport.badgeNumber
      && this.createdDate == policeReport.createdDate
      && this.event.id == policeReport.event.id
      && this.managerEmailAddress == policeReport.managerEmailAddress
      && this.incidentReportNumber == policeReport.incidentReportNumber
      && this.officerEmailAddress == policeReport.officerEmailAddress
      && this.officerPhoneNumber == policeReport.officerPhoneNumber
      && this.requestingOfficer == policeReport.requestingOfficer
  }
}
