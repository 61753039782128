import { Store } from 'vuex'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { AuthState } from '@/ts/states/auth/authState'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Pagination } from '@/ts/models/pagination'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { TerminalComment } from '@/ts/models/terminalComment'
import { TerminalCommentEditor } from '@/ts/editors/terminalCommentEditor'
import { TerminalCommentRepository } from '@/ts/repositories/terminalCommentRepository'
import { ViewStatus } from '@/ts/enums/viewStatus'


/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class TerminalCommentsState {

  /**
  * The Auth State for retrieving the current logged in user
  * @property
  * @see {@link AuthState}
  * @returns {AuthState}
  */
  public auth: AuthState

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
   * The selected Person the current Terminal Comments belong to
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
  */
  public person?: Person

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * A Terminal Comment Editor that is used to store the original values of the editor along with the
   * changed/new values for editing purposes
   * @property
   * @see {@link TerminalCommentEditor}
   * @returns {TerminalCommentEditor}
   */
  public terminalComment: TerminalCommentEditor


  /**
  * A back-end store for the current Terminal Comments, i.e, those which should be
  * displayed on a Terminal Station.
  * @private
  * @property
  * @see {@link TerminalComment}
  * @returns {TerminalComment[]}
  */
  private currentComments = new Array<TerminalComment>()

  /**
   * The LookupState for retrieving and displaying pre-defined
   * values in the UI
   * @property
   * @returns {LookupState}
   */
  private lookupState: LookupState

  /**
   * The pagination used to manage the list of Terminal Comments in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<TerminalComment>()


  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link TerminalCommentRepository}
   * @returns {TerminalCommentRepository}
   */
  private repo = new TerminalCommentRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store: Store<any>, person?: Person) {
    this.auth = new AuthState(store)
    this.lookupState = new LookupState(store)
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'fromDate'
    this.queryParams.sortDesc = true

    this.terminalComment = new TerminalCommentEditor(new TerminalComment(), store, this.lookupState.terminalCommentSuggestions)

    if (person != null) {
      this.queryParams.personId = person.id
      this.person = person
      this.fetch()
      this.fetchCurrent()
    }
  }


  /**
   * The total number of Terminal Comments for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
  * The current Terminal Comments for this Person/Patron, i.e, those which should be
  * displayed on a Terminal Station.
  * @property
  * @see {@link TerminalComment}
  * @returns {TerminalComment[]}
  */
  public get current() {
    return this.currentComments
  }

  /**
   * Whether this Person/Patron has any current Terminal Comments.
   * @private
   * @returns {TerminalComment[]}
   */
  public get hasCurrent() {
    return this.current.length > 0
  }

  /**
   * Whether there are more Terminal Comments that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current Person has more than one Patron account.
   * This will only be true when the NightKey user is an Admin who is not tied
   * to an Organisation and can therefore see multiple Patrons.
   * @property
   * @returns {boolean}
   */
  public get hasPatrons() {
    return this.person != null && this.person.patrons.length > 1
  }

  /**
   * Whether the current State has retrieved any Terminal Comments to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current Terminal Location has been edited
   * @property
   * @returns {boolean}
   */
  public get isDirty() {
    return this.terminalComment.isDirty
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The patrons for this Person
   * @property
   * @returns {Patron[]}
   */
  public get patrons() {
    return this.person != null ? this.person.patrons : []
  }

  /**
 * A key/value map to display Terminal Locations in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'wpx-50',
        key: 'isIdQuery',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        key: 'fromDate',
        label: 'From',
        sortable: true,
        formatter: (date?: Date) => {
          return (date == null) ? '' : DateFormatter.format(date, DateStyle.Long)
        }
      },
      {
        class: 'font-weight-bold',
        key: 'toDate',
        label: 'To',
        sortable: true,
        formatter: (date?: Date) => {
          return (date == null) ? '' : DateFormatter.format(date, DateStyle.Long)
        }
      },
      {
        key: 'createdBy',
        label: 'Set by',
        sortable: true
      },
      {
        class: 'w-50',
        key: 'comment',
        label: 'Terminal comment',
        sortable: false
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'id',
        label: '',
        sortable: false
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 2, 1)
    }

    return fields
  }


  /**
   * The list of Terminal Comments for the current page
   * @property
   * @returns {TerminalComment[]} The list of Terminal Comments for the current page
   */
  public get terminalComments() {
    return this.pagination.page()
  }

  /**
   * The total number of pages of resutls
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }

  /**
   * Clears the Terminal Comments currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.currentComments = []
    this.pagination.reset()
    this.reset()
    this.status = ViewStatus.NONE
  }

  /**
   * Deletes the Terminal Comment currently being viewed
   * @function
   * @returns {void}
   */
  public async delete() {
    try {
      this.status = ViewStatus.DELETING
      await this.terminalComment.delete()
      this.update()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      this.status = ViewStatus.FAILED
      console.log(error)
    }
  }

  /**
   * Retrieves a list of Terminal Comments from the API.
   * If the current page is the first page, pagination will be reset to start again
   * otherwise the status is updating
   * @function
   * @returns {TerminalComment[]} The list of Terminal Locations
   */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getTerminalComments()
  }

  /**
   * Retrieves a list of current Terminal Comments from the API.
   * @function
   * @returns {TerminalComment[]} The list of Terminal Locations
   */
  public async fetchCurrent() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      this.queryParams.personId = this.person!.id
      this.currentComments = await this.repo.getCurrentTerminalComments(this.person!.id, this.queryParams)

      return this.current
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return null
  }

  /**
   * Retrieves a list of Terminal Comments for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {TerminalLocation[]} The list of Terminal Locations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.terminalComments
      }

      this.status = ViewStatus.UPDATING
      return await this.getTerminalComments()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Resets the editor and undoes all changes made
   * @function
   * @returns {void}
   */
  public reset() {
    this.terminalComment.reset()
    this.status = ViewStatus.NONE

    this.terminalComment.person = new Person(this.person)
    if (this.person?.patrons.length == 1) {
      this.terminalComment.patrons = this.person.patrons
    }
  }

  /**
   * Saves the edited Terminal Comments and updates the existing records
   * @function
   * @returns {void}
   */
  public async save() {
    try {
      this.status = ViewStatus.SAVING

      if (this.terminalComment.edit.createdBy == null) {
        this.terminalComment.edit.createdBy = this.auth.user.userName
      }

      await this.terminalComment.save()
      this.update()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Sets the current Terminal Comment for the state and assigns the Patron
   * @function
   * @returns {void}
   */
  public setTerminalComment(terminalComment: TerminalComment) {
    this.terminalComment.set(terminalComment)
    this.terminalComment.person = this.person!

    if (!this.terminalComment.isIdQuery) {
      if (terminalComment.patron?.id ?? 0 > 0) {
        this.terminalComment.patrons = this.person!.patrons.filter(p => p.id == terminalComment.patron!.id)
      }
      else if (this.person!.patrons.length == 1) {
        this.terminalComment.patrons = this.person!.patrons
      }
    }
  }

  /**
   * Retrieves a fresh list of Terminal Comments in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalComment[]} The list of sorted Terminal Comments
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getTerminalComments()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Terminal Comments from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @function
   * @returns {TerminalComment[]} The list of Terminal Comments for the current page
   */
  private async getTerminalComments() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      const response = await this.repo.getTerminalComments(this.person!.id, this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.terminalComments
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Refreshes the current screen by retrieving the latest set of Terminal Comments and
   * current Terminal Comments.
   * @private
   * @function
   * @returns {void} 
   */
  private update() {
    this.fetch()
    this.fetchCurrent()
  }
}
