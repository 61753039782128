/* eslint-disable */ //stop error when compling
import { BanStatus } from '../models/banStatus'
import { EventType } from '../models/eventType'
import { Gender } from '../models/gender'
import { IQueryString, QueryString } from './queryString'
import { Organisation } from '../models/organisation'
import { PersonStatus } from '../models/personStatus'
import { ProofOfIdType } from '../models/proofOfIdType'
import { TerminalLocation } from '../models/terminalLocation'
import { ISortContext } from '../interfaces/sortContext'
import { VerificationType } from '../enums/verificationType'
import { PoliceReportStatus } from '../enums/policeReportStatus'

/**
 * A representation of Query Parameters populated by the UI typically from search forms
 * to assist with the searching, filtering and sorting of results returned from an API query
 * @interface
 */
export interface IQueryParams {
  ageFrom?: number
  ageTo?: number
  auditType?: number
  banStatuses?: BanStatus
  company?: string
  currentPage: number
  dateFrom?: string
  dateTo?: string
  eventTypes?: EventType[]
  excludeBanned: boolean
  gender?: Gender[]
  givenNames?: string
  hasPhone: boolean
  includeInactive: boolean
  nkReference?: number
  pageSize: number
  patronIds?: number[]
  personId?: number
  personStatuses?: PersonStatus[]
  phoneNumber?: string
  policeReportStatus?: PoliceReportStatus
  postcode?: string
  proofOfId?: string
  proofOfIds?: ProofOfIdType[]
  searchText?: string
  selectedIds?: number[]
  sortBy: string
  sortDesc: boolean
  states?: Array<{id: string, name: string}>
  suburb?: string
  surname?: string
  terminalLocations?: TerminalLocation[]
  timeFrom?: string
  timeTo?: string
  venues?: Organisation[]
  verificationType?: VerificationType

  copy(): IQueryParams
  reset(): void
  sortedBy(context: ISortContext): IQueryParams
  toQueryString(): IQueryString
}

/**
 * A representation of Query Parameters populated by the UI typically from search forms
 * to assist with the searching, filtering and sorting of results returned from an API query
 * @class
 */
export class QueryParams implements IQueryParams {

  /**
   * The minimum age of a person to search from
   * @property
   */
  public ageFrom?: number

  /**
   * The maximum age of a person to search up to
   * @property
   */
  public ageTo?: number

  /**
   * The audit type to search for
   * @property
   */
  public auditType?: number

/**
   * The list of Ban Statuses to search for
   * @property
   * @see {@link BanStatus}
   */
  public banStatuses?: BanStatus

  /**
   * The company name to search for
   * @property
   */
  public company?: string

  /**
   * The current page of search (when paginated)
   * @property
   */
  public currentPage = 1

  /**
   * The starting date to search from
   * @property
   */
  public dateFrom?: string

  /**
   * The end date to search up to
   * @property
   */
  public dateTo?: string

  /**
   * The list of Event Types to search for - this should only be used for Marketing searches
   * @property
   * @see {@link EventType}
   */
  public eventTypes?: EventType[]

  /**
   * Whether the ignore banned patrons in the search - this should only be used for Marketing searches (true)
   * @property
   */
  public excludeBanned = false

  /**
   * The list of Genders to search for 
   * @property
   * @see {@link Gender}
   */
  public gender?: Gender[]

  public givenNames?: string

  /**
   * Whether the person has a phone - this should only be used for Marketing searches (true)
   * @property
   */
  public hasPhone = false

  /**
   * A status of Police Reports to search for
   * @property
   */
  public policeReportStatus?: PoliceReportStatus

  /**
   * An Id number to search for
   * @property
   */
  public proofOfId?: string

  /**
  * The list of Proof Of Id types to include in the search
  * @property
  * @see {@link ProofofIdType}
  */
  public proofOfIds?: ProofOfIdType[]

  /**
   * Whether to include results which are flagged as Inactive
   * @property
   */
  public includeInactive = true

  /**
   * The NK Ref
   * @property
   */
  public nkReference?: number

  /**
   * The page size for paginated searches
   * @property
   */
  public pageSize = 50

  /**
   * The Patron Ids of a person to include in the search
   * @property
   */
  public patronIds?: number[]

  /**
   * The Id of a person to include in the search
   * @property
   */
  public personId?: number

  /**
  * The list of Person status types to include in the search
  * @property
  * @see {@link PersonStatus}
  */
  public personStatuses?: PersonStatus[]

  /**
   * A phone number to search for - this should only be used in Marketing searches
   * @property
   */
  public phoneNumber?: string

  /**
   * A postcode to narrow search down to a location
   * @property
   */
  public postcode?: string

  /**
   * Generic search text to include in the search
   * @property
   */
  public searchText?: string

  /**
   * A range of Ids which have been selected to specifically search for
   * @property
   */
  public selectedIds?: number[]

  /**
   * The property name to sort by
   * @property
   */
  public sortBy = ''

  /**
   * Sort Descending or Ascending
   * @property
   */
  public sortDesc = false

  /**
   * A list of states to sort by
   * @property
   */
  public states?: Array<{ id: string, name: string }>

  /**
  * A suburb to search by in order to narrow results to a location
  * @property
  */
  public suburb?: string

  /**
  * A person's last name
  * @property
  */
  public surname?: string

  /**
  * A list of terminal locations to narrow search results for
  * @property
  */
  public terminalLocations?: TerminalLocation[]

  /**
   * The starting time to search from
   * @property
   */
  public timeFrom?: string

  /**
   * The end time to search up to
   * @property
   */
  public timeTo?: string

  /**
  * A list of venues to narrow search results for
  * @property
  */
  public venues?: Organisation[]

  /**
  * The verification type 
  * @property
  */
  public verificationType?: VerificationType


  constructor(queryParams?: IQueryParams) {
    this.ageFrom = queryParams?.ageFrom
    this.ageTo = queryParams?.ageTo
    this.banStatuses = queryParams?.banStatuses
    this.company = queryParams?.company
    this.currentPage = queryParams?.currentPage ?? 1
    this.dateFrom = queryParams?.dateFrom
    this.dateTo = queryParams?.dateTo
    this.eventTypes = queryParams?.eventTypes?.map(e => e)
    this.excludeBanned = queryParams?.excludeBanned ?? false
    this.gender = queryParams?.gender?.map(g => g)
    this.givenNames = queryParams?.givenNames
    this.hasPhone = queryParams?.hasPhone ?? false
    this.proofOfIds = queryParams?.proofOfIds
    this.includeInactive = queryParams?.includeInactive ?? true
    this.nkReference = queryParams?.nkReference
    this.pageSize = queryParams?.pageSize ?? 50
    this.patronIds = queryParams?.patronIds
    this.personId = queryParams?.personId
    this.personStatuses = queryParams?.personStatuses?.map(p => new PersonStatus(p))
    this.phoneNumber = queryParams?.phoneNumber
    this.policeReportStatus = queryParams?.policeReportStatus
    this.postcode = queryParams?.postcode
    this.proofOfId = queryParams?.proofOfId
    this.proofOfIds = queryParams?.proofOfIds
    this.searchText = queryParams?.searchText
    this.selectedIds = queryParams?.selectedIds?.map(id => id)
    this.sortBy = queryParams?.sortBy ?? ''
    this.sortDesc = queryParams?.sortDesc ?? false
    this.states = queryParams?.states?.map(s => s)
    this.suburb = queryParams?.suburb
    this.surname = queryParams?.surname
    this.terminalLocations = queryParams?.terminalLocations?.map(t => new TerminalLocation(t))
    this.timeFrom = queryParams?.timeFrom
    this.timeTo = queryParams?.timeTo
    this.venues = queryParams?.venues?.map(v => new Organisation(v))
    this.verificationType = queryParams?.verificationType
  }

  /**
   * Makes a deep copy of the current Query Params
   * @function
   * @returns QueryParams
   */
  public copy(): QueryParams {
    return new QueryParams(this)
  }

  /**
   * Resets all parameters to their default value 
  */
  public reset() {
    this.ageFrom = undefined
    this.ageTo = undefined
    this.banStatuses = undefined
    this.company = undefined
    this.currentPage = 1
    this.dateFrom = undefined
    this.dateTo = undefined
    this.eventTypes = undefined
    this.gender = undefined
    this.givenNames = undefined
    this.hasPhone = false
    this.nkReference = undefined
    this.pageSize = 50
    this.patronIds = undefined
    this.personId = undefined
    this.personStatuses = undefined
    this.phoneNumber = undefined
    this.postcode = undefined
    this.policeReportStatus = undefined
    this.proofOfId = undefined
    this.proofOfIds = undefined
    this.searchText = undefined
    this.selectedIds = undefined
    this.sortBy = ''
    this.sortDesc = false
    this.states = undefined
    this.suburb = undefined
    this.surname = undefined
    this.terminalLocations = undefined
    this.timeFrom = undefined
    this.timeTo = undefined
    this.venues = undefined
    this.verificationType = undefined
  }

  /**
   * Sets the sort property and order based on a SortContext
   * @function
   * @param context
   */
  public sortedBy(context: ISortContext): QueryParams {
    this.sortBy = context.sortBy
    this.sortDesc = context.sortDesc

    return this
  }

  public noPagination() {
    this.pageSize = 0
    this.currentPage = 0
  }

  /**
   * Converts a QueryParam to a QueryString ready for API
   * @function
   * @returns QueryString
   */
  public toQueryString(): QueryString {
    return new QueryString(this)
  }
}
