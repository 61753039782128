import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Patron } from './patron'

export interface IBan extends IJsonIdentity {
  createdBy?: string
  createdDate: Date
  expiryDate?: Date
  fromDate: Date
  internalReason?: string
  isExpired: boolean
  modifiedBy?: string
  modifiedDate: Date
  patron: Patron
  reason: string
  toDate?: Date

  copy(): IBan
  isEqual(ban: IBan): boolean
}

export class Ban implements IBan  {

  public createdBy?: string
  public createdDate: Date
  public expiryDate?: Date
  public fromDate: Date
  public id: number
  public internalReason?: string
  public isExpired: boolean
  public modifiedBy?: string
  public modifiedDate: Date
  public patron: Patron
  public reason: string
  public toDate?: Date
  
  constructor(ban?: IBan) {
    this.createdBy = ban?.createdBy
    this.createdDate = DateFormatter.parse(ban?.createdDate, new Date())
    this.expiryDate = DateFormatter.parse(ban?.expiryDate, undefined)
    this.fromDate = DateFormatter.parse(ban?.fromDate, new Date())
    this.id = ban?.id ?? 0
    this.internalReason = ban?.internalReason
    this.isExpired = ban?.isExpired ?? false
    this.modifiedBy = ban?.modifiedBy
    this.modifiedDate = DateFormatter.parse(ban?.modifiedDate, new Date())
    this.patron = new Patron(ban?.patron)
    this.reason = ban?.reason ?? ''
    this.toDate = DateFormatter.parse(ban?.toDate, null)
  }

  public copy(): Ban {
    return new Ban(this)
  }

  public isEqual(ban: Ban) {
    return this.createdBy == ban.createdBy
      && this.createdDate == ban.createdDate
      && this.expiryDate == ban.expiryDate
      && this.fromDate == ban.fromDate
      && this.id == ban.id
      && this.internalReason == ban.internalReason
      && this.isExpired == ban.isExpired
      && this.patron.id == ban.patron.id
      && this.reason == ban.reason
      && this.toDate == ban.toDate
  }
}
