import { ApiRepository } from './apiRepository'
import { Paths } from '../constants/paths'
import { ProofOfId } from '../models/proofOfId'
import { Person, IPerson } from '../models/person'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'
import * as qs from 'qs'

export class PersonRepository extends ApiRepository<Person, IPerson> {

  constructor(path = Paths.PERSONS, type: new (i?: IPerson) => Person = Person) {
    super(path, type)
  }

  public async deleteFingerPin(personId: number): Promise<boolean> {
      const url = this.urlPath(this.path, personId, [Paths.FINGERPIN])
      return await axios.delete(url)
  }

  public async getDuplicates(personId: number): Promise<Person[]> {
    try {
      const url = this.urlPath(this.path, personId, [Paths.DUPLICATES])
      const response = await axios.get(url)
      const iPersons = response.data as IPerson[]

      return iPersons.map(i => new Person(i))
    }
    catch {
      return []
    }
  }

  public async getPerson(proofOfId: ProofOfId): Promise<Person | undefined> {
    try {
      const url = this.urlPath(this.path, null, [Paths.PROOF_OF_ID])
      const response = await axios.post(url, proofOfId)
      const iPerson = response.data as IPerson

      return new Person(iPerson);
    }
    catch {
      return undefined
    }
  }

  public async getPersons(queryParams: QueryParams) {
    return await this.getResponse(this.path, queryParams)
  }

  public async getIds(queryParams: QueryParams): Promise<number[]> {
    const url = this.urlPath(this.path, null, [Paths.IDS])
    const response = await axios.get(url, {
      params: queryParams.toQueryString(),
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
    })

    return response.data
  }

  public async patch(person: Person): Promise<Person | undefined> {
    const urlPath = this.urlPath(this.path, person.id)
    const response = await axios.patch(urlPath, [{ op: 'replace', path: 'Email', value: person.email }, { op: 'replace', path: 'Mobile', value: person.mobile }])
    const iPerson = response.data as IPerson

    return new Person(iPerson);
  }
}
