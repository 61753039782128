export class Routes {

  public static ACCOUNT = 'Account'
  public static ADMIN = 'Admin'
  public static HOME = 'Home'
  public static LOGIN = 'Login'
  public static MARKETING = 'Marketing'
  public static MARKETING_SEARCH = 'MarketingSearch'
  public static MERGE = 'Merge'
  public static MERGE_BANS = 'VerifyBans'
  public static MERGE_COMMENTS = 'VerifyComments'
  public static MERGE_COMMUNICATIONS = 'VerifyCommunications'
  public static MERGE_EVENTS = 'VerifyEvents'
  public static MERGE_NOTES = 'VerifyNotes'
  public static MERGE_PROFILE = 'VerifyProfile'
  public static MERGE_VIEWS = 'VerifyViews'
  public static PASSWORD = 'Password'
  public static PATRON = 'Patron'
  public static PATRON_BANS = 'PatronBans'
  public static PATRON_COMMENTS = 'PatronComments'
  public static PATRON_COMMUNICATIONS = 'PatronCommunications'
  public static PATRON_EVENTS = 'PatronEvents'
  public static PATRON_NOTES = 'PatronNotes'
  public static PATRON_PROFILE = 'PatronProfile'
  public static PATRON_SEARCH = 'PatronSearch'
  public static PATRON_VIEWS = 'PatronViews'
  public static PATRONS = 'Patrons'
  public static PERSON = 'Person'
  public static PERSON_BANS = 'PersonBans'
  public static PERSON_COMMENTS = 'PersonComments'
  public static PERSON_COMMUNICATIONS = 'PersonCommunications'
  public static PERSON_EVENTS = 'PersonEvents'
  public static PERSON_NOTES = 'PersonNotes'
  public static PERSON_PROFILE = 'PersonProfile'
  public static PERSON_VIEWS = 'PersonViews'
  public static POLICE_REPORTS = 'PoliceReports'
  public static REPORTING = 'Reporting'
  public static SETTINGS = 'Settings'
  public static TERMINAL_LOCATIONS = 'TerminalLocations'
  public static TERMINAL_STATIONS = 'TerminalStations'
  public static TOOLBOX = 'Toolbox'
  public static TOOLBOX_SEARCH = 'ToolboxSearch'
  public static USERS = 'Users'
  public static VENUE = 'Venue'
  public static VENUES = 'Venues'
  public static VERIFY = 'Verify'
  public static VERIFY_BANS = 'VerifyBans'
  public static VERIFY_COMMENTS = 'VerifyComments'
  public static VERIFY_COMMUNICATIONS = 'VerifyCommunications'
  public static VERIFY_EVENTS = 'VerifyEvents'
  public static VERIFY_NOTES = 'VerifyNotes'
  public static VERIFY_PROFILE = 'VerifyProfile'
  public static VERIFY_VIEWS = 'VerifyViews'
}
