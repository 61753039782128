import { Store } from 'vuex'

import { Audit } from '@/ts/models/audit'
import { AuditRepository } from '@/ts/repositories/auditRepository'
import { AuditType } from '@/ts/enums/auditType'
import { AuthState } from '@/ts/states/auth/authState'
import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { Pagination } from '@/ts/models/pagination'
import { Patron } from '@/ts/models/patron'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewEventType } from '@/ts/enums/viewEventType'
import { ViewStatus } from '@/ts/enums/viewStatus'


/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class ViewEventsState {

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The AuthState for retrieving the current logged in user
   * values in the UI
   * @private
   * @property
   * @returns {AuthState}
   */
  private authState: AuthState

  /**
   * The pagination used to manage the list of View Events in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<Audit>()

  /**
   * The selected Person the current View Events belong to
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  private person?: Person

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link AuditRepository}
   * @returns {AuditRepository}
   */
  private repo = new AuditRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */

  constructor(store: Store<any>, person?: Person) {
    this.authState = new AuthState(store)

    this.queryParams.auditType = AuditType.VIEW_USER
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'createdDate'
    this.queryParams.sortDesc = true

    if (person != null) {
      this.person = person
      this.fetch()
    }
  }


  /**
   * The total number of View Events for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more View Events that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current State has retrieved any View Events to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
 * A key/value map to display View Events in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.LongTime)
        },
        key: 'createdDate',
        label: 'Date viewed',
        sortable: true
      },
      {
        key: 'createdBy',
        label: 'Viewed by',
        sortable: true
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 1, 1)
    }

    return fields
  }

  /**
   * The total number of pages of resutls
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }

  /**
   * The list of View Events for the current page
   * @property
   * @returns {ViewEvent[]} The list of ViewEvents for the current page
   */
  public get viewEvents() {
    return this.pagination.page()
  }


  /**
   * Clears the View Events currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.pagination.reset()
    this.status = ViewStatus.NONE
  }

  /**
  * Retrieves a list of View Events from the API.
  * If the current page is the first page, pagination will be reset to start again
  * otherwise the status is updating
  * @function
  * @returns {Audit[]} The list of Audits
  */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getViewEvents()
  }

  /**
   * Retrieves a list of View Events for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {Audit[]} The list of Audits
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.viewEvents
      }

      this.status = ViewStatus.UPDATING

      return await this.getViewEvents()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a fresh list of View Events in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalComment[]} The list of sorted ViewEvents
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getViewEvents()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a list of View Events from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @function
   * @returns {ViewEvent[]} The list of View Events for the current page
   */
  private async getViewEvents() {
    try {
      this.queryParams.auditType = AuditType.VIEW_USER
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)

      const response = await this.repo.getAudits(this.person!.id, this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount

      this.status = ViewStatus.SUCCEEDED

      return this.viewEvents
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
 * Refreshes the current screen by retrieving the latest set of View Events and
 * current View Events.
 * @private
 * @function
 * @returns {void} 
 */
  private update() {
    this.fetch()
  }
}
