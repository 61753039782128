import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Organisation } from './organisation'

export interface ITerminalLocation extends IJsonIdentity {  
  isActive: true
  name: string
  organisation: Organisation


  copy(): ITerminalLocation
  isEqual(terminalLocation: TerminalLocation): boolean
}

export class TerminalLocation implements ITerminalLocation {
  public id: number
  public isActive: true
  public name: string
  public organisation: Organisation

  constructor(terminalLocation?: ITerminalLocation) {
    this.id = terminalLocation?.id ?? 0
    this.isActive = terminalLocation?.isActive ?? true
    this.name = terminalLocation?.name ?? ''
    this.organisation = new Organisation(terminalLocation?.organisation)
  }

  public copy(): TerminalLocation {
    return new TerminalLocation(this)
  }

  public isEqual(terminalLocation: TerminalLocation) {
    return this.id == terminalLocation.id
      && this.isActive == terminalLocation.isActive
      && this.name == terminalLocation.name
      && this.organisation.id == terminalLocation.organisation.id
  }
}
