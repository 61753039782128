import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IMessageSuggestion extends IJsonIdentity {
  message: string
  messageType: number
  suggestion: string

  copy(): IMessageSuggestion
}

export class MessageSuggestion implements IMessageSuggestion {
  public id: number
  public message: string
  public messageType: number
  public suggestion: string

  constructor(message?: IMessageSuggestion) {
    this.id = message?.id ?? 0
    this.message = message?.message ?? ''
    this.messageType = message?.messageType ?? 0
    this.suggestion = message?.suggestion ?? ''
  }

  public copy(): MessageSuggestion {
    return new MessageSuggestion(this)
  }
}
