import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { HttpStatus } from '../enums/httpStatusCode'
import { Paths } from '../constants/paths'
import { IVerification, Verification } from '../models/verification'

import axios from 'axios'
import { QueryParams } from '../api/queryParams'
import qs from 'qs'

export class ToolboxRepository extends ApiRepository<Verification, IVerification> {

  constructor(path = Paths.TOOLBOX, type: new (i?: IVerification) => Verification = Verification) {
    super(path, type)
  }

  public async getVerifications(queryParams: QueryParams): Promise<ApiResponse<Verification, IVerification>> {
      const url = this.urlPath(this.path, null, [Paths.VERIFICATIONS])
      const response = await axios.get(url, {
          params: queryParams.toQueryString(),
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
        })

        return new ApiResponse<Verification, IVerification>(response.data, Verification)
  }

  public async idQuery(verification: Verification): Promise<boolean> {
    const url = this.urlPath(this.path, null, [Paths.IDQUERY])
    const response = await axios.put(url, verification)
    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async merge(verification: Verification): Promise<boolean> {
    const url = this.urlPath(this.path, null, [Paths.MERGE])
    const response = await axios.put(url, verification)
    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async verify(verification: Verification): Promise<boolean> {
    const url = this.urlPath(this.path, null, [Paths.VERIFY])
    const response = await axios.put(url, verification)
    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }
}
