export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204
}

export class HttpStatus {
  public static isOK(statusCode: number) {
    return statusCode >= HttpStatusCode.OK && statusCode <= HttpStatusCode.NO_CONTENT
  }
}
