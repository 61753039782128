import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { AuditRepository } from '@/ts/repositories/auditRepository'
import { AuthState } from '@/ts/states/auth/authState'
import { Ban } from '@/ts/models/ban'
import { BansState } from './children/bansState'
import { EventsState } from './children/eventsState'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { NotesState } from './children/notesState'
import { CommunicationsState } from './children/communicationsState'
import { Patron } from '@/ts/models/patron'
import { Person } from '@/ts/models/person'
import { PersonEditor } from '@/ts/editors/personEditor'
import { PersonRepository } from '@/ts/repositories/personRepository'
import { PersonStatus } from '@/ts/models/personStatus'
import { PersonStatusType } from '@/ts/enums/personStatusType'
import { Route } from '@/ts/models/route'
import { SearchState } from '../search/searchState'
import { TerminalCommentsState } from './children/terminalCommentsState'
import { ViewEventsState } from './children/viewEventsState'
import { ViewStatus } from '@/ts/enums/viewStatus'

import PersonModule from '@/ts/store/person/personModule'

import _ from 'lodash'

/**
 * State management of Person searches and UI
 * @class
 */
export class PersonState {

  /**
  * The Auth State for retrieving the current logged in user
  * @property
  * @see {@link AuthState}
  * @returns {AuthState}
  */
  public auth: AuthState

  /**
   * The Bans State for retrieving and displaying a list of Ban for a specific Persons
   * including adding, updating or removing an existing Ban.
   * @property
   * @see {@link BansState}
   * @returns {BansState}
   */
  public bans: BansState

  /**
   * The Communications State for retrieving and displaying a list of comms for a specific Persons
   * @property
   * @see {@link CommunicationsState}
   * @returns {CommunicationsState}
   */
  public communications: CommunicationsState

  /**
   * When editing a user's details (like their ID) a duplicate record may be identified.  In such cases the
   * the duplicate person is stored in State so that they can be identified and the appropriate course of
   * action can be taken.
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  public duplicate?= new Person()

  /**
   * The Event Logs State for retrieving and displaying a list of Event Logs for a specific Persons
   * @property
   * @see {@link EventsState}
   * @returns {EventsState}
   */
  public events: EventsState

  /**
   * The Notes State for retrieving and displaying a list of Note for a specific Persons
   * including adding, updating or removing a Note.
   * @property
   * @see {@link NotesState}
   * @returns {NotesState}
   */
  public notes: NotesState

  /**
   * A Person Editor that is used to store the original values of the editor along with the
   * changed/new values for editing purposes
   * @property
   * @see {@link PersonEditor}
   * @returns {PersonEditor}
   */
  public person: PersonEditor

   /**
    * The Search State for displaying a list of SEARCHED People and Events
    * within the UI
    * @property
    * @see {@link SearchState}
    * @returns {SearchState}
    */
  public search: SearchState

  /**
  * Whether to show a terminal comment for an ID Query
  * @property
  * @returns {boolean}
  */
  public showIdQuery = false

  /**
  * Whether to show the organisation for entries
  * @property
  * @returns {boolean}
  */
  public showOrganisation = false

  
  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.SUCCEEDED

  /**
   * The Terminal Comments State for retrieving and displaying a list of Terminal Comments for a specific Persons
   * including adding, updating or removing a Terminal Comment.
   * @property
   * @see {@link TerminalCommentsState}
   * @returns {TerminalCommentsState}
   */
  public terminalComments: TerminalCommentsState

  /**
   * The View Events State for retrieving and displaying a list of View Events for a specific Persons
   * @property
   * @see {@link ViewEventsState}
   * @returns {ViewEventsState}
   */
  public viewEvents: ViewEventsState


  /**
 * A back-end store for the navigation stack.
 * This should only be used when the UI allows a user to tap a link
 * to a new page, but should be able to return to the current page and return
 * to the existing state.
 * @private
 * @property
 * @see {@link Route}
 * @returns {Route}
 */
  protected routes = new Array<Route>()

  /**
  * The LookupState for retrieving and displaying pre-defined
  * values in the UI
  * @private
  * @property
  * @returns {LookupState}
  */
  private lookupState: LookupState


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PersonModule}
   * @returns {PersonModule}
   */
  private module: PersonModule

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link PersonRepository}
   * @returns {PersonRepository}
   */
  private repo = new PersonRepository()

  /**
   * The back-end store for the number of slides to show
   * @private
   * @property
   * @returns {number}
   */
  private slideCount = 0

  /**
   * A back-end store for the Vuex local session storage
   * @private
   * @property
   * @see {@link Store}
   * @returns {Store<any>}
   */
  private store: Store<any>

  /**
 * A pre-defined list of Person Statuses
 * @property
 * @see {@link PersonStatusType}
 * @returns {PersonStatusType[]}
 */
  private statuses = new Array<PersonStatus>()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store: Store<any>, person?: Person) {
    this.module = getModule(PersonModule, store)
    this.store = store

    this.auth = new AuthState(store)
    this.bans = new BansState(store)
    this.communications = new CommunicationsState(store)
    this.events = new EventsState(store)
    this.lookupState = new LookupState(store)
    this.notes = new NotesState(store)
    this.person = new PersonEditor(new Person(), store)
    this.statuses = this.lookupState.personStatuses
    this.search = new SearchState(store)
    this.terminalComments = new TerminalCommentsState(store)
    this.viewEvents = new ViewEventsState(store)

    this.setPerson(person)
  }


  /**
  * Whether the current state as any selected persons
  * @property
  * @returns {boolean}
  */
  public get hasState() {
    return this.search.persons.selected.length > 0
  }

  /**
  * The Id of the current Person
  * @property
  * @returns {number}
  */
  public get id() {
    return this.person.original.id
  }

  public get personStatuses() {
    if (this.person.original.is(PersonStatusType.IDQUERY)) {
      return this.statuses.filter(s => s.id == PersonStatusType.IDQUERY)
    }

    return this.statuses.filter(s => s.id == this.person.original.personStatus.id || s.id == PersonStatusType.IDQUERY)
  }

  /**
   * The previous Route which should be used to allow a user to go back to a previous screen.
   * This method does not pop the Route of the navigation stack.
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  public get returnRoute() {
    const iRoute = _.last(this.module.returnRoutes)
    return (iRoute) ? new Route(iRoute.name, iRoute.params, iRoute.displayName) : undefined
  }

  /**
   * The Y scroll position of the current page
   * @property
   * @returns {number}
   */
  public get scrollPosition() {
    return this.module.scrollPosition
  }

  public set scrollPosition(value: number) {
    this.module.setScrollPosition(value)
  }
  
  /**
   * The default number of slides to show in the Events carousel
   * @property
   * @returns {number}
   */
  public get slidesToShow() {
    return this.slideCount
  }

  public set slidesToShow(value: number) {
    this.slideCount = value
    this.events.slidesToShow = value
  }

  /**
   * The Tab Index selected for the carousel which appears in the UI to show events, duplicates etc...
   * @property
   * @returns {number}
   */
  public get tabIndex() {
    return this.module.tabIndex
  }

  public set tabIndex(index: number) {
    this.module.setTabIndex(index)
  }


  /**
   * Clears the entire state and child states
   * @function
   * @returns {void}
   */
  public clear() {
    this.communications.clear()
    this.duplicate = undefined
    this.status = ViewStatus.NONE
    this.module.clear()
    this.events.clear()
    this.bans.clear()
    this.notes.clear()
    this.terminalComments.clear()
    this.viewEvents.clear()
  }

  /**
   * Creates an ID Query and sends to the API when a duplicate has been identified in the system
   * @function
   * @returns {void}
   */
  public async createIdQuery() {
    try {
      
      const idQueryStatus = this.lookupState.personStatus(PersonStatusType.IDQUERY)!
      this.person.edit.personStatus = idQueryStatus
      this.duplicate!.personStatus = idQueryStatus
      await this.repo.save(this.person.original)
      await this.repo.save(this.duplicate!)
      await this.update(this.person.original)

      this.duplicate = undefined
    }
    catch (error) {
      console.log(error)
    }
  }

   /**
    * Retrieves data from the API for all child states for the current Person
    * @function
    * @returns {void}
    */
   public async getMetadata() {
    if (this.person.original != null) {
      this.status = ViewStatus.UPDATING
      try {
        this.bans = new BansState(this.store, this.person.original)
        this.bans.bansChanged = async (bans: Ban[]) => await this.updateBans(bans)
        this.bans.showOrganisation = this.showOrganisation

        this.events = new EventsState(this.store, this.person.original)
        this.events.showOrganisation = this.showOrganisation

        this.notes = new NotesState(this.store, this.person.original)
        this.notes.showOrganisation = this.showOrganisation

        this.communications = new CommunicationsState(this.store, this.person.original)
        this.communications.showOrganisation = this.showOrganisation

        this.terminalComments = new TerminalCommentsState(this.store, this.person.original)
        this.terminalComments.showOrganisation = this.showOrganisation

        this.viewEvents = new ViewEventsState(this.store, this.person.original)
        this.viewEvents.showOrganisation = this.showOrganisation

        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        console.log(error)
        this.status = ViewStatus.FAILED
      }
    }
  }

  /**
  * Logs a Person view in the audit
   *@public
   * @function
   * @returns {void}
   */
  public async log(person: Person, patron?: Patron) {
    try {
      const repo = new AuditRepository()
      if (patron != null) {
        await repo.logPatronViewed(patron)
      }
      else {
        await repo.logPersonViewed(person)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Saves email and mobile edited Person
   * @function
   * @returns {void}
  */
  public async patch() {

    try {
      this.status = ViewStatus.SAVING

      await this.person.patch()
      this.update(this.person.original)

      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }


  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  public popRoute() {
    this.module.popRoute()
  }

  /**
   * Adds a new route to the navigation stack.
   * This should be called when the user navigates away from a page but will
   * have the option to return back on the destination page.
   * stack with a new route
   * @function
   * @param {Route} route - The route to add to the stack
   * @param {boolean} replace - Whether to replace all existing routes with this route
   * @return {void}
   */
  public pushRoute(route: Route, replace = false) {
    if (replace) {
      this.module.clearRoutes()
    }
    this.module.pushRoute(route)
  }

  /**
  * Resets the Person Editor, restoring to it's original state
  * @function
  * @returns {void}
  */
  public reset() {
    this.person.set(this.person.original)
  }

  /**
   * Saves all changes made to the edited Person
   * @function
   * @param {boolean} changeStatus - If New, will change the status to verified
   * @returns {void}
   */
  public async save() {

    if (this.status != ViewStatus.SAVING) {
      this.status = ViewStatus.SAVING
      this.showIdQuery = this.person.isIdQuery

      if (this.showIdQuery) { return }
    }
    try {
      await this.person.save()
      this.update(this.person.original)

      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }


  /**
   * Sets a new Person for the state, retrieving new metadata and re-setting the current status
   * @function
   * @param {Person} person 
   * @returns {void}
   */
  public setPerson(person?: Person) {

    if (person != null) {
      this.module.setPerson(person)
      
    }
    else {
      person = new Person(this.module.iPerson)
    }
   
    this.person.set(person)
    this.setStatus()
  }

  /**
   * Updates the current Person in the Person State and Selected Persons State with a new Person,
   * or an updated version of the existing Person
   * @private
   * @function
   * @param {Person} person - The updated Person
   * @returns {void}
   */
  public async update(person: Person) {
      this.search.persons.update(person)
      this.search.persons.selected.update(person)
      this.search.eventLogs.update(person)
    }


  /**
   * Validates the current Person's Proof of Id and sets a Duplicate if a person
   * with matching Proof Of Id is found
   * @function
   * @param {Person} person 
   * @returns {void}
   */
  public async validateProofOfId() {
    try {
      this.duplicate = await this.repo.getPerson(this.person.edit.proofOfId)
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
  * Sets the current status based on whether Persons have already been
  * retrieved or not
  * private
  * @function
  * @return {void}
  */
  private async setStatus() {
    this.status = (this.person != null) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }

  /**
   * Updates the current Person with a list of updated Bans to detect if the Banned Status
   * of the Person has changed
   * @private
   * @function
   * @param {Ban[]} bans - The updated Bans
   * @returns {void}
   */
  private updateBans(bans: Ban[]) {
    this.module.updateBans(bans);
    this.update(this.person.original)
  }
}
