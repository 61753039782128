import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'
import { TerminalComment, ITerminalComment } from '../models/terminalComment'

import axios from 'axios'
import qs from 'qs'

export class TerminalCommentRepository extends ApiRepository<TerminalComment, ITerminalComment> {

  constructor(path = Paths.TERMINAL_COMMENTS, type: new (i?: ITerminalComment) => TerminalComment = TerminalComment) {
    super(path, type)
  }

  public async getCurrentTerminalComments(id: number, queryParams: QueryParams): Promise<TerminalComment[]> {
      if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
        return [];
      }

      const url = this.urlPath(Paths.PERSONS, id, [this.path, Paths.CURRENT])
      const response = await axios.get(url, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      const iTerminalComments = response.data as ITerminalComment[]
      return iTerminalComments.map(i => new TerminalComment(i))
  }

  public async getTerminalComments(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<TerminalComment, ITerminalComment>(null, TerminalComment);
    }

    const url = this.urlPath(Paths.PERSONS, id, [this.path])
    return this.getResponse(url, queryParams)
  }
}


