import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Organisation } from './organisation'

export interface ITerminalStation extends IJsonIdentity {  
  isActive: boolean
  macAddress: string
  name: string
  organisation: Organisation

  copy(): ITerminalStation
  isEqual(terminalStation: TerminalStation): boolean
}

export class TerminalStation implements ITerminalStation {
  public id: number
  public isActive: boolean
  public macAddress: string
  public name: string
  public organisation: Organisation


  constructor(terminalStation?: ITerminalStation) {
    this.id = terminalStation?.id ?? 0
    this.isActive = terminalStation?.isActive ?? true
    this.macAddress = terminalStation?.macAddress ?? ''
    this.name = terminalStation?.name ?? ''
    this.organisation = new Organisation(terminalStation?.organisation)
  }

  public copy(): TerminalStation {
    return new TerminalStation(this)
  }

  public isEqual(terminalStation: TerminalStation) {
    return this.id == terminalStation.id
      && this.isActive == terminalStation.isActive
      && this.macAddress == terminalStation.macAddress
      && this.name == terminalStation.name
      && this.organisation.id == terminalStation.organisation.id
  }
}
