import { ApiRepository } from './apiRepository'
import { BanStatus, IBanStatus } from '../models/banStatus'
import { EventType, IEventType } from '../models/eventType'
import { Gender, IGender } from '../models/gender'
import { MessageSuggestion, IMessageSuggestion } from '../models/messageSuggestion'
import { Organisation, IOrganisation } from '../models/organisation'
import { OrganisationType, IOrganisationType } from '../models/organisationType'
import { Paths } from '../constants/paths'
import { PersonStatus, IPersonStatus } from '../models/personStatus'
import { PersonStatusType } from '../enums/personStatusType'
import { ProofOfIdType, IProofOfIdType } from '../models/proofOfIdType'
import { TerminalLocation, ITerminalLocation } from '../models/terminalLocation'

import axios from 'axios'
import { IUserRole, UserRole } from '../models/userRole'

export enum Types {
  gender = 'gender',
  event = 'event_type'
}

export class LookupRepository {

  public async getBanSuggestions(): Promise<MessageSuggestion[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.BAN_SUGGESTIONS])
      const response = await axios.get(url)
      const iMessageSuggestions = response.data as IMessageSuggestion[]

      return iMessageSuggestions.map(m => new MessageSuggestion(m))
  }

  public async getBanStatuses(): Promise<BanStatus[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.BANS, Paths.STATUSES])
      const response = await axios.get(url)
      const iBanStatuses = response.data as IBanStatus[]

      return iBanStatuses.map(b => new BanStatus(b))
  }

  public async getEventTypes(): Promise<EventType[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.EVENTLOGS, Paths.TYPES])
      const response = await axios.get(url)
      const iEventTypes = response.data as IEventType[]

      return iEventTypes.map(e => new EventType(e))
  }

  public async getGenders(): Promise<Gender[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.GENDERS])
      const response = await axios.get(url)
      const iGenders = response.data as IGender[]

      return iGenders.map(g => new Gender(g))
  }

  public async getOrganisations(): Promise<Organisation[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.ORGANISATIONS])
      const response = await axios.get(url)
      const iOrganisations = response.data as IOrganisation[]
      const organisations = iOrganisations.map(o => new Organisation(o))

      return organisations
  }

  public async getOrganisationTypes(): Promise<OrganisationType[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.ORGANISATIONS, Paths.TYPES])
      const response = await axios.get(url)
      const iOrganisationTypes = response.data as IOrganisationType[]
      const organisationTypess = iOrganisationTypes.map(o => new OrganisationType(o))

      return organisationTypess
  }

  public async getPersonStatuses(): Promise<PersonStatus[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.PERSONS, Paths.STATUSES])
      const response = await axios.get(url)
      const iPersonStatuses = response.data as IPersonStatus[]

      return iPersonStatuses.map(p => new PersonStatus(p))
  }

  public async getProofOfIdTypes(): Promise<IProofOfIdType[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.ID_TYPES])
      const response = await axios.get(url)
      const iProofOfIdTypes = response.data as IProofOfIdType[]

      return iProofOfIdTypes.map(i => new ProofOfIdType(i))
  }

  public async getTerminalCommentSuggestions(): Promise<MessageSuggestion[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.TERMINAL_COMMENTS, Paths.SUGGESTIONS])
      const response = await axios.get(url)
      const iMessageSuggestions = response.data as IMessageSuggestion[]

      return iMessageSuggestions.map(m => new MessageSuggestion(m))
  }

  public async getTerminalLocations(): Promise<TerminalLocation[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.TERMINAL_LOCATIONS])
      const response = await axios.get(url)
      const iTerminalLocations = response.data as ITerminalLocation[]

      return iTerminalLocations.map(tl => new TerminalLocation(tl))
  }

  public async getUserRoles(): Promise<UserRole[]> {
      const url = ApiRepository.urlPath(Paths.LOOKUPS, null, [Paths.USER_ROLES])
      const response = await axios.get(url)
      const iUserRoles = response.data as IUserRole[]

      return iUserRoles.map(r => new UserRole(r))
  }
}
