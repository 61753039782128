import { AccessToken, IAccessToken } from '../models/accessToken'
import { HttpStatus } from '../enums/httpStatusCode'
import { PasswordMode } from '../enums/passwordMode'
import { Paths } from '../constants/paths'
import { IUser, User } from '../models/user'
//import { PowerbiReport, IPowerbiReport } from '../models/powerbiReport'
import axios from 'axios'


export class AuthRepository  {

  public async changePassword(mode: PasswordMode, username: string, password: string, newPassword: string): Promise<boolean> {
    const url = mode == PasswordMode.CHANGE ? Paths.CHANGE_PASSWORD : Paths.RESET_PASSWORD
    const response = await axios.post(url, { username, password, newPassword })
    if (!HttpStatus.isOK(response.status)) {
         throw response.status
      }

    return true
  }

  public async forgotPassword(username: string): Promise<string | null> {
      const response = await axios.post(Paths.FORGOT_PASSWORD, { username })
    if (!HttpStatus.isOK(response.status)) {
        throw response.status
      }

      return response.data as string
  }


  public async logout(): Promise<boolean> {
    const response = await axios.get(Paths.LOGOUT)
    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async login(username: string, password: string): Promise<AccessToken | undefined> {
      const response = await axios.post(Paths.LOGIN, { username, password })
      const iAccessToken = response.data as IAccessToken

      return new AccessToken(iAccessToken);
  }

  public async loginCode(username: string, accessCode: number): Promise<AccessToken | undefined> {
      const response = await axios.post(Paths.LOGIN_ACCESS, { username, code: accessCode, delete: true })
      const iAccessToken = response.data as IAccessToken

      return new AccessToken(iAccessToken);
  }

  public async resendCode(): Promise<boolean> {
      const response = await axios.get(Paths.RESEND_CODE)
    if (!HttpStatus.isOK(response.status)) {
        throw response.status
      }

      return true
  }

  public async user(): Promise<User> {
    const response = await axios.get(Paths.USER)
    const iUser = response.data as IUser

    return new User(iUser);
  }

  public async validate(code: string, username: string, shouldDelete: boolean): Promise<boolean> {
    const response = await axios.post(Paths.VALIDATE, { code, username, shouldDelete })
    if (!HttpStatus.isOK(response.status)) {
        throw response.status
      }

      return true
  }

}
