import { Module, VuexModule, Action, Mutation, MutationAction } from 'vuex-module-decorators'

import { EventLog, IEventLog } from '@/ts/models/eventLog'
import { EventLogRepository } from '@/ts/repositories/eventLogRepository'
import { ViewStatus } from '@/ts/enums/viewStatus'

import _ from 'lodash'

/**
 * Local session storage of Selected Events
 * @module
 */
@Module({ name: 'SelectedEventsModule', namespaced: true })
export default class SelectedEventsModule extends VuexModule {

  /**
   * The total number of Events that have been selected
   * @property
   * @returns {number}
   */
  public count = 0

  /**
   * The list of selected Event Logs
   * @property
   * @returns {EventLog[]} The list of selected Event Logs
   */
  public iEventLogs = new Array<IEventLog>()

  /**
  * The list of selected EventLog Ids
  * @property
  * @returns {number[]}
  */
  public selectedIds = new Array<number>()

  /**
  * The current index of the Events carousel slide.
  * @property
  * @returns {number}
  */
  public slideIndex = 0

  /**
  * The current status
  * @property
  * @see {@link ViewStatus}
  * @returns {ViewStatus}
  */
  public status = ViewStatus.NONE

  
  /**
  * Clears the local storage of Event Logs & Ids
  * @function
  * @returns {void}
  */
  @Mutation
  public clearResults() {
    this.count = 0
    this.iEventLogs = []
    this.selectedIds = []
  }

  /**
  * Clears the entire local storage
  * @function
  * @returns {void}
  */
  @Mutation
  public clear() {
    this.count = 0
    this.iEventLogs = []
    this.selectedIds = []
    this.slideIndex = 0
  }

  /**
  * Adds Event Logs to the local storage and sorts them
  * @function
  * @returns {void}
  */
  @Mutation
  public add(events: EventLog[]) {
    this.iEventLogs.push(...events)
    this.iEventLogs = _.orderBy(this.iEventLogs, ['createdDate'], ['asc'])
  }

  /**
  * Adds a selected Event Log Id the local storage
  * @function
  * @returns {void}
  */
  @Mutation
  public addSelectedId(id: number) {
    this.selectedIds.push(id)
  }

  /**
  * Deselects a selected Event Log Id and removes the ID and EventLog from local storage
  * @function
  * @returns {void}
  */
  @Mutation
  public deselect(id: number) {
    this.selectedIds = this.selectedIds.filter(i => i != id)
    this.iEventLogs = this.iEventLogs.filter(i => i.timeFrameId != id)
  }

  /**
   * Stores the current index of the Events carousel slide.
   * @property
   * @returns {number}
   */
  @Mutation
  public setSlideIndex(index: number) {
    this.slideIndex = index
  }

  /**
   * Sets the current status
   * @function
   * @return {void}
   */
  @Mutation
  public setStatus(status: ViewStatus) {
    this.status = status
  }


  /**
   * Selects an event based on it's Id and retrieves the events which happened
   * either side of the selected event and stores them.
   * @function
   * @param {number} id - The Event Log Id
   * @returns {void}
   */
  @Action
  public async select(id: number) {
    if (!this.selectedIds.includes(id)) {
      this.context.commit('addSelectedId', id)

      const repo = new EventLogRepository()
      const events = await repo.getTimeFrameEvents(id)
      this.context.commit('add', events)
    }
  }
}
