import { Editor } from './editor'
import { Note } from '../models/note'
import { NoteRepository } from '../repositories/noteRepository'
import { Patron } from '@/ts/models/patron'
import { Store } from 'vuex'

export class NoteEditor extends Editor<Note> {

  /* PROPERTIES */

  public patrons = new Array<Patron>()


  /* PRIVATE PROPERTIES */

  private repo = new NoteRepository()


  constructor(original = new Note(), store: Store<any>) {
    super(original, store)
  }

  /* COMPUTED PROPERTIES */

  public get hasPatrons() {
    return this.patrons.length > 1 
  }


  /* PUBLIC METHODS */

  public async delete() {
    await this.repo.delete(this.edit)
    this.reset()
  }

  public reset() {
    this.edit = new Note()
    this.patrons = []
    this.original = new Note()
  }

  public async save() {
    if (this.edit.id == 0) {
      this.edit.createdBy = this.authState.user.userName
    }

    this.edit.modifiedBy = this.authState.user.userName
    this.edit.modifiedDate = new Date()

    const promises = new Array<Promise<any>>()
    this.patrons.forEach(p => {
      const note = this.edit.copy()
      note.patron = p

      const promise = this.repo.save(note)
      promises.push(promise)
    })

    await Promise.all(promises)
    this.reset()
  }
}
