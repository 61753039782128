import { Module, VuexModule, Action, Mutation, MutationAction } from 'vuex-module-decorators'

import { Person, IPerson } from '@/ts/models/person'
import { PersonRepository } from '@/ts/repositories/personRepository'
import { QueryParams } from '@/ts/api/queryParams'

/**
 * Local session storage of selected Persons
 * @module
 */
@Module({ name: 'SelectedPersonsModule', namespaced: true })
export default class SelectedPersonsModule extends VuexModule {

  /**
   * The total number of People that have been selected
   * @property
   * @returns {number}
   */
  public count = 0

  /**
   * The list of Persons who have been selected in local storage
   * @property
   * @returns {Person[]}
   */
  public iPersons = new Array<IPerson>()

  /**
  * Whether the select all option has been selected
  * @property
  * @returns {boolean}
  */
  public isAllSelected = false

  /**
 * The current Query Parameters used for the search which are populated from
 * form fields within the UI.
 * @property
 * @see {@link QueryParams}
 * @returns {QueryParams}
 */
  public queryParams = new QueryParams()

  /**
  * The Ids of the People who have been selected
  * @property
  * @returns {number[]}
  */
  public selectedIds = new Array<number>()

  /**
  * The current index of the Person carousel slide.
  * @property
  * @returns {number}
  */
  public slideIndex = 0


  /**
   * Clears the current store of all People, Ids etc
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.count = 0
    this.isAllSelected = false
    this.iPersons = []
    this.queryParams = new QueryParams()
    this.selectedIds = []
    this.slideIndex = 0
  }


  /**
   * Stores a People who have been selected
   * @function
   * @returns {void}
   */
  @Mutation
  public add(persons: Person[]) {
    this.iPersons.push(...persons)
  }

  /**
   * Deslects a Person by removing them and their Id from storage.
   * Updates the Query Params
   * @function
   * @returns {void}
   */
  @Mutation
  public deselect(person: Person) {
    this.iPersons = this.iPersons.filter(p => p.id != person.id)
    this.selectedIds = this.selectedIds.filter(i => i != person.id)
    this.isAllSelected = false
    this.count = this.selectedIds.length

    const query = new QueryParams(this.queryParams)
    query.selectedIds = this.selectedIds
    this.queryParams = query
  }

  /**
   * Deselects all Persons removing them from storage and their Ids.
   * Updates the Query Params.
   * @function
   * @returns {void}
   */
  @Mutation
  public deselectAll() {
    this.iPersons = []
    this.selectedIds = []
    this.isAllSelected = false
    this.count = 0

    const query = new QueryParams(this.queryParams)
    query.selectedIds = this.selectedIds
    this.queryParams = query
  }

  /**
   * Selects a Person and stores them and their Id to local storage.
   * Updates the Query Params.
   * @function
   * @returns {void}
   */
  @Mutation
  public select(person: Person) {
    if (!this.selectedIds.includes(person.id)) {
      this.iPersons.push(person)
      this.selectedIds.push(person.id)
      this.isAllSelected = this.selectedIds.length > 0 && this.selectedIds.length == this.count
      if (this.isAllSelected) {
        this.selectedIds = []
      }
      else {
        this.count = this.selectedIds.length
      }

      const query = new QueryParams(this.queryParams)
      query.selectedIds = this.selectedIds
      this.queryParams = query

    }
  }

  /**
   * Stores selected Persons by replacing any existing People in storage
   * @function
   * @returns {void}
   */
  @Mutation
  public set(persons: Person[]) {
    this.iPersons = persons
  }

  /**
   * Stores the total count of People who have been selected
   * This may be higher than the number if Persons in storage
   * @function
   * @returns {void}
   */
  @Mutation
  public setSelectedCount(count: number) {
    this.count = count
  }

  /**
   * Stores a copy of the current Query Params
   * @function
   * @returns {void}
   */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
   * Stores a list of selected Ids
   * @function
   * @returns {void}
   */
  @Mutation
  public setSelectedIds(ids: number[]) {
    this.selectedIds = ids
    this.isAllSelected = this.selectedIds.length > 0 && this.selectedIds.length == this.count

    const query = new QueryParams(this.queryParams)
    query.selectedIds = this.selectedIds
    this.queryParams = query
  }

  /**
   * Stores the current Slide Index of the Persons carousel
   * @function
   * @returns {void}
   */
  @Mutation
  public setSlideIndex(index: number) {
    this.slideIndex = index
  }

  /**
   * Updates the current person's details
   * @function  
   * @returns {void}
   */
  @Mutation
  public update(person: Person) {
    const index = this.iPersons.findIndex(p => p.id == person.id)
    if (index > -1) {
      const iPerson = new Person(this.iPersons[index])
      this.iPersons[index] = iPerson.update(person)
    }
  }


  /**
   * Retrieves a list of Persons from the API filtered and sorted by the
   * Query Params that have been populated from the UI.
   * @private
   * @function
   */
  @Action
  public async getPersons(queryParams: QueryParams) {
    const repo = new PersonRepository()
    const response = await repo.getPersons(queryParams)

    this.context.commit('setSelectedCount', response.totalCount)
    this.context.commit((queryParams.currentPage == 1) ? 'set' : 'add', response.result)
  }

  /**
   * Retrieves a list of all Person Ids from the API filted and sorted by the
   * Query Params that have been populated from the UI.
   * @private
   * @function
   */
  @MutationAction
  public async selectAll(queryParams: QueryParams) {
    const params = new QueryParams(queryParams)
    params.selectedIds = []

    const repo = new PersonRepository()
    const ids = await repo.getIds(params)

    return { isAllSelected: true, selectedIds: ids, count: ids.length, queryParams: params }
  }
}
