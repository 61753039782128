export enum EventLogCodeType {
  ENTERED = 101,
  REENTERED = 102,
  DID_NOT_ENTER = 103,
  DENIED_ENTRY = 104,
  FINGERPIN_MATCH = 220,
  FINGERPIN_FALSE_MATCH = 221,
  FINGERPIN_NO_MATCH = 222,
  FINGERPIN_BAD = 223,
  FINGERPIN_SKIPPED = 224,
  FINGERPIN_REFUSED = 226,
  PROOF_OF_ID_MATCH = 320,
  PROOF_OF_ID_FALSE_MATCH = 321,
  PROOF_OF_ID_NO_MATCH = 322,
  STATUS_RECENT_ENTRY = 402,
  STATUS_DID_NOT_ENTER = 403,
  STATUS_DENIED_ENTRY = 404,
  STATUS_ENROLMENT = 405,
  STATUS_REENROLMENT = 406,
  STATUS_IDQUERY = 407,
  STATUS_BANNED = 408,
  STATUS_UNDERAGE = 409
}
