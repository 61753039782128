import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

/**
 * Local session storage of a Person
 * @module
 */
@Module({ name: 'MediaModule', namespaced: true })
export default class MediaModule extends VuexModule {

  public modalState = false

  /**
  * Stores the current Modal status for the displayed modal
  * @function
  * @returns {void}
  */

 @Mutation
  public getModalState() {
    return this.modalState
  }

  @Mutation
  public setModalState(value: boolean) {
    this.modalState = value
  }

}
