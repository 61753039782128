import { DateFormatter } from '../formatters/dateFormatter'
import { Organisation } from './organisation'
import { RoleType } from '../enums/roleType'
import { UserRole } from './userRole'

export interface IUser {
  createdBy?: string
  createdDate: Date
  email: string
  givenNames: string
  hasFingerPin: boolean
  id?: string
  isActive: boolean
  organisation?: Organisation
  passwordResetRequired: boolean
  phoneNumber: string
  roles: UserRole[]
  surname: string
  userName: string

  
  copy(): IUser
  hasPermission(claim: string, permission: number): boolean
  isEqual(user: User): boolean
  isInRole(role: RoleType): boolean
}

export class User implements IUser {
  public createdBy?: string
  public createdDate: Date
  public email: string
  public givenNames: string
  public hasFingerPin: boolean
  public id?: string
  public isActive: boolean
  public organisation?: Organisation
  public passwordResetRequired: boolean
  public phoneNumber: string
  public roles: UserRole[]
  public surname: string
  public userName: string

  constructor(user?: IUser) {
    this.createdBy = user?.createdBy
    this.createdDate = DateFormatter.parse(user?.createdDate, new Date())
    this.email = user?.email ?? ''
    this.givenNames = user?.givenNames ?? ''
    this.hasFingerPin = user?.hasFingerPin ?? false
    this.id = user?.id ?? undefined
    this.isActive = user?.isActive ?? false
    this.organisation = user?.organisation != undefined ? new Organisation(user?.organisation) : undefined
    this.passwordResetRequired = user?.passwordResetRequired ?? false
    this.phoneNumber = user?.phoneNumber ?? ''
    this.roles = user?.roles ? user.roles.map(r => new UserRole(r)) : []
    this.surname = user?.surname ?? ''
    this.userName = user?.userName ?? ''
  }


  public copy(): User {
    return new User(this)
  }

  public hasPermission(claim: string, permission: number) {
    return this.roles.findIndex(r => r.hasPermission(claim, permission)) > -1
  }

  public isEqual(user: User) {
    return this.id === user.id
      && this.givenNames === user.givenNames
      && this.surname === user.surname
      && this.isActive === user.isActive
      && this.email === user.email
      && this.phoneNumber === user.phoneNumber
      && this.hasFingerPin === user.hasFingerPin
  }

  public isInRole(role: RoleType) {
    return this.roles.findIndex(r => r.name == role) > -1
  }
}
