import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { Ban } from '../models/ban'
import { BanRepository } from '../repositories/banRepository'
import { Editor } from './editor'
import { MessageSuggestion } from '@/ts/models/messageSuggestion'
import { Patron } from '@/ts/models/patron'
import { Store } from 'vuex'

export class BanEditor extends Editor<Ban> {

  /* PROPERTIES */

  public patrons = new Array<Patron>()


  /* PRIVATE PROPERTIES */

  private banSuggestions: MessageSuggestion[]
  private lifetimeBan = true
  private repo = new BanRepository()
  private selectedSuggestion?: MessageSuggestion

  constructor(original: Ban, store: Store<any>, suggestions: MessageSuggestion[]) {
    super(original, store)
    this.lifetimeBan = (original.toDate == null && original.expiryDate == null)
    this.banSuggestions = suggestions
  }

  /* COMPUTED PROPERTIES */

  public get expiryDate() {
    return DateFormatter.format(this.edit.toDate, DateStyle.Picker)
  }

  public set expiryDate(value: string) {
    this.edit.toDate = DateFormatter.parse(value)
    this.edit.toDate?.setHours(23, 59, 59)
  }

  public get hasPatrons() {
    return this.patrons.length > 1 
  }

  public get isLifetimeBan() {
    return this.lifetimeBan
  }

  public set isLifetimeBan(value: boolean) {
    this.lifetimeBan = value
    if (value) {
      this.edit.expiryDate = undefined
      this.edit.toDate = undefined
    }
  }

  public get suggestion() {
    return this.selectedSuggestion
  }

  public set suggestion(value: MessageSuggestion | undefined) {
    this.selectedSuggestion = value
    this.edit.reason = value?.message ?? ''
  }

  public get suggestions() {
    return this.banSuggestions
  }


  /* PUBLIC METHODS */

  public async delete() {
    await this.repo.delete(this.edit)
    this.reset()
  }

  public reset() {
    this.edit = new Ban()
    this.lifetimeBan = true
    this.patrons = []
    this.original = new Ban()
    this.selectedSuggestion = undefined  }

  public async save() {
    if (this.edit.id == 0) {
      this.edit.createdBy = this.authState.user.userName
    }

    this.edit.modifiedDate = new Date()
    this.edit.modifiedBy = this.authState.user.userName

    const promises = new Array<Promise<any>>()
    this.patrons.forEach(p => {
      const ban = this.edit.copy()
      ban.patron = p

      const promise = this.repo.save(ban)
      promises.push(promise)
    })

    await Promise.all(promises)
    this.reset()
  }

  public set(original: Ban) {
    super.set(original)
    this.lifetimeBan = (original.toDate == null && original.expiryDate == null)
  }
}
