import { Module, VuexModule, Action, Mutation, MutationAction } from 'vuex-module-decorators'

import { Person, IPerson } from '@/ts/models/person'
import { PersonRepository } from '@/ts/repositories/personRepository'
import { QueryParams } from '@/ts/api/queryParams'

/**
 * Local session storage of searched Persons
 * @module
 */
@Module({ name: 'PersonsModule', namespaced: true })
export default class PersonsModule extends VuexModule {

  /**
   * The total number of Persons which match the search criteria
   * @property
   * @returns {number}
   */
  public count = 0

  /**
  * The Persons which have been retrieved so far
  * @property
  * @returns {Person[]}
  */
  public iPersons = new Array<IPerson>()

  /** 
  * The current Query Parameters used for the search which are populated from
  * form fields within the UI.
  * @property
  * @see {@link QueryParams}
  * @returns {QueryParams}
  */
  public queryParams = new QueryParams()

  /**
  * Whether to show all people or just selected people
  * @property
  * @returns {boolean}
  */
  public showAll = true

  /**
  * The current index of the Person carousel slide.
  * @property
  * @returns {number}
  */
  public slideIndex = 0

  /**
  * The total count of all Persons found in the NightKey database.
  * @property
  * @returns {number}
  */
  public totalCount = 0


  /**
  * Resets all Persons and selected Ids in storage
  * @function
  * @returns {void}
  */
  @Mutation
  public clearResults() {
    this.count = 0
    this.iPersons = []
  }

  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.count = 0
    this.iPersons = []
    this.queryParams = new QueryParams()
    this.slideIndex = 0
  }


  /**
   * Stores a People who have been selected
   * @function
   * @returns {void}
   */
  @Mutation
  public add(persons: Person[]) {
    this.iPersons.push(...persons)
  }

  /**
   * Stores the Persons who have been retrieved by the API by replacing local storage
   * @function
   * @returns {void}
   */
  @Mutation
  public set(persons: Person[]) {
    this.iPersons = persons
  }

  /**
   * Stores the total count of People who match the criteria  
   * This may be higher than the number if Persons in storage
   * @function
   * @returns {void}
   */
  @Mutation
  public setCount(count: number) {
    this.count = count
  }

  /**
    * Stores a copy of the current Query Params
    * @function
    * @returns {void}
    */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
   * Stores the current Slide Index of the Persons carousel
   * @function
   * @returns {void}
   */
  @Mutation
  public setSlideIndex(index: number) {
    this.slideIndex = index
  }

  /**
   * Stores whether to show all or just selected Persons
   * @function
   * @returns {void}
   */
  @Mutation
  public setShowAll(show: boolean) {
    this.showAll = show
  }

  /**
   * Updates the current person's details
   * @function  
   * @returns {void}
   */
  @Mutation
  public update(person: Person) {
    const index = this.iPersons.findIndex(p => p.id == person.id)
    if (index > -1) {
      const iPerson = new Person(this.iPersons[index])
      this.iPersons[index] = iPerson.update(person)
    }
  }

  /**
   * Retrieves a list of Persons from the API filtered and sorted by the
   * Query Params that have been populated from the UI.
   * @function
   */
  @Action
  public async getPersons(queryParams: QueryParams) {
    const repo = new PersonRepository()
    const response = await repo.getPersons(queryParams)
    this.context.commit('setCount', response.totalCount)
    this.context.commit((queryParams.currentPage == 1) ? 'set' : 'add', response.result)
  }


  /**
   * Retrieves the total number of People in the system and stores it.
   * @function
   */
  @MutationAction
  public async getTotalCount() {
    const repo = new PersonRepository()
    const totalCount = await repo.count()

    return { totalCount }
  }

}

