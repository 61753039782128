import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { Ban, IBan } from '../models/ban'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'
import qs from 'qs'

export class BanRepository extends ApiRepository<Ban, IBan> {

  constructor(path = Paths.BANS, type: new (i?: IBan) => Ban = Ban) {
    super(path, type)
  }

  public async getBans(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<Ban, IBan>(null, Ban);
    }

    const url = this.urlPath(Paths.PERSONS, id, [this.path])
    return this.getResponse(url, queryParams)
  }

  public async getCurrentBans(id: number, queryParams: QueryParams): Promise<Ban[]> {
      const url = this.urlPath(Paths.PERSONS, id, [this.path, Paths.CURRENT])
      const response = await axios.get(url, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      const iBans = response.data as IBan[]
      return iBans.map(i => new Ban(i))
  }
}
