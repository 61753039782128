import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { EventLog } from '@/ts/models/eventLog'
import { LookupState } from '../lookup/lookupState'
import { MergeState } from './children/mergeState'
import { Person } from '@/ts/models/person'
import { PersonState } from '@/ts/states/person/personState'
import { PoliceReportsState } from '@/ts/states/toolbox/children/policeReportsState'
import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'
import { SearchState } from '@/ts/states/search/searchState'
import { QueryParams } from '@/ts/api/queryParams'
import { Route } from '@/ts/models/route'
import { SelectedEventsState } from '@/ts/states/person/children/selectedEventsState'
import { Verification } from '@/ts/models/verification'
import { VerificationType } from '@/ts/enums/verificationType'
import { VerifyState } from './children/verifyState'
import { ViewStatus } from '@/ts/enums/viewStatus'

import ToolboxModule from '@/ts/store/toolbox/toolboxModule'

import _ from 'lodash'

export class ToolboxState {

  /**
   * The LookupState for retrieving and displaying pre-defined
   * values in the UI
   * @property
   * @returns {LookupState}
   */
  public lookupState: LookupState

  /**
   * The State for the currently selected Person
   * @property
   * @returns {PersonState}
   */
  public person: PersonState

  /**
   * The State for the current search if one exists
   * @property
   * @returns {SearchState}
   */
  public search: SearchState

  /**
   * The Police Reports State
   * @property
   * @see {@link PoliceReportsState}
   * @returns {PoliceReportsState}
   */
  //public policeReports: PoliceReportsState

  /**
   * The Selected Events State which manages when Events are selected
   * while and Administrator is merging records
   * @property
   * @see {@link SelectedEventsState}
   * @returns {SelectedEventsState}
   */
  public selectedEvents: SelectedEventsState

  /**
  * The number of slides to show in the carousel based on screen width
  * @property
  * @returns {number}
  */
  public slidesToShow = 0


  /**
  * The Merge State
  * @property
  * @see {@link MergeState}
  * @returns {MergeState}
  */
  public mergeState: MergeState

  /**
  * The Police Reports State
  * @property
  * @see {@link PoliceReportsState}
  * @returns {PoliceReportsState}
  */
  public policeReports: PoliceReportsState

  /**
 * The Verify State
 * @property
 * @see {@link VerifyState}
 * @returns {VerifyState}
 */
  public verifyState: VerifyState


  /**
* The current Verification which is being processed by an Administrator
* when verifying or merging records
* @property
* @see {@link Verification}
* @returns {Verification}
*/
  public verification: Verification

  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link ToolboxModule}
   * @returns {ToolboxModule}
   */
  private module: ToolboxModule



  /** CONSTRUCTORS */

  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * and instantiates all child states
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(ToolboxModule, store)
    this.person = new PersonState(store)
    this.search = new SearchState(store)
    this.lookupState = new LookupState(store)
    this.mergeState = new MergeState(store, this.person)
    this.policeReports = new PoliceReportsState(store)
    this.selectedEvents = new SelectedEventsState(store)
    this.verification = new Verification()
    this.verifyState = new VerifyState(store, this.person)
  }

  private get isMerge() {
    return this.mode == VerificationType.MERGE
  }

  /**
   * A list of people who partially match details of the current Person in the
   * Person State.
   * @property
   * @see {@link Person}
   * @returns {Person[]}
   */
  public get duplicates() {
    return this.module.iDuplicates.map(i => new Person(i))
  }

  public set duplicates(value: Person[]) {
    this.module.setDuplicates(value)
  }

  /**
   * The total number of duplicates found
   * @property
   * @returns {number}
   */
  public get duplicatesCount() {
    return this.module.iDuplicates.length
  }

  /**
   * Whether the Administrator has entered fullscreen mode
   * @property
   * @returns {boolean}
   */
  public get fullscreen() {
    return this.module.fullscreen
  }

  public set fullscreen(value: boolean) {
    this.module.setFullscreen(value)
  }

  /**
   * Whether any duplicates have been found matching the current Person
   * in the Person State
   * @property
   * @returns {boolean}
   */
  public get hasDuplicates() {
    return this.duplicatesCount > 0
  }

  /**
   * Whether any Event Logs exist in the Verification record for the Original
   * Person
   * @property
   * @returns {boolean}
   */
  public get hasEvents() {
    return this.isMerge ? this.mergeState.hasEvents : this.verifyState.hasEvents
  }

  /**
   * Whether all records have now been processed
   * @property
   * @returns {boolean}
   */
  public get isComplete() {
    return this.progress == this.count()
  }

  /**
   * The Verification Type for the Verification currently being processed
   * @property
   * @see {@link VerificationType}
   * @returns {VerificationType}
   */
  public get mode() {
    return this.module.mode
  }

  public set mode(value: VerificationType) {
    this.module.setMode(value)
  }


  /**
* The list of organisations which a Nightkey Admin user might want to filter by.
* This will not be populated when the Application User belongs to a single Organisation.
* @property
* @returns {Organisation[]}
*/
  public get organisations() {
    return this.verifyState.organisations
  }

  /**
   * The percentage of verifications which have been processed so far
   * @property
   * @returns {boolean}
   */
  public get progress() {
    return this.isMerge ? this.mergeState.progress : this.verifyState.progress
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return this.isMerge ? this.mergeState.queryParams : this.verifyState.queryParams
  }

  /**
* A custom list of States based on Organisations available
* @property
*/
  public get states() {
    return this.verifyState.states
  }

  /**
 * The current status
 * @property
 * @see {@link ViewStatus}
 * @returns {ViewStatus}
 */
  public get status() {
    return this.verification.typeId == VerificationType.MERGE ? this.mergeState.status : this.verifyState.status
  }


  /**
   * The Tab Index selected for the carousel which appears in the UI to show events, duplicates etc...
   * @property
   * @returns {number}
   */
  public get tabIndex() {
    return this.module.tabIndex
  }

  public set tabIndex(index: number) {
    this.module.setTabIndex(index)
  }

  /**
   * A key/value map to display Organistations in a table
   * @property
   */
  protected get tableFields() {
    return [
      {
        class: 'p-0 py-2',
        key: 'id',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold wpx-150',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.Long)
        },
        key: 'createdDate',
        label: 'Date of event',
        sortable: false,
      },
      {
        class: 'font-weight-bold',
        key: 'createdTime',
        label: 'Time of event',
        sortable: false
      },
      {
        key: 'codes',
        label: 'Status',
        sortable: false
      },
      {
        formatter: (value: string, key: string, item: EventLog) => {
          const fields = []
          if (item.terminalLocation != null && item.terminalLocation.name.length > 0) {
            fields.push(item.terminalLocation.name)
          }

          if (item.terminalStation != null && item.terminalStation.name.length > 0) {
            fields.push(item.terminalStation.name)
          }

          if (item.operator != null && item.operator.userName.length > 0) {
            fields.push(item.operator.userName)
          }

          return `${fields.join(', ')}`
        },
        class: 'wpx-150',
        key: 'location',
        label: 'Terminal location',
        sortable: false
      }
    ]
  }

  /**
   * The list of verifications retrieved from the Store that need processing
   * @property
   * @see {@link Verification}
   * @returns {Verification}
   */
  public get verifications() {
    return this.isMerge ? this.mergeState.verifications : this.verifyState.verifications
  }

  /**
   * Clears the entire state and resets current person
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
    this.person.clear()
    this.mergeState.clear()
    this.verifyState.clear()
  }

  /**
   * Clears all duplicates retrieved for the current person
   * @function
   * @returns {void}
   */
  public clearDuplicates() {
    this.module.clearDuplicates()
  }

  /**
   * Clears all Verifications
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.mergeState.clearResults()
    this.verifyState.clearResults()
  }

  /**
  * The total number of verifications in the Store which need processing
  * @function
  * @returns {boolean}
  */
  public count(mode = this.mode) {
    return (mode == VerificationType.MERGE) ? this.mergeState.count : this.verifyState.count
  }

  /**
   * Creates an ID Query on the current verification
   * @function
   * @returns {void}
   */
  public async createIdQuery() {
    try {
      this.isMerge ? await this.mergeState.createIdQuery() : await this.verifyState.createIdQuery()

      if (this.isMerge && this.status == ViewStatus.SUCCEEDED) {
        this.search.update(this.verification.original)
        if (this.verification.duplicate != null) {
          this.search.update(this.verification.duplicate)
        }

        this.moveNext()
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a list of People from the Store who may be duplicates of the
   * person who's Id is specified
   * @function
   * @param {number} personId - The Person Id
   * @returns {Person[]}
   */
  public async fetchDuplicates(personId: number) {
    try {
      await this.module.getDuplicates(personId)
      return this.duplicates
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a list of Verifications from the Store using the current state
   * Query Params that have been populated from the UI. 
   * @function
   * @returns {void}
   */
  public async fetch(queryParams?: QueryParams) {
    const params = queryParams ?? new QueryParams()
    params.policeReportStatus = PoliceReportStatus.REQUESTED
    params.verificationType = this.mode

    this.policeReports.fetch(params)
    this.isMerge ? await this.mergeState.fetch(params) : await this.verifyState.fetch(params)
  }

  /**
  * Whether the current State has retrieved any Verifications of the type specified to
  * indicate whether a search has already been peformed and the page already
  * has results loaded.
  * @function
  * @returns {boolean}
  */
  public hasState(mode = this.mode) {
    return (mode == VerificationType.MERGE) ? this.mergeState.hasState : this.verifyState.hasState
  }

  /**
   * Moves to the next record
   * @function
   * @returns {void}
  */
  public moveNext() {
    if (this.isComplete) {
      return
    }

    this.isMerge ? this.mergeState.updateProgress() : this.verifyState.updateProgress()
    this.update()
  }


  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  public popRoute() {
    const route = _.last(this.module.returnRoutes)
    this.module.popRoute()
    return route
  }

  /**
  * Adds a new route to the navigation stack.
  * This should be called when the user navigates away from a page but will
  * have the option to return back on the destination page.
  * stack with a new route
  * @function
  * @param {Route} route - The route to add to the stack
  * @param {boolean} replace - Whether to replace all existing routes with this route
  * @return {void}
  */
  public pushRoute(route: Route) {
    this.module.pushRoute(route)
  }

  /**
  * Clears the current State and will retrieve Police Reports from the API again
  * @function
  * @return {void}
  */
  public reset() {
    this.clear()
  }

  /**
   * Saves the current Verification record into one and moves next if specified.  This will
  * merge the Duplicate data into the Original data and removes the Duplicate from the database.
   * @function
   * @param {boolean} next = Whether to continue to the next record. Defaults to false
   * @returns {void}
   */
  public async submit() {
    try {
      if (this.verification.typeId == VerificationType.MERGE) {
        await this.mergeState.submit()
      }
      else {
        this.person.status = ViewStatus.SAVING
        await this.verifyState.submit()
      }

      if (this.status == ViewStatus.SUCCEEDED) {
        this.search.update(this.verification.original)

        this.moveNext()
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Converts that Verification from a Merge to Verify, or Verify to Merge at the current index.
   * If converting from Verify to Merge, this typically means an existing person has been identified
   * as already existing so the Verification record is actually a Merge as it's a duplicate.
   * @function
   * @param {Person} person = An existing Person that has been identified matching and is thus, the original.
   * @returns {void}
   */
  public swap(person?: Person) {
    this.isMerge ? this.mergeState.swap(person) : this.verifyState.swap(person)
    this.setVerification(this.verifications[0])
    this.clearDuplicates()
  }

  /**
   * Update state and storage for the latest person.
   */
  public async update() {
    if (this.verifications.length == 0) {
      return
    }

    this.setVerification(this.verifications[0])
    this.person.setPerson(this.verification.original)
    this.clearDuplicates()


    if (!this.isMerge) {
      this.person.getMetadata()

      if (this.mode == VerificationType.VERIFY && this.verification.original.isMatchable) {
        this.fetchDuplicates(this.person.id)
      }

      if (this.verifications.length == 5) {
        const queryParams = this.queryParams
        queryParams.currentPage += 1

        this.verifyState.fetch(queryParams)
      }
    }
    else if (!this.mergeState.hasEvents) {
      await this.mergeState.fetchEvents()
      this.setVerification(this.verifications[0])
    
      if (this.verifications.length == 5) {
        const queryParams = this.queryParams
        queryParams.currentPage += 1

        this.mergeState.fetch(queryParams)
      }
    }
  }

  private setVerification(verification: Verification) {
    this.verification = verification
    this.mergeState.verification = verification
    this.verifyState.verification = verification
  }

}
