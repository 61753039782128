import { GenderType } from '../enums/genderType'

export interface IGender {
  id: number
  readonly initial: string
  name: string
}

export class Gender implements IGender {

  public id: number
  public readonly initial: string
  public name: string
  public readonly uppercaseName: string

  constructor(gender?: IGender) {
    this.id = gender?.id ?? GenderType.FEMALE
    this.name = gender?.name ?? 'Female'
    this.uppercaseName = this.name.toUpperCase();

    this.initial = this.name.substring(0, 1)
  }
}
