import { ApiResponse } from '../api/apiResponse'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'
import qs from 'qs'


export class ApiRepository<T extends I, I extends IJsonIdentity> {

  /** PUBLIC STATIC METHODS*/

  public static urlPath(route: string, id: number | null = null, paths: string[] | null = null) {
    let urlPath = route

    if (id != null) {
      urlPath += `/${id}`
    }

    if (paths != null) {
      paths.forEach(p => urlPath += `/${p}`)
    }

    return urlPath
  }


  /** PRIVATE PROPERTIES */

  protected path: string
  private type: new (i?: I) => T


  constructor(path: string, type: new (i?: I) => T) {
    this.path = path
    this.type = type
  }


  /** PUBLIC METHODS */

  public async count(url?: string) {
      const urlPath = url ?? this.urlPath(this.path, null, [Paths.COUNT])
      const response = await axios.get(urlPath)

      return response.data
  }

  public async delete(entity: T, url?: string) {
      const urlPath = url ?? this.urlPath(this.path, entity.id)
      return await axios.delete(urlPath)
  }

  public async get(id: number, url?: string): Promise<T | undefined> {
      const urlPath = url ?? this.urlPath(this.path, id)
      const response = await axios.get(urlPath)
      const iEntity = response.data as I

      return new this.type(iEntity)
  }

  public async getResponse(url: string, queryParams: QueryParams): Promise<ApiResponse<T, I>> {
      const response = await axios.get(url, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      return new ApiResponse<T, I>(response.data, this.type)
  }

  public async save(entity: T, url?: string): Promise<T | undefined> {
      if (entity.id == 0) {
        const urlPath = url ?? this.urlPath(this.path)
        const response = await axios.post(urlPath, entity)
        const iEntity = response.data as I
        return new this.type(iEntity)
      }
      else {
        const urlPath = url ?? this.urlPath(this.path, entity.id)
        const response = await axios.put(urlPath, entity)
        return response.data
      }
  }

  public urlPath(route: string, id: number | null = null, paths: string[] | null = null) {
    return ApiRepository.urlPath(route, id, paths)
  }

}

