import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { AuthState } from '../states/auth/authState'
import { Routes } from '../constants/routes'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Routes.HOME,
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue')
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Login.vue'),
    meta: { allowAnonymous: true }
  },
  {
    path: '/login/password',
    name: Routes.PASSWORD,
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Password.vue'),
    meta: { allowAnonymous: true }
  },
  {
    path: '/admin',
    name: Routes.ADMIN,
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/home/Admin.vue'),
    children: [
      {
        path: 'terminals/locations',
        name: Routes.TERMINAL_LOCATIONS,
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/terminal-locations/TerminalLocations.vue'),
      },
      {
        path: 'terminals/stations',
        name: Routes.TERMINAL_STATIONS,
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/terminal-stations/TerminalStations.vue'),
      },
      {
        path: 'users',
        name: Routes.USERS,
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Users.vue'),
      },
      {
        path: 'venues',
        name: Routes.VENUES,
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/Venues.vue'),
      },
      {
        path: 'venues/:venueId',
        name: Routes.VENUE,
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venue/Venue.vue'),
      },
    ]
  },
  {
    path: '/customers',
    name: Routes.PATRONS,
    redirect: { name: Routes.PATRON_SEARCH },
  },
  {
    path: '/customers/search',
    name: Routes.PATRON_SEARCH,
    component: () => import(/* webpackChunkName: "search" */ '@/views/patron/search/PatronSearch.vue'),
  },
  {
    path: '/customers/:patronId',
    name: Routes.PATRON_PROFILE,
    component: () => import(/* webpackChunkName: "profile" */ '@/views/patron/profiles/PatronProfile.vue'),
    meta: { routeName: Routes.PATRON },
    props: true,
    children: [
      {
        path: 'events',
        name: Routes.PATRON_EVENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Events.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
      {
        path: 'terminal-comments',
        name: Routes.PATRON_COMMENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/TerminalComments.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
      {
        path: 'notes',
        name: Routes.PATRON_NOTES,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Notes.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
      {
        path: 'communications',
        name: Routes.PATRON_COMMUNICATIONS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Communications.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
      {
        path: 'bans',
        name: Routes.PATRON_BANS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Bans.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
      {
        path: 'views',
        name: Routes.PATRON_VIEWS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/ViewEvents.vue'),
        meta: { routeName: Routes.PATRON },
        props: true
      },
    ]
  },
  {
    path: '/marketing',
    name: Routes.MARKETING,
    component: () => import(/* webpackChunkName: "marketing" */ '@/views/marketing/home/Marketing.vue'),
    redirect: { name: 'MarketingSearch' },
    children: [
      {
        alias: '',
        path: 'search',
        name: Routes.MARKETING_SEARCH,
        component: () => import(/* webpackChunkName: "marketing" */ '@/views/marketing/search/MarketingSearch.vue'),
        props: true
      }
    ]
  },
  {
    path: '/reports',
    name: Routes.REPORTING,
    component: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/Reporting.vue')
  },
  {
    path: '/settings',
    name: Routes.SETTINGS,
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/home/Settings.vue'),
    redirect: { name: Routes.ACCOUNT },
    children: [
      {
        alias: '',
        path: 'account',
        name: Routes.ACCOUNT,
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/account/Account.vue'),
      }
    ]
  },
  {
    path: '/toolbox',
    name: Routes.TOOLBOX,
    redirect: { name: Routes.TOOLBOX_SEARCH },
  },
  {
    path: '/toolbox/policeReports',
    name: Routes.POLICE_REPORTS,
    component: () => import(/* webpackChunkName: "toolbox" */ '@/views/toolbox/policeReports/PoliceReports.vue'),
  },
  {
    path: '/toolbox/search',
    name: Routes.TOOLBOX_SEARCH,
    component: () => import(/* webpackChunkName: "toolbox" */ '@/views/toolbox/search/ToolboxSearch.vue'),
  },
  {
    path: '/toolbox/profiles/:personId',
    name: Routes.PERSON_PROFILE,
    component: () => import(/* webpackChunkName: "toolbox" */ '@/views/toolbox/profiles/PersonProfile.vue'),
    meta: { routeName: Routes.PERSON },
    props: true,
    children: [
      {
        path: 'events',
        name: Routes.PERSON_EVENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Events.vue'),
        meta: { routeName: Routes.PERSON },
        props: true
      },
      {
        path: 'terminal-comments',
        name: Routes.PERSON_COMMENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/TerminalComments.vue'),
        meta: { routeName: Routes.PERSON },
        props: true
      },
      {
        path: 'notes',
        name: Routes.PERSON_NOTES,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Notes.vue'),
        meta: { routeName: Routes.PERSON },
        props: true
      },
      {
        path: 'communications',
        name: Routes.PERSON_COMMUNICATIONS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Communications.vue'),
        meta: { routeName: Routes.PERSON },
        props: true
      },
      {
        path: 'bans',
        name: Routes.PERSON_BANS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Bans.vue'),
        meta: { routeName: Routes.PERSON },
      },
      {
        path: 'views',
        name: Routes.PERSON_VIEWS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/ViewEvents.vue'),
        meta: { routeName: Routes.PERSON },
        props: true
      },
    ]
  },
  {
    path: '/toolbox/verify',
    name: Routes.VERIFY,
    component: () => import(/* webpackChunkName: "toolbox" */ '@/views/toolbox/verify/Verify.vue'),
    meta: { routeName: Routes.VERIFY },
    children: [
      {
        path: 'events',
        name: Routes.VERIFY_EVENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Events.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
      {
        path: 'terminal-comments',
        name: Routes.VERIFY_COMMENTS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/TerminalComments.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
      {
        path: 'notes',
        name: Routes.VERIFY_NOTES,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Notes.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
      {
        path: 'communications',
        name: Routes.VERIFY_COMMUNICATIONS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Communications.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
      {
        path: 'bans',
        name: Routes.VERIFY_BANS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/Bans.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
      {
        path: 'views',
        name: Routes.VERIFY_VIEWS,
        component: () => import(/* webpackChunkName: "profile" */ '@/components/person/ViewEvents.vue'),
        meta: { routeName: Routes.VERIFY },
        props: true
      },
    ]
  },
  



  //{
  //  path: '/login',
  //  name: 'Login',
  //  component: () => import(/* webpackChunkName: "seettings"*/ '@/views/settings/Login.vue')
  //},
  //{
  //  path: '/forgot',
  //  name: 'Forgot',
  //  component: () => import(/* webpackChunkName: "seettings"*/ '@/views/settings/Forgot.vue')
  //},
  //{
  //  path: '/setpassword',
  //  name: 'SetPassword',
  //  component: () => import(/* webpackChunkName: "seettings"*/ '@/views/settings/SetPassword.vue')
  //},
  //
  //{
  //  path: '/admin/venuedetails/:id',
  //  name: 'VenueDetails',
  //  component: () => import(/* webpackChunkName: "profile" */ '@/views/admin/VenueDetails.vue'),
  //},

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})


router.beforeResolve((to, from, next) => {
  const state = new AuthState(router.app.$store)
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next()
  }
  else {
    state.isAuthenticated ? next() : next('/login')
  }
});

export default router
