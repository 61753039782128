import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { Note, INote } from '../models/note'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

import axios from 'axios'
import qs from 'qs'

export class NoteRepository extends ApiRepository<Note, INote> {

  constructor(path = Paths.NOTES, type: new (i?: INote) => Note = Note) {
    super(path, type)
  }

  public async getLastNote(id: number, queryParams: QueryParams): Promise<Note|undefined> {
      if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
        return undefined;
      }

      const url = this.urlPath(Paths.PERSONS, id, [this.path, Paths.LAST])
      const response = await axios.get(url, {
        params: queryParams.toQueryString(),
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
      })

      const iNote = response.data as INote
      return new Note(iNote)
  }

  public async getNotes(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<Note, INote>(null, Note);
    }

    const url = this.urlPath(Paths.PERSONS, id, [this.path])
    return this.getResponse(url, queryParams)
  }
}
