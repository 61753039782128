import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IBanStatus extends IJsonIdentity {
  name: string

  copy(): IBanStatus
}

export class BanStatus implements IBanStatus {
  public id: number
  public name: string

  constructor(banStatus?: IBanStatus) {
    this.id = banStatus?.id ?? 0
    this.name = banStatus?.name ?? ''
  }

  public copy(): BanStatus {
    return new BanStatus(this)
  }
}
