import { DateFormatter } from '../formatters/dateFormatter'

export interface IAccessToken {
  expiryDate: Date
  refreshToken: string
  token: string
}

export class AccessToken implements IAccessToken  {

  public expiryDate: Date
  public refreshToken: string
  public token: string

  constructor(accessToken?: IAccessToken) {
    this.expiryDate = DateFormatter.parse(accessToken?.expiryDate, new Date())
    this.refreshToken = accessToken?.refreshToken ?? ''
    this.token = accessToken?.token ?? ''
  }
}
