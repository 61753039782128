import { Store } from 'vuex'

import LookupModule from '@/ts/store/lookup/lookupModule'
import { getModule } from 'vuex-module-decorators'


import { BanStatus } from '@/ts/models/banStatus'
import { EventType } from '@/ts/models/eventType'
import { Gender } from '@/ts/models/gender'
import { LookupRepository } from '@/ts/repositories/lookupRepository'
import { MessageSuggestion } from '@/ts/models/messageSuggestion'
import { Organisation } from '@/ts/models/organisation'
import { OrganisationType } from '@/ts/models/organisationType'
import { PersonStatus } from '@/ts/models/personStatus'
import { PersonStatusType } from '@/ts/enums/personStatusType'
import { ProofOfIdType } from '@/ts/models/proofOfIdType'
import { TerminalLocation } from '@/ts/models/terminalLocation'
import { UserRole } from '@/ts/models/userRole'


/**
 * State management of Lookup searches for UI dropdowns
 * @class
 */
export class LookupState {

  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link LookupModule}
   * @returns {LookupModule}
   */
  private module: LookupModule


  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link LookupRepository}
   * @returns {LookupRepository}
   */
  private repo = new LookupRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(LookupModule, store)
  }


  /**
   * The list of pre-defined suggestions for ban reasons/messages saved in the Store
   * @property
   * @see {@link MessageSuggestion}
   * @returns {MessageSuggestion[]}
   */
  public get banSuggestions() {
    return this.module.iBanSuggestions.map(i => new MessageSuggestion(i))
  }

  /**
   * The list of pre-defined ban statuses for searching saved in the Store
   * @property
   * @see {@link BanStatus}
   * @returns {BanStatus[]}
   */
  public get banStatuses() {
    return this.module.iBanStatuses.map(i => new BanStatus(i))
  }

  /**
   * The list of pre-defined event types for marketing searches and filtering saved in the Store
   * @property
   * @see {@link EventType}
   * @returns {EventType[]}
   */
  public get eventTypes() {
    return this.module.iEventTypes.map(i => new EventType(i))
  }

  /**
   * The list of pre-defined genders saved in the Store
   * @property
   * @see {@link Gender}
   * @returns {Gender[]}
   */
  public get genders() {
    return this.module.iGenders.map(i => new Gender(i))
  }

  /**
   * The list of pre-defined Organisations saved in the Store
   * @property
   * @see {@link Organisation}
   * @returns {Organisation[]}
   */
  public get organisations() {
    return this.module.iOrganisations.map(i => new Organisation(i))
  }

  /**
   * The list of pre-defined Organisation Types saved in the Store
   * @property
   * @see {@link OrganisationType}
   * @returns {OrganisationType[]}
   */
  public get organisationTypes() {
    return this.module.iOrganisationTypes.map(i => new OrganisationType(i))
  }

  /**
   * The list of pre-defined Person Statuses saved in the Store
   * @property
   * @see {@link PersonStatus}
   * @returns {PersonStatus[]}
   */
  public get personStatuses() {
    return this.module.iPersonStatuses.map(i => new PersonStatus(i))
  }

  /**
   * The list of pre-defined Proof Of Id types saved in the Store
   * @property
   * @see {@link ProofOfIdType}
   * @returns {ProofOfIdType[]}
   */
  public get proofOfIdTypes() {
    return this.module.iProofOfIdTypes.map(i => new ProofOfIdType(i))
  }

  /**
   * The list of pre-defined suggestions for Terminal Comment messages saved in the Store
   * @property
   * @see {@link MessageSuggestion}
   * @returns {MessageSuggestion[]}
   */
  public get terminalCommentSuggestions() {
    return this.module.iTerminalCommentSuggestions.map(i => new MessageSuggestion(i))
  }

  /**
   * The list of Terminal Locations saved in the Store
   * @property
   * @see {@link TerminalLocation}
   * @returns {TerminalLocation[]}
   */
  public get terminalLocations() {
    return this.module.iTerminalLocations.map(i => new TerminalLocation(i))
  }

  /**
   * The list of User Roles saved in the Store
   * @property
   * @see {@link UserRole}
   * @returns {UserRole[]}
   */
  public get userRoles() {
    return this.module.iUserRoles.map(i => new UserRole(i))
  }

  public get isEmpty() {
    return this.banStatuses.length == 0
      && this.banSuggestions.length == 0
      && this.eventTypes.length == 0
      && this.genders.length == 0
      && this.organisationTypes.length == 0
      && this.personStatuses.length == 0
      && this.proofOfIdTypes.length == 0
      && this.terminalCommentSuggestions.length == 0
      && this.userRoles.length == 0
  }

  /**
   * Retreives all pre-defined values saved in the the Store
   * @function
   * @returns {void}
   */
  public fetch() {
    this.getBanSuggestions()
    this.getBanStatuses()
    this.getEventTypes()
    this.getGenders()
    this.getProofOfIdTypes()
    this.getOrganisationTypes()
    this.getPersonStatuses()
    this.getProofOfIdTypes()
    this.getTerminalCommentSuggestions()
    this.getUserRoles()
  }

  /**
   * Retrieves a refreshed set of Message Suggestions from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {MessageSuggestion[]}
   */
  public async getBanSuggestions() {
    try {
      await this.module.getBanSuggestions()
      return this.banSuggestions
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Ban Statuses from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {BanStatus[]}
   */
  public async getBanStatuses() {
    try {
      await this.module.getBanStatuses()
      return this.banStatuses
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Event Types from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {EventType[]}
   */
  public async getEventTypes() {
    try {
      this.module.getEventTypes()
      return this.eventTypes
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Genders from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {Gender[]}
   */
  public async getGenders() {
    try {
      await this.module.getGenders()
      return this.genders
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Proof Of Id Types from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {ProofOfIdType[]}
   */
  public async getProofOfIdTypes() {
    try {
      await this.module.getProofOfIdTypes()
      return this.proofOfIdTypes
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Organisations from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {Organisation[]}
   */
  public async getOrganisations() {
    try {
      await this.module.getOrganisations()
      return this.organisations
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Organisation Types from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {MessageSuggestion[]}
   */
  public async getOrganisationTypes() {
    try {
      await this.module.getOrganisationTypes()
      return this.organisationTypes
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Person Statuses from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {PersonStatus[]}
   */
  public async getPersonStatuses() {
    try {
      await this.module.getPersonStatuses()
      return this.personStatuses
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Terminal Comment Suggestions from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {MessageSuggestion[]}
   */
  public async getTerminalCommentSuggestions() {
    try {
      await this.module.getTerminalCommentSuggestions()
      return this.terminalCommentSuggestions
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of Terminal Locations from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {TerminalLocation[]}
   */
  public async getTerminalLocations() {
    try {
      await this.module.getTerminalLocations()
      return this.terminalLocations
    }
    catch (error) {
      console.log(error)
    }

    return []
  }

  /**
   * Retrieves a refreshed set of User Roles from the Store
   * This method forces the Store to retreive the results from the API first
   * @function
   * @returns {UserRole[]}
   */
  public async getUserRoles() {
    try {
      await this.module.getUserRoles()
      return this.userRoles
    }
    catch (error) {
      console.log(error)
    }

    return []
  }


  public personStatus(id: PersonStatusType) {
    return this.personStatuses.find(p => p.id == id)
  }
}
