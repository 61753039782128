import { Store } from 'vuex'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { EventLog } from '@/ts/models/eventLog'
import { EventLogRepository } from '@/ts/repositories/eventLogRepository'
import { Pagination } from '@/ts/models/pagination'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { SelectedEventsState } from './selectedEventsState'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'


export class EventsState {

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()


  /**
   * The State management for selected events.
   * @property
   * @see {@link SelectedEventsState}
   * @returns {SelectedEventsState}
   */
  public selected: SelectedEventsState

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
   * The default number of slides to show in the Events carousel
   * @property
   * @returns {number}
   */
  public slidesToShow = 10

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The pagination used to manage the list of Event Logs in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<EventLog>()

  /**
   * The Person who's details are currently being viewed.
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  private person?: Person

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link EventLogRepository}
   * @returns {EventLogRepository}
   */
  private repo = new EventLogRepository()



  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store: Store<any>, person?: Person) {
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'createdDate'
    this.queryParams.sortDesc = true
    this.selected = new SelectedEventsState(store)

    if (person != null) {
      this.person = person
      this.fetch()
    }
  }


  /**
   * The total number of Event Logs for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * The list of Event Logs for the current page
   * @property
   * @returns {EventLog[]} The list of Event Logs for the current page
   */
  public get events() {
    return this.pagination.page()
  }

  /**
   * Whether there are more Event Logs that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current State has retrieved any Event Logs to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
 * A key/value map to display Event Logs in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'wpx-50',
        key: 'id',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.Long)
        },
        key: 'createdDate',
        label: 'Date of event',
        sortable: true,
      },
      {
        class: 'font-weight-bold',
        key: 'createdTime',
        label: 'Time of event',
        sortable: false
      },
      {
        key: 'codes',
        label: 'Status',
        sortable: false
      },
      {
        key: 'operator.userName',
        label: 'Operator',
        sortable: true
      },
      {
        key: 'terminalStation.name',
        label: 'Terminal station',
        sortable: true
      },
      {
        key: 'terminalLocation.name',
        label: 'Terminal location',
        sortable: true
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 1, 1)
    }

    return fields
  }

  /**
   * The total number of pages of results
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }
 

 
  /**
   * Clears the Event Logs currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.clearResults()
    this.selected.clear()
    this.status = ViewStatus.NONE
  }

  /**
  * Clears all results and selected results and resets pagination
  * @function
  * @returns {void} 
  */
  public clearResults() {
    this.pagination.reset()
    this.selected.clearResults()
  }

  public getEvent(id: number) {
    return this.events.find(e => e.id == id) ?? new EventLog()
  }

  /**
   * Retrieves a list of Event Logs from the API.
   * @function
   * @returns {EventLog[]} The list of Event Logs
   */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getEventLogs()
  }

  /**
   * Retrieves a list of Event Logs for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {EventLog[]} The list of Event Logs
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.events
      }

      this.status = ViewStatus.UPDATING
      return await this.getEventLogs()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a fresh list of Event Logs in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {EventLog[]} The list of sorted Event Logs
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getEventLogs()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Event Logs from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @function
   * @returns {EventLog[]} The list of Bans for the current page
   */
  private async getEventLogs() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      const response = await this.repo.getEvents(this.person!.id, this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.clearResults()
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.events
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }
}

