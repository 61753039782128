export class Pagination<T> {

  public currentPage: number
  public totalPages: number
  public totalCount: number

  private results$ = [{ page: 1, results: new Array<T>() }]
 
  constructor() {
    this.currentPage = 1
    this.totalPages = 0
    this.totalCount = 0
  }

  public get hasAllPages() {
    return this.results$.flatMap(r => r.results).length == this.totalCount
  }

  public hasPage(page: number) {
    const index = this.results$.findIndex(r => r.page == page)
    return index > -1 && this.results$[index].results.length > 0
  }

  public page(page?: number) {
    const pg = page ?? this.currentPage
    const index = this.results$.findIndex(r => r.page == pg)
    return index < 0 ? new Array<T>() : this.results$[index].results
  }

  public set(page: number, results: T[]) {
    const index = this.results$.findIndex(r => r.page == page)
    if (index < 0) {
      this.results$.push({ page, results })
    }
    else {
      this.results$[index].results = results
    }
  }

  public reset(keepPage = false) {
    if (!keepPage) {
      this.currentPage = 1
    }

    this.results$ = [{ page: 1, results: new Array<T>() }]
    this.totalPages = 0
    this.totalCount = 0
  }
}
