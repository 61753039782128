import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IOrganisationType extends IJsonIdentity {
  type: string

  copy(): IOrganisationType
}

export class OrganisationType implements IOrganisationType {
  public id: number
  public type: string

  constructor(organisationType?: IOrganisationType) {
    this.id = organisationType?.id ?? 0
    this.type = organisationType?.type ?? 'Cloud'
  }

  public copy(): OrganisationType {
    return new OrganisationType(this)
  }
}
