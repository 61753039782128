
export interface IUserRoleClaim {
  claim: string
  permission: number

  copy(): IUserRoleClaim
}

export class UserRoleClaim implements IUserRoleClaim {

  public claim: string
  public permission: number


  constructor(roleClaim?: IUserRoleClaim) {
    this.claim = roleClaim?.claim ?? ''
    this.permission = roleClaim?.permission ?? 0
  }

  public copy(): IUserRoleClaim {
    return new UserRoleClaim(this)
  }
}
