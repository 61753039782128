import { Editor } from './editor'
import { Person } from '../models/person'
import { PersonRepository } from '../repositories/personRepository'
import { PersonStatusType } from '../enums/personStatusType'
import { Store } from 'vuex'

import { isValid } from 'date-fns'

/**
 * An editor for Persons
 * @class
 */
export class PersonEditor extends Editor<Person> {


  /* PRIVATE PROPERTIES */

  private repo = new PersonRepository()


  constructor(original = new Person(), store: Store<any>) {
    super(original, store)
  }


  /* COMPUTED PROPERTIES */

  public get isIdQuery() {
    return this.edit.is(PersonStatusType.IDQUERY) && !this.original.is(PersonStatusType.IDQUERY)
  }

  /* PUBLIC METHODS */

  public reset() {
    this.edit = new Person()
    this.original = new Person()
  }

  public deleteFingerPin() {
    this.edit.hasFingerPin = false
  }

  public resetPersonStatus() {
    if (this.edit.personStatus!.id == PersonStatusType.IDQUERY) {
      this.edit.personStatus = this.original.personStatus?.copy()
    }
  }

  public async patch() {
    this.edit.capitalize()
    this.edit.trim()

    await this.repo.patch(this.edit)
    this.original.update(this.edit)
  }

  public async save() {
    if (this.original.proofOfId.photo?.file != this.edit.proofOfId.photo?.file) {  
      if (this.original.proofOfId.photo && this.edit.proofOfId.photo) {
        this.original.proofOfId.photo.file = this.edit.proofOfId.photo.file

        await this.repo.save(this.original)
         this.original.update(this.edit)
      }
    }
    else if (this.original.proofOfId.scan?.file != this.edit.proofOfId.scan?.file) { 
      if (this.original.proofOfId.scan && this.edit.proofOfId.scan) {
        this.original.proofOfId.scan.file = this.edit.proofOfId.scan.file

        await this.repo.save(this.original)
        this.original.update(this.edit) 
      }
    }
    else {
      this.edit.capitalize()
      this.edit.trim()
      this.edit.fullname = this.edit.givenNames + ' ' + this.edit.surname
      this.edit.proofOfId.clean()
      await this.repo.save(this.edit)
      if (this.original.hasFingerPin && !this.edit.hasFingerPin) {
        await this.repo.deleteFingerPin(this.original.id)
      }
      this.original.update(this.edit)
    }

  }

}
