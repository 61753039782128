import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { confirmed, email, min, required } from 'vee-validate/dist/rules'

extend('confirmed', confirmed)
extend('email', email)
extend('min', min)
extend('required', required)



Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)

export interface IValidationState { dirty: boolean; validated: boolean; valid: boolean | null }
