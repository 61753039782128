export class Claims {

  public static ADMIN = 'admin'
  public static ADMIN_CUSTOMERS = 'admin.customers'
  public static ADMIN_OPERATORS = 'admin.operators'
  public static ADMIN_LOCATIONS = 'admin.locations'
  public static ADMIN_STATIONS = 'admin.stations'
  public static ADMIN_USERS = 'admin.users'
  public static MARKETING = 'marketing'
  public static PATRON_POLICEREPORTS = 'patron.policeReports'
  public static PATRON_SEARCH = 'patron.search'
  public static PROFILE = 'profile'
  public static PROFILE_ADDRESS = 'profile.address'
  public static PROFILE_BANS = 'profile.bans'
  public static PROFILE_COMMUNICATIONS = 'profile.communications'
  public static PROFILE_DOB = 'profile.dob'
  public static PROFILE_EMAIL = 'profile.email'
  public static PROFILE_EVENTLOGS = 'profile.eventLogs'
  public static PROFILE_NAME = 'profile.name'
  public static PROFILE_NOTES = 'profile.notes'
  public static PROFILE_PHONE = 'profile.phone'
  public static PROFILE_TERMINALCOMMENTS = 'profile.terminalComments'
  public static PROFILE_VIEWEVENTS = 'profile.viewEvents'
  public static REPORTING = 'reporting'
  public static SETTINGS = 'settings'
  public static SETTINGS_DETAILS = 'settings.details'
  public static SETTINGS_PASSWORD = 'settings.password'
  public static TOOLBOX_MERGE = 'toolbox.merge'
  public static TOOLBOX_POLICEREPORTS = 'toolbox.policeReports'
  public static TOOLBOX_SEARCH = 'toolbox.search'
  public static TOOLBOX_VERIFY = 'toolbox.verify'
}
