import { Module, VuexModule, Mutation, MutationAction } from 'vuex-module-decorators'

import { BanStatus } from '@/ts/models/banStatus'
import { EventType } from '@/ts/models/eventType'
import { Gender } from '@/ts/models/gender'
import { LookupRepository } from '@/ts/repositories/lookupRepository'
import { MessageSuggestion } from '@/ts/models/messageSuggestion'
import { Organisation } from '@/ts/models/organisation'
import { OrganisationType } from '@/ts/models/organisationType'
import { PersonStatus } from '@/ts/models/personStatus'
import { ProofOfIdType } from '@/ts/models/proofOfIdType'
import { TerminalLocation } from '@/ts/models/terminalLocation'
import { UserRole } from '@/ts/models/userRole'

/**
 * Local session storage for pre-defined Look Ups
 * @module
 */
@Module({ name: 'LookupModule', namespaced: true })
export default class LookupModule extends VuexModule {

  /**
  * A list of pre-defined suggestions for ban reasons/messages
  * @property
  * @see {@link MessageSuggestion}
  * @returns {MessageSuggestion[]}
  */
  public iBanSuggestions = new Array<MessageSuggestion>()

  /**
  * A list of pre-defined ban statuses for searching
  * @property
  * @see {@link BanStatus}
  * @returns {BanStatus[]}
  */
  public iBanStatuses = new Array<BanStatus>()

  /**
   * A list of pre-defined event types for marketing searches and filtering
   * @property
   * @see {@link EventType}
   * @returns {EventType[]}
   */
  public iEventTypes = new Array<EventType>()

  /**
   * A list of pre-defined genders
   * @property
   * @see {@link Gender}
   * @returns {Gender[]}
   */
  public iGenders = new Array<Gender>()

  /**
   * A list of all Organisations
   * @property
   * @see {@link Organisation}
   * @returns {Organisation[]}
   */
  public iOrganisations = new Array<Organisation>()

  /**
   * A list of pre-defined Organisation Types
   * @property
   * @see {@link OrganisationType}
   * @returns {OrganisationType[]}
   */
  public iOrganisationTypes = new Array<OrganisationType>()

  /**
   * A list of pre-defined Person Statuses
   * @property
   * @see {@link PersonStatus}
   * @returns {PersonStatus[]}
   */
  public iPersonStatuses = new Array<PersonStatus>()

  /**
   * A list of pre-defined Proof Of Id types
   * @property
   * @see {@link ProofOfIdType}
   * @returns {ProofOfIdType[]}
   */
  public iProofOfIdTypes = new Array<ProofOfIdType>()

  /**
   * A list of pre-defined suggestions for Terminal Comment messages
   * @property
   * @see {@link MessageSuggestion}
   * @returns {MessageSuggestion[]}
   */
  public iTerminalCommentSuggestions = new Array<MessageSuggestion>()

  /**
   * All Terminal Locations
   * @property
   * @see {@link TerminalLocation}
   * @returns {TerminalLocation[]}
   */
  public iTerminalLocations = new Array<TerminalLocation>()

  /**
  * All User Roles
  * @property
  * @see {@link UserROle}
  * @returns {UserRole[]}
  */
  public iUserRoles = new Array<UserRole>()


  /**
   * Clears the entire LookUp storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.iBanSuggestions = []
    this.iBanStatuses = []
    this.iEventTypes = []
    this.iGenders = []
    this.iOrganisations = []
    this.iOrganisations = []
    this.iOrganisationTypes = []
    this.iPersonStatuses = []
    this.iProofOfIdTypes = []
    this.iTerminalCommentSuggestions = []
    this.iTerminalLocations = []
    this.iUserRoles = []
  }


  /**
   * Retreives all pre-defined Ban message suggestions from API
   * @function
   * @returns {MessageSuggestion[]}
   */
  @MutationAction
  public async getBanSuggestions() {
    const repo = new LookupRepository()
    const iBanSuggestions = await repo.getBanSuggestions()

    return { iBanSuggestions }
  }

  /**
   * Retreives all pre-defined Ban Statuses from API
   * @function
   * @returns {BanStatus[]}
   */
  @MutationAction
  public async getBanStatuses() {
    const repo = new LookupRepository()
    const iBanStatuses = await repo.getBanStatuses()

    return { iBanStatuses }
  }

  /**
   * Retreives all pre-defined Event Types from API
   * @function
   * @returns {EventType[]}
   */
  @MutationAction
  public async getEventTypes() {
    const repo = new LookupRepository()
    const iEventTypes = await repo.getEventTypes()

    return { iEventTypes }
  }

  /**
   * Retreives all pre-defined Genders from API
   * @function
   * @returns {Gender[]}
   */
  @MutationAction
  public async getGenders() {
    const repo = new LookupRepository()
    const iGenders = await repo.getGenders()

    return { iGenders }
  }

  /**
  * Retreives all Organisations from API
  * @function
  * @returns {Organisation[]}
  */
  @MutationAction
  public async getOrganisations() {
    const repo = new LookupRepository()
    const iOrganisations = await repo.getOrganisations()

    return { iOrganisations }
  }

  /**
    * Retreives all Organisations from API
    * Retreives all pre-defined Organisation Types from API
    * @function
    * @returns {MessageSuggestion[]}
    */
   @MutationAction
  public async getOrganisationTypes() {
    const repo = new LookupRepository()
    const iOrganisationTypes = await repo.getOrganisationTypes()

    return { iOrganisationTypes }
  }

  /**
   * Retreives all pre-defined Person Statuses from API
   * @function
   * @returns {PersonStatus[]}
   */
  @MutationAction
  public async getPersonStatuses() {
    const repo = new LookupRepository()
    const iPersonStatuses = await repo.getPersonStatuses()

    return { iPersonStatuses }
  }

  /**
   * Retreives all pre-defined Proof Of Id types from API
   * @function
   * @returns {ProofOfIdType[]}
   */
  @MutationAction
  public async getProofOfIdTypes() {
    const repo = new LookupRepository()
    const iProofOfIdTypes = await repo.getProofOfIdTypes()

    return { iProofOfIdTypes }
  }


  /**
   * Retreives all pre-defined Terminal Comment message suggestions from API
   * @function
   * @returns {MessageSuggestion[]}
   */
  @MutationAction
  public async getTerminalCommentSuggestions() {
    const repo = new LookupRepository()
    const iTerminalCommentSuggestions = await repo.getTerminalCommentSuggestions()

    return { iTerminalCommentSuggestions }
  }

  /**
   * Retreives all Terminal Locations from API
   * @function
   * @returns {TerminalLocation[]}
   */
  @MutationAction
  public async getTerminalLocations() {
    const repo = new LookupRepository()
    const iTerminalLocations = await repo.getTerminalLocations()

    return { iTerminalLocations }
  }

  /**
   * Retreives all User Roles from API
   * @function
   * @returns {UserRole[]}
   */
  @MutationAction
  public async getUserRoles() {
    const repo = new LookupRepository()
    const iUserRoles = await repo.getUserRoles()

    return { iUserRoles }
  }
}
