import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Media } from './media'
import { ProofOfIdType } from './proofOfIdType'

export interface IProofOfId extends IJsonIdentity {
  id: number
  expiry?: string
  expiryDate?: Date
  number?: string
  photo?: Media 
  scan?: Media
  type: ProofOfIdType

  copy(): IProofOfId
}

export class ProofOfId implements IProofOfId {
  
  public id: number
  public expiryDate?: Date
  public number?: string
  public photo?: Media
  public scan?: Media
  public type: ProofOfIdType

  constructor(proofOfId?: IProofOfId) {
    this.id = proofOfId?.id ?? 0
    this.expiryDate = DateFormatter.parse(proofOfId?.expiryDate)
    this.number = proofOfId?.number
    this.photo = new Media(proofOfId?.photo)
    this.scan = new Media(proofOfId?.scan)
    this.type = proofOfId?.type ?? ProofOfIdType.none()
  }

  public get expiry() {
    return (this.expiryDate != null) ? DateFormatter.format(this.expiryDate, DateStyle.Short) : undefined
  }

  public set expiry(value: string | undefined) {
    this.expiryDate = (value != null) ? DateFormatter.date(value, DateStyle.Short) : undefined
  }

  public copy(): ProofOfId {
    return new ProofOfId(this)
  }

  public clean() {
    if (this.photo != null && this.photo.id == 0) {
      this.photo = undefined
    }

    if (this.scan != null && this.scan.id == 0) {
      this.scan = undefined
    }
  }
}
