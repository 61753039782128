import { Store } from 'vuex'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { Pagination } from '@/ts/models/pagination'
import { Communication } from '@/ts/models/communication'
import { CommunicationRepository } from '@/ts/repositories/communicationRepository'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'


/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class CommunicationsState {

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The pagination used to manage the list of Communications in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<Communication>()

  /**
   * The selected Person the current Communications belong to
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  private person?: Person

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link CommunicationRepository}
   * @returns {CommunicationRepository}
   */
  private repo = new CommunicationRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store?: Store<any>, person?: Person) {
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'createdDate'
    this.queryParams.sortDesc = true

    if (person != null) {
      this.person = person
      this.fetch()
    }
  }


  /**
   * The total number of Communications for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more Communications that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
   * Whether the current State has retrieved any Communications to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The list of Communications for the current page
   * @property
   * @returns {Communication[]} The list of Communications for the current page
   */
  public get communications() {
    return this.pagination.page()
  }

  /**
 * A key/value map to display Communications in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.LongTime)
        },
        key: 'createdDate',
        label: 'Date sent',
        sortable: true,

      },
      {
        key: 'createdBy',
        label: 'Sent by',
        sortable: true
      },
      {
        class: 'w-50',
        key: 'message',
        label: 'Message',
        sortable: false
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 1, 1)
    }

    return fields
  }

  /**
   * The total number of pages of resutls
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }


  /**
   * Clears the Communications currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.pagination.reset()
    this.status = ViewStatus.NONE
  }

  /**
  * Retrieves a list of Communications from the API.
  * If the current page is the first page, pagination will be reset to start again
  * otherwise the status is updating
  * @function
  * @returns {Communication[]} The list of Terminal Locations
  */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getCommunications()
  }

  /**
   * Retrieves a list of Communications for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {TerminalLocation[]} The list of Terminal Locations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.communications
      }

      this.status = ViewStatus.UPDATING
      return await this.getCommunications()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a fresh list of Communications in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalComment[]} The list of sorted Communications
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getCommunications()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Communications from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @property
   * @returns {Communication[]} The list of Communications for the current page
   */
  private async getCommunications() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      const response = await this.repo.getCommunications(this.person!.id, this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.communications
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }
}
