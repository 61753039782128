
  import { Component, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { Routes } from '@/ts/constants/routes'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'


  @Component
  export default class Navigation extends Vue {

    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)
    private state = new SearchState(this.$store)
    private toolboxState = new ToolboxState(this.$store)

    /** COMPUTED PROPERTIES */

    protected get canAdmin() {
      return this.user.hasPermission(Claims.ADMIN, Crud.READ)
    }

    protected get canMarket() {
      return this.user.hasPermission(Claims.MARKETING, Crud.READ)
    }

    protected get canReport() {
      return this.user.hasPermission(Claims.REPORTING, Crud.READ)
    }

    protected get canSearch() {
      return this.user.hasPermission(Claims.PATRON_SEARCH, Crud.READ)
    }

    protected get canSettings() {
      return this.user.hasPermission(Claims.SETTINGS, Crud.READ)
    }

    protected get canToolboxSearch() {
      return this.user.hasPermission(Claims.TOOLBOX_SEARCH, Crud.READ)
    }

    protected get isAuthenticated() {
      return this.authState.isAuthenticated && this.authState.isValidated
    }

    protected get isCustomerSearchActive() {
      return this.$route.path.startsWith('/customer') || this.$route.path === '/'
    }

    protected get isFullScreen() {
      return this.toolboxState.fullscreen
    }

    protected get isToolboxSearchActive() {
      return this.$route.path.startsWith('/toolbox')
    }

    protected get showNav() {
      return this.isAuthenticated && !this.isFullScreen
    }

    protected get user() {
      return this.authState.user
    }

   


    /** EVENTS **/

    protected onCustomerSearch() {
      this.state.clear()
      this.$router.push({ name: Routes.PATRON_SEARCH })
    }

    protected onLogout() {
      this.authState.logout()
      this.$router.push({ name: Routes.LOGIN })
    }



    protected onToolbox() {
      this.state.clear()
      this.$router.push({ name: Routes.TOOLBOX_SEARCH })
    }
  }
