import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Patron } from './patron'
import { Person } from './person'

export interface IAudit extends IJsonIdentity {
  createdDate: Date
  patron?: Patron
  person: Person
  createdBy?: string

  copy(): IAudit
}

export class Audit implements IAudit {

  public createdDate: Date
  public id: number
  public patron?: Patron
  public person: Person
  public createdBy?: string

  constructor(audit?: IAudit) {
    this.createdDate = DateFormatter.parse(audit?.createdDate, new Date())
    this.id = audit?.id ?? 0
    this.patron = audit?.patron != null ? new Patron(audit?.patron) : undefined
    this.person = new Person(audit?.person)
    this.createdBy = audit?.createdBy
  }

  public copy(): Audit {
    return new Audit(this)
  }
}
