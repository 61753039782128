import { isPast } from 'date-fns'
import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Patron } from './patron'
import { Person } from './person'

export interface ITerminalComment extends IJsonIdentity {
  comment: string
  createdBy?: string
  createdDate: Date
  fromDate: Date
  isExpired: boolean
  readonly isIdQuery: boolean
  modifiedBy?: string
  modifiedDate: Date
  patron?: Patron
  person: Person
  toDate?: Date 

  copy(): ITerminalComment
  isEqual(terminalComment: TerminalComment): boolean
}

export class TerminalComment implements ITerminalComment {

  public comment: string
  public createdBy?: string
  public createdDate: Date
  public fromDate: Date
  public id: number
  public modifiedBy?: string
  public modifiedDate: Date
  public patron?: Patron
  public person: Person
  public toDate?: Date

  public get isExpired() {
    return this.toDate != null && isPast(this.toDate!) 
  }

  public get isIdQuery() {
    return this.person != null && this.patron == null
  }

  constructor(terminalComment?: ITerminalComment) {
    this.comment = terminalComment?.comment ?? ''
    this.createdBy = terminalComment?.createdBy
    this.createdDate = DateFormatter.parse(terminalComment?.createdDate, new Date())
    this.fromDate = DateFormatter.parse(terminalComment?.fromDate, new Date())
    this.id = terminalComment?.id ?? 0
    this.modifiedBy = terminalComment?.modifiedBy
    this.modifiedDate = this.createdDate
    this.patron = terminalComment?.patron != null ? new Patron(terminalComment?.patron) : undefined
    this.person = new Person(terminalComment?.person)
    this.toDate = DateFormatter.parse(terminalComment?.toDate, undefined)
  }

  public copy(): TerminalComment {
    return new TerminalComment(this)
  }

  public isEqual(terminalComment: TerminalComment) {
    return this.comment == terminalComment.comment
      && this.createdDate == terminalComment.createdDate
      && this.createdBy == terminalComment.createdBy
      && this.fromDate == terminalComment.fromDate
      && this.id == terminalComment.id
      && this.person.id == terminalComment.person.id
      && this.toDate == terminalComment.toDate
  }
}
