import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { Gender } from './gender'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Match } from './match'
import { Media } from './media'
import { Patron } from './patron'
import { PersonStatus } from './personStatus'
import { PersonStatusType } from '../enums/personStatusType'
import { ProofOfId } from './proofOfId'

import _ from 'lodash'
import { isEqual, isValid, isFuture } from 'date-fns'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

export interface IPerson extends IJsonIdentity {
  address?: string
  readonly age: number
  country?: string
  deletedDate?: Date
  dateOfBirth: string
  dob?: Date
  email?: string
  fullname?: string
  gender: Gender
  givenNames?: string
  hasFingerPin: boolean
  readonly isBanned: boolean
  readonly isMatchable: boolean
  readonly isTagged: boolean
  readonly isValidDob: boolean
  readonly isValidEmail: boolean
  readonly latestPhoto: Media
  mobile?: string
  modifiedBy?: number
  modifiedDate?: Date
  patrons: Patron[]
  personStatus: PersonStatus
  postcode?: string
  proofOfId: ProofOfId
  state?: string
  suburb?: string
  surname?: string

  capitalize(): void
  copy(): IPerson
  is(type: PersonStatusType): boolean
  isEqual(person: IPerson): boolean
  match(person: IPerson): Match
  trim(): void
  update(person: IPerson): IPerson
  validate(): boolean
}

export class Person implements IPerson {

  public address?: string
  public country?: string
  public deletedDate?: Date
  public dob?: Date
  public fullname?: string
  public gender: Gender
  public givenNames?: string
  public email?: string
  public hasFingerPin: boolean
  public id: number
  public mobile?: string
  public modifiedDate?: Date
  public modifiedBy?: number
  public patrons: Patron[]
  public personStatus: PersonStatus
  public postcode?: string
  public proofOfId: ProofOfId
  public state?: string
  public suburb?: string
  public surname?: string

  public get age() {
    return (this.dob != null && isValid(this.dob)) ? DateFormatter.age(this.dob) : 0
  }

  public get dateOfBirth() {
    return DateFormatter.format(this.dob, DateStyle.Short)
  }

  public set dateOfBirth(value: string) {
    this.dob = DateFormatter.date(value, DateStyle.Short)
  }

  public get isBanned() {
    return this.patrons.findIndex(p => p.isBanned) > -1
  }

  public get isMatchable() {
    if (this.surname == null || this.surname.length == 0) {
      return false
    }

    if (this.givenNames == null || this.givenNames.length == 0) {
      return false
    }

    if (this.dob == null) {
      return false
    }

    return true
  }

  public get isValidDob() {
    if (this.dob == null) {
      return true
    }

    return !isFuture(this.dob)
  }

  public get isValidEmail() {
    if (this.email == null) {
      return true
    }

    return isEmpty(this.email) || isEmail(this.email)
  }

  public get isTagged() {
    return this.patrons.findIndex(p => p.taggedDate != null) > -1
  }

  public get latestPhoto() {
    const photos = this.patrons.map(p => p.latestPhoto)
    switch (photos.length) {
      case 0:
        return new Media()
      case 1:
        return photos[0]
      default: {
        return _.orderBy(photos, ['createdDate'], ['desc'])[0]
      }
    }
  }

  constructor(person?: IPerson) {
    this.address = person?.address
    this.country = person?.country
    this.deletedDate = DateFormatter.parse(person?.deletedDate)
    this.dob = DateFormatter.parse(person?.dob, null)
    this.fullname = person?.fullname
    this.gender = new Gender(person?.gender)
    this.givenNames = person?.givenNames
    this.email = person?.email
    this.hasFingerPin = person?.hasFingerPin ?? false
    this.id = person?.id ?? 0
    this.mobile = person?.mobile
    this.modifiedBy = person?.modifiedBy
    this.modifiedDate = DateFormatter.parse(person?.modifiedDate, new Date())
    this.patrons = (person?.patrons != null) ? person.patrons.map(p => new Patron(p)) : []
    this.personStatus = new PersonStatus(person?.personStatus)
    this.postcode = person?.postcode
    this.proofOfId = new ProofOfId(person?.proofOfId)
    this.state = person?.state
    this.suburb = person?.suburb
    this.surname = person?.surname
  }

  public trim(): void {
    this.address = this.address?.trim()
    this.country = this.country?.trim()
    this.givenNames = this.givenNames?.trim()
    this.fullname = this.fullname?.trim()
    this.email = this.email?.trim()
    this.mobile = this.mobile?.trim()
    this.state = this.state?.trim()
    this.suburb = this.suburb?.trim()
    this.surname = this.surname?.trim()
    this.postcode = this.postcode?.trim()
  }

  public capitalize(): void {
    this.givenNames = this.givenNames?.toUpperCase()
    this.fullname = this.fullname?.toUpperCase()
    this.surname = this.surname?.toUpperCase()
    this.postcode = this.postcode?.toUpperCase()
  }

  public copy(): Person {
    return new Person(this)
  }

  public isEqual(person: Person) {
    return this.address == person.address
      && this.country == person.country
      && this.deletedDate == person.deletedDate
      && this.dob == person.dob
      && this.fullname == person.fullname
      && this.gender.id == person.gender.id
      && this.givenNames == person.givenNames
      && this.hasFingerPin == person.hasFingerPin
      && this.id == person.id
      && this.isBanned == person.isBanned
      && this.latestPhoto?.id == person.latestPhoto?.id
      && this.personStatus.id == person.personStatus.id
      && this.postcode == person.postcode
      && this.proofOfId.id == person.proofOfId.id
      && this.state == person.state
      && this.suburb == person.suburb
      && this.surname == person.surname
  }

  public is(type: PersonStatusType): boolean {
    return this.personStatus.id == type
  }

  public match(person: Person): Match {
    const match = new Match()
    if (this.surname != null && person.surname != null) {
      match.onName = this.surname == person.surname
    }

    if (!match.onName && (this.givenNames != null && person.givenNames != null)) {
      match.onName = this.givenNames == person.givenNames
    }

    if (this.dob != null && person.dob != null) {
      match.onDob = isEqual(this.dob, person.dob)
    }

    if (this.proofOfId != null && person.proofOfId != null) {
      match.onPoi = this.proofOfId.number == person.proofOfId.number
    }

    match.onGender = this.gender.id == person.gender.id

    return match
  }

  public update(person: Person) {
    this.address = person.address
    this.country = person.country
    this.deletedDate = person.deletedDate
    this.dob = person.dob
    this.fullname = person.fullname
    this.gender = new Gender(person.gender)
    this.givenNames = person.givenNames
    this.email = person.email
    this.hasFingerPin = person.hasFingerPin
    this.mobile = person.mobile
    this.modifiedDate = new Date()
    this.personStatus = new PersonStatus(person.personStatus)
    this.postcode = person.postcode
    this.proofOfId = new ProofOfId(person.proofOfId)
    this.state = person.state
    this.suburb = person.suburb
    this.surname = person.surname

    return this
  }

  public validate() {
    return this.isValidEmail && this.isValidDob
  }
}

