import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IProofOfIdType extends IJsonIdentity {
  code: string
  id: number
  name: string

  copy(): IProofOfIdType
}

export class ProofOfIdType implements IProofOfIdType {
  public static none() {
    const idType = new ProofOfIdType()
    idType.id = 5
    idType.code = 'NONE'
    idType.name = 'None'

    return idType
  }

  public code: string
  public id: number
  public name: string

  constructor(proofOfIdType?: IProofOfIdType) {
    this.code = proofOfIdType?.code ?? 'NONE'
    this.name = proofOfIdType?.name ?? 'None'
    this.id = proofOfIdType?.id ?? 5
  }

  public copy(): ProofOfIdType {
    return new ProofOfIdType(this)
  }
}
