import { Store } from 'vuex'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { AuthState } from '@/ts/states/auth/authState'
import { Note } from '@/ts/models/note'
import { NoteEditor } from '@/ts/editors/noteEditor'
import { NoteRepository } from '@/ts/repositories/noteRepository'
import { Pagination } from '@/ts/models/pagination'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'


/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class NotesState {

  /**
   * A Note Editor that is used to store the original values of the editor along with the
   * changed/new values for editing purposes
   * @property
   * @see {@link NoteEditor}
   * @returns {NoteEditor}
   */
  public note: NoteEditor

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
  * The Auth State for retrieving the current logged in user
  * @property
  * @see {@link AuthState}
  * @returns {AuthState}
  */
  public auth: AuthState


  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE

  /**
  * A back-end store for the last Note that was entered.
  * @private
  * @property
  * @see {@link Note}
  * @returns {Note}
  */
  private lastNote = new Note()

  /**
   * The pagination used to manage the list of Notes in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<Note>()

  /**
   * The selected Person the current Notes belong to
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  private person?: Person

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link NoteRepository}
   * @returns {NoteRepository}
   */
  private repo = new NoteRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store: Store<any>, person?: Person) {
    this.auth = new AuthState(store)
    this.note = new NoteEditor(new Note(), store)
    this.queryParams.pageSize = 10
    this.queryParams.sortBy = 'createdDate'
    this.queryParams.sortDesc = true

    if (person != null) {
      this.person = person
      this.fetch()
      this.fetchLast()
    }
  }


  /**
   * The total number of Notes for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
   * Whether there are more Notes that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
  * Whether the current Person has more than one Patron account.
  * This will only be true when the NightKey user is an Admin who is not tied
  * to an Organisation and can therefore see multiple Patrons.
  * @property
  * @returns {boolean}
  */
  public get hasPatrons() {
    return this.person != null && this.person.patrons.length > 1
  }

  /**
   * Whether the current State has retrieved any Notes to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current Note has been edited
   * @property
   * @returns {boolean}
   */
  public get isDirty() {
    return this.note.isDirty
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The last Note that was entered.
   * @property
   * @returns {Note}
   */
  public get last() {
    return this.lastNote
  }

  /**
   * The list of Notes for the current page
   * @property
   * @returns {Note[]} The list of Notes for the current page
   */
  public get notes() {
    return this.pagination.page()
  }

  /**
   * The patrons for this Person
   * @property
   * @returns {Patron[]}
   */
  public get patrons() {
    return this.person != null ? this.person.patrons : []
  }

  /**
 * A key/value map to display Notes in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'font-weight-bold',
        key: 'createdDate',
        label: 'Created date',
        sortable: true,
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.LongTime)
        }
      },
      {
        key: 'createdBy',
        label: 'Set by',
        sortable: true
      },
      {
        key: 'comment',
        label: 'Internal note',
        sortable: false
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'id',
        label: '',
        sortable: false
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 2, 1)
    }

    return fields
  }


  /**
   * The total number of pages of resutls
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }


  /**
   * Clears the Notes currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.lastNote = new Note()
    this.pagination.reset()
    this.reset()
    this.status = ViewStatus.NONE
  }

  /**
   * Deletes the Note currently being viewed
   * @function
   * @returns {void}
   */
  public async delete() {
    try {
      this.status = ViewStatus.DELETING
      await this.note.delete()
      this.update()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      this.status = ViewStatus.FAILED
      console.log(error)
    }
  }

  /**
  * Retrieves a list of Notes from the API.
  * If the current page is the first page, pagination will be reset to start again
  * otherwise the status is updating
  * @function
  * @returns {Note[]} The list of Terminal Locations
  */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getNotes()
  }

  /**
   * Retrieves a list of current Notes from the API.
   * @function
   * @returns {Note[]} The list of Terminal Locations
   */
  public async fetchLast() {
    try {
      this.queryParams.patronIds = this.person?.patrons.map(p => p.id)
      this.lastNote = await this.repo.getLastNote(this.person!.id, this.queryParams) ?? new Note()

      return this.last
    }
    catch (error) {
      console.log(error)
    }

    return null
  }

  /**
   * Retrieves a list of Notes for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {TerminalLocation[]} The list of Terminal Locations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.notes
      }

      this.status = ViewStatus.UPDATING
      return await this.getNotes()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Resets the editor and undoes all changes made
   * @function
   * @returns {void}
   */
  public reset() {
    this.note.reset()
    this.status = ViewStatus.NONE

    if (this.person?.patrons.length == 1) {
      this.note.patrons = this.person.patrons
    }
  }

  /**
   * Saves the edited Notes and updates the existing records
   * @function
   * @returns {void}
   */
  public async save() {
    try {
      this.status = ViewStatus.SAVING

      if (this.note.edit.createdBy == null) {
        this.note.edit.createdBy = this.auth.user.userName
      }

      await this.note.save()
      this.update()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Sets the current Note for the state and assigns the Patron
   * @function
   * @returns {void}
   */
  public setNote(note: Note) {
    this.note.set(note)

    if (note.patron?.id > 0) {
      this.note.patrons = this.person!.patrons.filter(p => p.id == note.patron.id)
    }
    else if (this.person!.patrons.length == 1) {
      this.note.patrons = this.person!.patrons
    }
  }

  /**
   * Retrieves a fresh list of Notes in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalComment[]} The list of sorted Notes
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getNotes()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }


  /**
   * Retrieves a list of Notes from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @function
   * @returns {Note[]} The list of Notes for the current page
   */
  private async getNotes() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      const response = await this.repo.getNotes(this.person!.id, this.queryParams)
      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.notes
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Refreshes the current screen by retrieving the latest set of Notes and
   * current Notes.
   * @private
   * @function
   * @returns {void}
   */
  private update() {
    this.fetch()
    this.fetchLast()
  }
}
