
export class Route {
  public displayName?: string
  public name: string
  public params?: RouteParam[] | null

  constructor(name: string, params?: RouteParam[] | null, displayName?: string) {
    this.name = name
    this.displayName = displayName?.toLowerCase() ?? name.toLowerCase()
    this.params = params ?? new Array<RouteParam>()
  }

  public toPath() {
    if (!this.params) {
      return { name: this.name }
    }

    const params = Object.assign({}, ...this.params.map((p) => ({ [p.name]: p.value })));
    return { name: this.name, params }
  }
}

export class RouteParam {
  public name: string
  public value: string

  constructor(name: string, value: string) {
    this.name = name
    this.value = value
  }
}
