import { Store } from 'vuex'

import { AccessToken } from '@/ts/models/accessToken'
import { AuthRepository } from '@/ts/repositories/authRepository'
import { AuthStatus } from '@/ts/enums/authStatus'
import { PasswordMode } from '@/ts/enums/passwordMode'
import { User } from '@/ts/models/user'
import { ViewStatus } from '@/ts/enums/viewStatus'

import { getModule } from 'vuex-module-decorators'

import AuthModule from '@/ts/store/auth/authModule'
import axios from 'axios'


/**
 * State management for Authentication
 * @class
 */
export class AuthState {

  /**
 * The current authentication status
 * @property
 */
  public authentication = AuthStatus.NONE


  /**
   * The password entered for login purposes.
   * This is only ever stored briefly in memory during the login process.
   * @property
   */
  public password = ''

  /**
   * The password entered for change password purposes.
   * This is only ever stored briefly in memory during the change password process.
   * @property
   */
  public newPassword = ''

  /**
   * The masked phone number of the user.
   * @property
   */
  public phone: string | null = null


  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE
  

  /**
    * The Vuex module which stores data to a local session
    * @private
    * @property
    * @see {@link AuthModule}
    * @returns {AuthModule}
    */
  private module: AuthModule

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link AuthRepository}
   * @returns {AuthRepository}
   */
  private repo = new AuthRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(AuthModule, store)
  }


  /**
   * The JWT token retrieved after a successful login to NightKey.
   * This token should be used as the Bearer token in all subsequent API requests.
   * @property
   * @see {@link AccessToken}
   * @returns {AccessToken}
   */
  public get accessToken() {
    return new AccessToken(this.module.iAccessToken)
  }

  /**
   * The Authorization Header Bearer Token formatted string which should be used on
   * all API requests.
   * @property
   * @see {@link AccessToken}
   * @returns {AccessToken}
   */
  public get bearerToken() {
    return this.accessToken.token.length > 0 ? `Bearer ${this.accessToken.token}` : undefined
  }

  /**
   * Whether the NightKey application user is currently logged in.
   * @property
   * @returns {boolean}
   */
  public get isAuthenticated() {
    const authenticated = this.module.isAuthenticated
    this.authentication = (authenticated) ? AuthStatus.LOGGED_IN : AuthStatus.NONE

    return authenticated
  }

  public set isAuthenticated(value: boolean) {
    this.authentication = (value) ? AuthStatus.LOGGED_IN : AuthStatus.NONE
    this.module.setAuthenticated(value)
  }

  /**
   * Whether the NightKey application user has validated their identity
   * using 2FA authentication via a code.
   * @property
   * @returns {boolean}
   */
  public get isValidated() {
    const validated = this.module.isValidated
    this.authentication = (validated) ? AuthStatus.VALIDATED : AuthStatus.LOGGED_IN 

    return validated
  }

  public set isValidated(value: boolean) {
    this.authentication = (value) ? AuthStatus.VALIDATED : AuthStatus.LOGGED_IN 
    this.module.setValidated(value)
  }

  /**
   * The currently logged in NightKey User
   * @property
   * @see {@link User}
   * @returns {User}
   */
  public get user() {
    return new User(this.module.iUser)
  }

  /**
   * The username entered to login.
   * @property
   *  * @returns {string}
   */
  public get username() {
    return this.module.username
  }

  public set username(value: string) {
    this.module.setUsername(value)
  }


  /**
   * Changes a user's password by comparing their current password, and if successful
   * changing it to the new one
   * @function
   * @returns {void}
   */
  public async changePassword(mode: PasswordMode) {
    if (this.username.length > 0) {
      this.status = ViewStatus.IN_PROGRESS
      try {
        await this.repo.changePassword(mode, this.username, this.password, this.newPassword)
        this.password = ''
        this.newPassword = ''
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
      }
    }
  }

  /**
   * Login a user with username and password
   * @function
   * @returns {void}
   */
  public async login() {
    if (this.username.length > 0 && this.password.length > 0) {
      this.status = ViewStatus.IN_PROGRESS
      try {
        await this.module.login({ username: this.username, password: this.password })
        if (!this.user.passwordResetRequired) {
          this.password = ''
        }

        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
      }
    }
  }

  /**
 * Login in a user with an access code
 * @function
 * @returns {void}
 */
  public async loginCode(accessCode: number) {
      this.status = ViewStatus.IN_PROGRESS
    try {
        await this.module.loginCode({ username: this.username, accessCode })
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
      }
  }

  /**
   * Logout the current user
   * @function
   * @returns {void}
   */
  public async logout() {
    this.reset();
    this.module.logout()
  }

  /**
   * Resends a 2FA validation code if the user did not receive it
   * @function
   * @returns {void}
   */
  public async resendCode() {
    await this.repo.resendCode()
  }


  /**
   * Clears the entire state and resets the status back to NONE
   * @function
   * @returns {void}
   */
  public reset() {
    this.password = ''
    this.username = ''
    this.status = ViewStatus.NONE
  }

  /**
   * Sends a request to initiate a reset password for a user who has forgotten
   * their password and cannot log in
   * @function
   * @returns {void}
   */
  public async forgotPassword() {
    if (this.username.length > 0) {
      this.status = ViewStatus.IN_PROGRESS
      try {
        this.phone = await this.repo.forgotPassword(this.username)
        if (this.phone == null) {
          this.status = ViewStatus.FAILED
          return
        }

        this.authentication = AuthStatus.VERIFYING
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
      }
    }
  }

  /**
   * Validates the 2FA code which a user has entered against the code
   * which is saved in the database.
   * @function
   * @returns {void}
   */
  public async validate(accessCode: string, shouldDelete: boolean) {
    if (accessCode != null && accessCode.length == 6) {
      this.status = ViewStatus.IN_PROGRESS
      try {
        await this.repo.validate(accessCode, this.username, shouldDelete)
        this.status = ViewStatus.SUCCEEDED
      }
      catch (error) {
        this.status = ViewStatus.FAILED
      }
    }
  }
}
