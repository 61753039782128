/**
 * A standard response returned by the API for paginated queries
 * @interface
 */
export interface IApiResponse<I> {

  currentPage: number
  pageSize: number
  result: I[]
  totalCount: number
  totalPages: number
}

/**
 * A standard response returned by the API for paginated queries
 * @class
 */
export class ApiResponse<T extends I, I> implements IApiResponse<I> {

  /**
   * The current page
   * @property
   */
  public currentPage: number

  /**
   * The page size of results
   * @property
   */
  public pageSize: number

  /**
   * The list of results
   * @property
   */
  public result: T[]

  /**
   * The total numberof all results found which match the search criteria
   * @property
   */
  public totalCount: number

  /**
   * The total number of pages found which match the search criteria
   * @property
   */
  public totalPages: number

  /**
   * Instantiates an API Response from the response of the API using generics
   * @constructor
   * @param response
   * @param type
   */
  constructor(response: IApiResponse<I> | null = null, type: new (i: I) => T) {
    this.currentPage = response?.currentPage ?? 1
    this.pageSize = response?.pageSize ?? 25
    this.result = (response != null) ? response.result.map(i => new type(i)) : new Array<T>()
    this.totalCount = response?.totalCount ?? this.result.length
    this.totalPages = response?.totalPages ?? 1
  }
}
