import { SearchMode } from '@/ts/enums/searchMode'
import { Route } from '@/ts/models/route'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'


/**
* Local session storage of a search
* @module
*/

@Module({ name: 'SearchModule', namespaced: true })
export default class SearchModule extends VuexModule {


  /**
   * Whether advanced search is selected
   * @property
   * @returns {bool}
  */
  public isAdvancedSearch = false

  /**
   * The current search mode for viewing persons
   * @property
   * @returns {SearchMode}
  */
  public mode = SearchMode.PERSONS


  /**
   * A list of Routes which form the navigation stack for the application.
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  public returnRoutes = new Array<Route>()

  /**
 * The Y scroll position of the current page
 * @property
 * @returns {number}
 */
  public scrollPosition = 0

  /**
  * The currently selected Id
   * @property
   * @returns {number}
   */
  public selectedId = 0

  /**
   * The current selected tab index on the carousel
   * @property
   * @returns {number}
   */
  public tabIndex = 0
  
  /**
* Empties the navigation stack
* @function
* @returns {void}
*/
  @Mutation
  public clearRoutes() {
    this.returnRoutes = []
  }

  /**
* Removes the last route from the navigation stack and returns it
* @function
* @returns {void}
*/
  @Mutation
  public popRoute() {
    return this.returnRoutes.pop()
  }

  /**
  * Adds a new route to the navigation stack.
  * @function
  * @returns {void}
  */
  @Mutation
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }

  /**
   * Stores the current advanced search
   * @function  
   * @param {bool} advanced
   * @returns {void}
   */
  @Mutation
  public setAdvancedSearch(advanced: boolean) {
    this.isAdvancedSearch = advanced
  }

  /**
   * Stores the current search mode
   * @function  
   * @param {SearchMode} mode
   * @returns {void}
   */
  @Mutation
  public setMode(mode: SearchMode) {
    this.mode = mode
  }


/**
   * Stores the current scroll position
   * @function  
   * @param {number} y
   * @returns {void}
   */
  @Mutation
  public setScrollPosition(y: number) {
    this.scrollPosition = y
  }

   /**
     * Stores the currently selected Id
     * @function  
     * @param {number} y
     * @returns {void}
     */
  @Mutation
  public setSelectedId(id: number) {
    this.selectedId = id
  }

  /**
   * Stores the current scroll position
   * @function    
   * @param {number} index
   * @returns {void}
  */
  @Mutation
  public setTabIndex(index: number) {
    this.tabIndex = index
  }

  
  /**
 * Resets the entire storage
 * @function
 * @returns {void}
 */
  @Mutation
  public clear() {
    this.returnRoutes = []
    this.scrollPosition = 0
    this.tabIndex = 0
  }
}
