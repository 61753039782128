import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { EventLog } from '@/ts/models/eventLog'
import { QueryParams } from '@/ts/api/queryParams'
import { Person } from '@/ts/models/person'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'

import EventLogsModule from '@/ts/store/search/children/eventLogsModule'


export class EventLogsState {

  
  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PersonModule}
   * @returns {PersonModule}
   */
  private module: EventLogsModule


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(EventLogsModule, store)
    this.setStatus()
  }


  /**
   * The list of Event Logs for the current page
   * @property
   * @returns {EventLog[]} The list of Event Logs for the current page
   */
  public get events() {
    return this.module.iEventLogs.map(i => new EventLog(i))
  }

  /**
   * The total number of Event Logs for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.module.count
  }

  /**
   * Whether there are more Event Logs that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return this.module.iEventLogs.length < this.count
  }

  /**
   * Whether the current State has retrieved any Event Logs to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.module.iEventLogs.length > 0
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The total Event Logs that are currently in the Store.
   * This might be less than the selected amount if not all records have been retrieved yet.
   * @property
   * @returns {number}
   */
  public get length() {
    return this.module.iEventLogs.length
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }

  /**
   * Whether to show all Event Logs or just Event Logs for selected Patrons.
   * @property
   * @returns {boolean}
   */
  public get showAll() {
    return this.module.showAll
  }

  public set showAll(show: boolean) {
    this.module.setShowAll(show)
  }

  /**
   * The current index of the Events carousel slide.
   * @property
   * @returns {number}
   */
  public get slideIndex() {
    return this.module.slideIndex
  }

  public set slideIndex(value: number) {
    this.module.setSlideIndex(value)
  }

  /**
   * A key/value map to display Event Logs in a table
   * @property
   * @returns key/value pairs
   */
  public get tableFields() {
    return [
      {
        key: 'id',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return DateFormatter.format(date, DateStyle.Long)
        },
        key: 'createdDate',
        label: 'Date of event',
        sortable: true,
      },
      {
        class: 'font-weight-bold',
        key: 'createdTime',
        label: 'Time of event',
        sortable: false
      },
      {
        key: 'eventTypeId',
        label: 'Status',
        sortable: true
      },
      {
        key: 'operator.name',
        label: 'Operator',
        sortable: true
      },
      {
        key: 'terminalStation.name',
        label: 'Terminal station',
        sortable: true
      },
      {
        key: 'terminalLocation.name',
        label: 'Terminal location',
        sortable: true
      }
    ]
  }


  /**
   * Clears the current state and resets all values
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
    this.status = ViewStatus.NONE
  }

  /**
   * Clears the current selected Event Logs
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.module.clearResults()
  }

  
  /**
   * Retrieves a list of Event Logs from the Store.
   * @function
   * @returns {EventLog[]} The list of Terminal Locations
   */
  public async fetch(queryParams: QueryParams) {
    this.status = (queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getEventLogs(queryParams)
  }

  /**
  * Retrieves an ID by index
  * @function
  * @returns {number} index
  */
  public idAtIndex(index: number) {
    return this.events[index].id
  }

  /**
 * Retrieves the current index of the event by id
 * @function
 * @returns {number} id
 */
  public indexOf(id: number) {
    return this.module.iEventLogs.findIndex(e => e.id == id)
  }

  /**
   * Retrieves a Person at the index
   * @function
   * @returns {number} index
  */
  public personAtIndex(index: number) {
    const event = this.events[index]

    const patron = event.patron!.copy()
    patron.person = undefined

    const person = event.patron!.person!.copy()
    person.patrons.push(patron)

    return person
  }


  /**
   * Retrieves a fresh list of Event Logs in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {EventLog[]} The list of sorted Event Logs
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      const queryParams = this.queryParams.sortedBy(context)

      return await this.getEventLogs(queryParams)
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Updates the current Person with a new Person,
   * or an updated version of the existing Person
   * @private
   * @function
   * @param {Person} person - The updated Person
   * @returns {void}
  */
  public update(person: Person) {
    this.module.update(person)
  }


  /**
   * Retrieves a list of Event Logs from the Store using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @property
   * @returns {EventLog[]} The list of Bans for the current page
   */
  private async getEventLogs(queryParams: QueryParams) {
    try {
      await this.module.getEventLogs(queryParams)
      this.queryParams = queryParams
      this.setStatus()

      return this.events
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Sets the current status based on whether Event Logs have already been
   * retrieved or not
   * private
   * @function
   * @return {void}
   */
  private setStatus() {
    this.status = (this.module.iEventLogs.length > 0) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }

}

