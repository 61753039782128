import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Patron } from './patron'

export interface ICommunication extends IJsonIdentity {
  createdBy?: string
  createdDate: Date
  message: string
  modifiedDate: Date
  patron: Patron

  copy(): ICommunication
}

export class Communication implements ICommunication {

  public createdBy?: string
  public createdDate: Date
  public id: number
  public message: string
  public modifiedDate: Date
  public patron: Patron

  constructor(communication?: ICommunication) {
    this.createdBy = communication?.createdBy
    this.createdDate = DateFormatter.parse(communication?.createdDate, new Date())
    this.id = communication?.id ?? 0
    this.message = communication?.message ?? ''
    this.modifiedDate = DateFormatter.parse(communication?.modifiedDate, new Date())
    this.patron = new Patron(communication?.patron)
  }

  public copy(): Communication {
    return new Communication(this)
  }
}
