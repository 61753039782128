import { ApiRepository } from './apiRepository'
import { Guid } from 'guid-typescript'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'
import { PoliceReport, IPoliceReport } from '../models/policeReport'
import { HttpStatus } from '../enums/httpStatusCode'
import axios from 'axios'

export class PoliceReportRepository extends ApiRepository<PoliceReport, IPoliceReport> {

  constructor(path = Paths.POLICEREPORTS, type: new (i?: IPoliceReport) => PoliceReport = PoliceReport) {
    super(path, type)
  }

  public async getPoliceReports(queryParams: QueryParams) {
    return this.getResponse(this.path, queryParams)
  }

  public async rejectPoliceReportRequest(policeReportId: number, comment: string): Promise<boolean> {
   
    const url = this.urlPath(this.path, policeReportId, [Paths.POLICE_REPORTS_REJECT])
    const formData = new FormData()
    formData.append('reason', comment)
    formData.append('id', String(policeReportId))
    const response = await axios.post(url, formData)
    
    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async approvePoliceReportRequest(id: number, blob: Blob): Promise<boolean> {
    const url = this.urlPath(this.path, id, [Paths.POLICE_REPORTS_APPROVE])
    const formData = new FormData()
    const filename = 'PR-' + Guid.create() + '.pdf'
    formData.append('file', blob, filename)
    formData.append('id', String(id))

    const response = await axios.post(url, formData)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }
}


