import { differenceInYears, format, parseISO, isDate, isValid, parse } from 'date-fns'

export enum DateStyle {
  AMPM = 'a',
  Api = "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'",
  Short = 'dd/MM/yyyy',
  Medium = 'dd MMM yyyy',
  MediumTime = 'EEE dd MMM yy | HH:mm',
  Ordinal = 'do MMM yyyy',
  Long = 'EEE dd MMM yyyy',
  LongTime = "EEE dd MMM yyyy h:mm aaaaa'm'",
  Time = 'hh:mma',
  Picker = 'yyyy-MM-dd'
}

export class DateFormatter {

  public static age(date: Date) {
    return differenceInYears(new Date(), date)
  }

  public static date(date: string, style = DateStyle.Medium) {
    return parse(date, style, new Date())
  }

  public static format(date?: Date | null, style = DateStyle.Medium) {
    if (date == null) {
      return ''
    }

    return format(date, style)
  }

  public static parse(date: any, defaultValue: Date | null = null) {
    if (date == undefined) {
      return defaultValue
    }

    if (date == null) {
      return null
    }

    if (isDate(date) && isValid(date)) {

      return date
    }

    return parseISO(date) ?? new Date()
  }

  public static join(date?: string | null, time?: string | null) {
    if (date == null) {
      return undefined
    }

    const dateTime = (time != null) ? new Date(date + 'T' + time) : new Date(date)
    return this.parse(dateTime)
  }

  public static currentDateStandardlization() {
    return new Date().toISOString().slice(0, 10);
  }

}
