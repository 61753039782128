import { PersonStatusType } from '../enums/personStatusType'
import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IPersonStatus extends IJsonIdentity {
  code: string
  isActive: boolean
  name: string

  copy(): IPersonStatus
}

export class PersonStatus implements IPersonStatus {
  public static variant(status: PersonStatusType) {
    switch (status) {
      case PersonStatusType.IDQUERY:
        return 'info'
      case PersonStatusType.NEW:
        return 'primary'
      default:
        return null
    }
  }

  public code: string
  public id: number
  public isActive: boolean
  public name: string

  constructor(personStatus?: IPersonStatus) {
    this.code = personStatus?.code ?? 'NEW'
    this.name = personStatus?.name ?? 'New'
    this.id = personStatus?.id ?? PersonStatusType.NEW
    this.isActive = personStatus?.isActive ?? true
  }

  public copy(): PersonStatus {
    return new PersonStatus(this)
  }
}
