import { ApiRepository } from './apiRepository'
import { Organisation, IOrganisation } from '../models/organisation'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

export class OrganisationRepository extends ApiRepository<Organisation, IOrganisation> {

  constructor(path = Paths.ORGANISATIONS, type: new (i?: IOrganisation) => Organisation = Organisation) {
    super(path, type)
  }

  public async getOrganisations(queryParams: QueryParams) {
    return this.getResponse(this.path, queryParams)
  }
}
