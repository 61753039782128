import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { Audit, IAudit } from '../models/audit'
import { Ban } from '../models/ban'
import { HttpStatus } from '../enums/httpStatusCode'
import { Paths } from '../constants/paths'
import { Patron } from '../models/patron'
import { Person } from '../models/person'
import { QueryParams } from '../api/queryParams'


import axios from 'axios'
import qs from 'qs'

export class AuditRepository extends ApiRepository<Audit, IAudit> {

  constructor(path = Paths.AUDITS, type: new (i?: IAudit) => Audit = Audit) {
    super(path, type)
  }


  public async getAudits(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<Audit, IAudit>(null, Audit);
    }

    const url = this.urlPath(Paths.PERSONS, id, [Paths.AUDITS])
    return this.getResponse(url, queryParams)
  }

  public async logBanViewed(ban: Ban): Promise<boolean> {
    const urlPath = ApiRepository.urlPath(Paths.AUDITS, null, [Paths.AUDITS, ban.id.toString()])
    const response = await axios.get(urlPath)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async logPatronViewed(patron: Patron): Promise<boolean> {
    const urlPath = ApiRepository.urlPath(Paths.AUDITS, null, [Paths.PATRONS, patron.id.toString()])
    const response = await axios.get(urlPath)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async logPersonViewed(person: Person): Promise<boolean> {
    const urlPath = ApiRepository.urlPath(Paths.AUDITS, null, [Paths.PERSONS, person.id.toString()])
    const response = await axios.get(urlPath)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

  public async logSearch(queryParams: QueryParams): Promise<boolean> {
    const urlPath = ApiRepository.urlPath(Paths.AUDITS, null, [Paths.SEARCH])
    const response = await axios.get(urlPath, {
      params: queryParams.toQueryString(),
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
    })

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return true
  }

}
