export class Images {

  public static DEFAULT = 'default.png'
  public static FEMALE = '/img/female/default.png'
  public static FEMALE_NOT_FOUND = '/img/female/not-found.png'
  public static MALE = '/img/male/default.png'
  public static MALE_NOT_FOUND = '/img/male/not-found.png'
  public static NOT_FOUND = 'not-found.png'

}
