import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { AuditRepository } from '@/ts/repositories/auditRepository'
import { AuthState } from '../auth/authState'
import { DateFormatter } from '@/ts/formatters/dateFormatter'
import { EventLogsState } from './children/eventLogsState'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Organisation } from '@/ts/models/organisation'
import { Person } from '@/ts/models/person'
import { PersonsState } from './children/personsState'
import { QueryParams } from '@/ts/api/queryParams'
import { RoleType } from '@/ts/enums/roleType'
import { Route } from '@/ts/models/route'
import { SearchMode } from '@/ts/enums/searchMode'
import { TerminalLocation } from '@/ts/models/terminalLocation'
import { TerminalLocationRepository } from '@/ts/repositories/terminalLocationRepository'
import { ViewStatus } from '@/ts/enums/viewStatus'


import SearchModule from '@/ts/store/search/searchModule'

import _ from 'lodash'

/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class SearchState {

  /**
   * The Event Logs State for retrieving and displaying a list of Event Logs for a specific Persons
   * @property
   * @see {@link AuthState}
   * @returns {AuthState}
   */
  public authState: AuthState


  /**
   * The Event Logs State for retrieving and displaying a list of Event Logs for a specific Persons
   * @property
   * @see {@link EventsState}
   * @returns {EventsState}
   */
  public eventLogs: EventLogsState


  /**
   * The list of organisations which a Nightkey Admin user might want to filter by.
   * This will not be populated when the Application User belongs to a single Organisation.
   * @property
   * @returns {Organisation[]}
   */
  public organisations?= new Array<Organisation>()

  /**
   * The Persons State for retrieving and displaying a list of Persons
   * @property
   * @see {@link EventsState}
   * @returns {PersonsState}
   */
  public persons: PersonsState

  /**
   * The back-end store for the number of slides to show
   * @property
   * @returns {number}
   */
  public slidesToShow = 0

  /**
   * A custom list of States based on Organisations available
   * @property
   */
  public states = new Array<{ id: number; name: string }>()


  /**
   * The list of terminal locations which a Nightkey user might want to filter by.
   * @property
   * @returns {TerminalLocation[]}
   */
  public terminalLocations?= new Array<TerminalLocation>()



  /**
  * The LookupState for retrieving and displaying pre-defined
  * values in the UI
  * @private
  * @property
  * @returns {LookupState}
  */
  private lookupState: LookupState

  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PersonModule}
   * @returns {PersonModule}
   */
  private module: SearchModule


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(SearchModule, store)
    this.authState = new AuthState(store)
    this.eventLogs = new EventLogsState(store)
    this.lookupState = new LookupState(store)
    this.persons = new PersonsState(store)
   
    this.lookup()
  }


  /**
   * Whether the current State has retrieved any Event Logs to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.persons.count > 0 && this.eventLogs.count > 0
  }

  public get index() {
    switch (this.mode) {
      case SearchMode.PERSONS:
        return this.persons.indexOf(this.selectedId)
      case SearchMode.SELECTED_PERSONS:
        return this.persons.selected.indexOf(this.selectedId)
      case SearchMode.EVENTS:
        return this.eventLogs.indexOf(this.selectedId)
    }
  }

  /**
 * Whether the UI is in advanced search mode.
 * @property
 * @returns {boolean}
 */
  public get isAdvancedSearch() {
    return this.module.isAdvancedSearch
  }

  public set isAdvancedSearch(advanced: boolean) {
    this.module.setAdvancedSearch(advanced)
  }

  /**
   * Whether the select all option has been selected
   * @property
   * @returns {boolean}
   */
  public get isAllSelected() {
    return this.persons.selected.isAllSelected
  }

  public set isAllSelected(selected: boolean) {
    (selected) ? this.persons.selected.selectAll() : this.persons.selected.deselectAll()
  }

  /**
   * Whether some options have been selected
   * @property
   * @returns {boolean}
   */
  public get isIndeterminate() {
    return !this.isAllSelected && this.persons.selected.ids.length > 0
  }

  public set isIndeterminate(indeterminate: boolean) {
    // Nothing needs to happen in here
  }

  public get mode() {
    return this.module.mode;
  }

  public set mode(mode: SearchMode) {
    if (this.persons.selected.hasState && !this.persons.selected.isAllSelected) {
      this.module.setMode(SearchMode.SELECTED_PERSONS)
    }
    else {
      this.module.setMode(mode)
    }
  }

 
  /**
   * The previous Route which should be used to allow a user to go back to a previous screen.
   * This method does not pop the Route of the navigation stack.
   * @property
   * @see {@link Route}
   * @returns {Route}
   */
  public get returnRoute() {
    const iRoute = _.last(this.module.returnRoutes)
    return (iRoute) ? new Route(iRoute.name, iRoute.params, iRoute.displayName) : undefined
  }

  /**
   * The Y scroll position of the current page
   * @property
   * @returns {number}
   */
  public get scrollPosition() {
    return this.module.scrollPosition
  }

  public set scrollPosition(value: number) {
    this.module.setScrollPosition(value)
  }

  /**
   * The currently selected Id
   * @property
   * @returns {number}
   */
  public get selectedId() {
    return this.module.selectedId
  }

  public set selectedId(value: number) {
    this.module.setSelectedId(value)
  }

  /**
   * The Tab Index selected for the carousel which appears in the UI to show events, duplicates etc...
   * @property
   * @returns {number}
   */
  public get tabIndex() {
    return this.module.tabIndex
  }

  public set tabIndex(index: number) {
    this.module.setTabIndex(index)
  }

  /**
   * A key/value map to display Event Logs in a table
   * @property
   * @returns key/value pairs
   */
  public get tableFields() {
    return [
      {
        key: 'id',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        key: 'surname',
        label: 'Surname',
        sortable: true
      },
      {
        key: 'givenNames',
        label: 'Given Names',
        sortable: true
      },
      {
        class: 'text-center',
        key: 'gender.initial',
        label: 'Gender',
        sortable: true
      },
      {
        formatter: (date: Date) => {
          return DateFormatter.format(date)
        },
        key: 'dob',
        label: 'DoB',
        sortable: true
      },
      {
        class: 'text-center',
        formatter: (age: number) => {
          return (age > 0) ? age : ''
        },
        key: 'age',
        label: 'Age',
        sortable: true
      },
      {
        class: 'text-center',
        key: 'personStatus',
        label: 'Customer status',
        sortable: true
      },
      {
        class: 'text-center',
        key: 'hasFingerPin',
        label: 'FingerPin',
        sortable: true
      },
      {
        formatter: (date: Date) => {
          return DateFormatter.format(date)
        },
        key: 'modifiedDate',
        label: 'Modified date',
        sortable: true
      },
      {
        class: 'text-right',
        key: 'more',
        label: '',
        sortable: false
      }
    ]
  }

  /**
   * The total number of records for the current mode
   * @function
   * @returns {number}
   */
  public get total() {
    switch (this.mode) {
      case SearchMode.PERSONS:
        return this.persons.count
      case SearchMode.SELECTED_PERSONS:
        return this.persons.selected.count
      case SearchMode.EVENTS:
        return this.eventLogs.count
    }
  }

  /**
   * Clears the current state and resets all values
   * @function
   * @returns {void}
   */
  public clear() {
    this.eventLogs.clear()
    this.isAdvancedSearch = false
    this.module.clear()
    this.persons.clear()
  }

  /**
   * Clears the current selected Event Logs
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.eventLogs.clearResults()
    this.persons.clearResults()
  }

  /**
   * Retrieves a list of People from the Store.
   * @function
   * @returns {EventLog[]} The list of Terminal Locations
   */
  public fetch(queryParams: QueryParams) {
    this.clearResults()
    this.persons.fetch(queryParams)
    this.eventLogs.fetch(queryParams)
    this.log(queryParams)
 }

  public moveToPerson(index: number) {
    const state = (this.mode == SearchMode.PERSONS) ? this.persons : (this.mode == SearchMode.SELECTED_PERSONS) ? this.persons.selected : this.eventLogs
    if (state.hasMoreResults && state.status != ViewStatus.IN_PROGRESS) {
      if (state.length - index == 5) {
        const queryParams = state.queryParams
        queryParams.currentPage += 1
        state.fetch(queryParams)
      }
    }

    this.selectedId = state.idAtIndex(index);
    return state.personAtIndex(index)
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * This should be called when the user returns to the page by clicking
   * on a back link from the UI.
   * * @function
   * @returns {Route} The last route from the navigation stack
   * @see {@link Route}
   */
  public popRoute() {
    const route = _.last(this.module.returnRoutes)
    this.module.popRoute()
    return route
  }

  /**
   * Adds a new route to the navigation stack.
   * This should be called when the user navigates away from a page but will
   * have the option to return back on the destination page.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  public pushRoute(route: Route, replace = false) {
    if (replace) {
      this.module.clearRoutes()
    }
    this.module.pushRoute(route)
  }


  /**
   * Replaces the Person current stored in State with the new Person  
   * @function
   * @param {Person} person - The person to update
   * @return {Person[]} The list of sorted People
  */
  public update(person: Person) {
    this.persons.update(person)
    this.persons.selected.update(person)
    this.eventLogs.update(person)
  }

  /**
   * Logs a search in the audit
   *@private
   * @function
   * @returns {void}
   */
  private async log(queryParams: QueryParams) {
    try {
      const repo = new AuditRepository()
      await repo.logSearch(queryParams)
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Initiates the retrieval of look-up data to display in the UI from the API
   *@private
   * @function
   * @returns {void}
   */
  private async lookup() {
    if (this.authState.user.isInRole(RoleType.ADMIN)) {
      this.organisations = await this.lookupState.getOrganisations()

      const stateNames = _.uniq(this.organisations?.map(o => o.state)).sort((a, b) => a.localeCompare(b))
      stateNames.forEach((name, idx) => {
        this.states.push({ id: idx, name })
      })
    }
    else {
      const repo = new TerminalLocationRepository()
      const params = new QueryParams()
      params.noPagination()

      const locations = await repo.getTerminalLocations(params)
      this.terminalLocations = locations.result
    }
  }
}
