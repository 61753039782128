export enum ViewStatus {
  NONE,
  IN_PROGRESS,
  UPDATING, 
  SORTING,
  DELETING,
  MERGING,
  SAVING,
  SUBMITTING,
  VERIFYING,
  SUCCEEDED,
  FAILED
}
