import { ApiRepository } from './apiRepository'
import { ApiResponse } from '../api/apiResponse'
import { Communication, ICommunication } from '../models/communication'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'

export class CommunicationRepository extends ApiRepository<Communication, ICommunication> {

  constructor(path = Paths.COMMUNICATIONS, type: new (i?: ICommunication) => Communication = Communication) {
    super(path, type)
  }

  public async getCommunications(id: number, queryParams: QueryParams) {
    if (queryParams.patronIds == null || queryParams.patronIds.length == 0) {
      return new ApiResponse<Communication, ICommunication>(null, Communication);
    }

    const url = this.urlPath(Paths.PERSONS, id, [this.path])
    return await this.getResponse(url, queryParams)
  }
}
