import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { EventLog, IEventLog } from '@/ts/models/eventLog'
import { QueryParams } from '@/ts/api/queryParams'
import { EventLogRepository } from '@/ts/repositories/eventLogRepository'
import { IPatron, Patron } from '@/ts/models/patron'
import { Person } from '../../../models/person'

/**
 * Local session storage of Event Logs of searched Persons
 * @module
 */
@Module({ name: 'EventLogsModule', namespaced: true })
export default class EventLogsModule extends VuexModule {

  /**
   * The total number of Events that have been found
   * @property
   * @returns {number}
   */
  public count = 0

  /**
   * The list of Event Logs
   * @property
  * @returns {EventLog[]} The list of selected Event Logs
  */
  public iEventLogs = new Array<IEventLog>()

  /**
  * The Person's Patrons
  * @property
  * @returns {Patron[]}
  */
  public iPatrons = new Array<IPatron>()


  /**
  * The current Query Parameters used for the search which are populated from
  * form fields within the UI.
  * @property
  * @see {@link QueryParams}
  * @returns {QueryParams}
  */
  public queryParams = new QueryParams()

  /**
  * Whether to show all Event Logs or just selected Event Logs
  * @property
  * @returns {boolean}
  */
  public showAll = true

  /**
  * The current index of the Event Log carousel slide.
  * @property
  * @returns {number}
  */
  public slideIndex = 0


  /**
  * Resets all Event Logs and Patrons in storage
  * @function
  * @returns {void}
  */
  @Mutation
  public clearResults() {
    this.count = 0
    this.iEventLogs = []
    this.iPatrons = []
  }

  /**
   * Resets the entire storage
   * @function
   * @returns {void}
  */
  @Mutation
  public clear() {
    this.count = 0
    this.iEventLogs = []
    this.iPatrons = []
    this.queryParams = new QueryParams()
    this.showAll = true
    this.slideIndex = 0
  }


  /**
   * Stores Event Logs
   * @function
   * @returns {void}
   */
  @Mutation
  public add(events: EventLog[]) {
    this.iEventLogs.push(...events)
  }

  /**
   * Stores Patrons
   * @function
   * @returns {void}
   */
  @Mutation
  public addPatrons(patrons: Patron[]) {
    this.iPatrons.push(...patrons)
  }

  /**
   * Removes Patrons from storage
   * @function
   * @returns {void}
   */
  @Mutation
  public removePatrons(patrons: Patron[]) {
    const patronIds = patrons.map(p => p.id)
    this.iPatrons = this.iPatrons.filter(i => !patronIds.includes(i.id))
  }

  /**
   * Stores Event Logs by replacing all stored Event Logs
   * @function
   * @returns {void}
   */
  @Mutation
  public set(events: EventLog[]) {
    this.iEventLogs = events
  }

  /**
   * Stores the total count of Event Logs whoich match the criteria
   * This may be higher than the number if Event Logs in storage
   * @function
   * @returns {void}
   */
  @Mutation
  public setCount(count: number) {
    this.count = count
  }

  /**
   * Stores a copy of the current Query Params
   * @function
   * @returns {void}
   */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
   * Stores whether to show all or just selected Persons
   * @function
   * @returns {void}
   */
  @Mutation
  public setShowAll(show: boolean) {
    this.showAll = show
  }

  /**
   * Stores the current index of the Events carousel slide.
   * @property
   * @returns {number}
   */
  @Mutation
  public setSlideIndex(index: number) {
    this.slideIndex = index
  }

  /**
   * Updates the current person's details
   * @function  
  * @returns {void}
  */
  @Mutation
  public update(person: Person) {
    const index = this.iEventLogs.findIndex(e => e.patron?.person?.id ?? 0 == person.id)
    if (index > -1) {
      const iEvent = new EventLog(this.iEventLogs[index])
      const iPerson = new Person(this.iEventLogs[index].patron!.person!)
      iEvent.patron!.person = iPerson.update(person)

      this.iEventLogs[index] = iEvent
    }
  }

  /**
   * Retrieves a list of Event Logs from the API filtered and sorted by the
   * Query Params that have been populated from the UI.
   * @function
   */
  @Action
  public async getEventLogs(queryParams: QueryParams) {
    const repo = new EventLogRepository()
    const response = await repo.getEventLogs(queryParams)

    this.context.commit('setCount', response.totalCount)
    this.context.commit((queryParams.currentPage == 1) ? 'set' : 'add', response.result)
  }
}
