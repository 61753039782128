import { Store } from 'vuex'
import { AuditRepository } from '@/ts/repositories/auditRepository'
import { DateFormatter, DateStyle } from '@/ts/formatters/dateFormatter'
import { AuthState } from '@/ts/states/auth/authState'
import { Ban } from '@/ts/models/ban'
import { BanEditor } from '@/ts/editors/banEditor'
import { BanRepository } from '@/ts/repositories/banRepository'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Pagination } from '@/ts/models/pagination'
import { Person } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ISortContext } from '@/ts/interfaces/sortContext'
import { ViewStatus } from '@/ts/enums/viewStatus'

/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class BansState {

  /**
  * The Auth State for retrieving the current logged in user
  * @property
  * @see {@link AuthState}
  * @returns {AuthState}
  */
  public auth: AuthState

  /**
   * A Ban Editor that is used to store the original values of the editor along with the
   * changed/new values for editing purposes
   * @property
   * @see {@link BanEditor}
   * @returns {BanEditor}
   */
  public ban: BanEditor

  /**
   * A callback function which should be fired when the list of Bans has changed
   * @property
   * @returns {void}
   */
  public bansChanged: ((bans: Ban[]) => void) | undefined = undefined

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
* Whether to show the organisation for entries
* @property
* @returns {boolean}
*/
  public showOrganisation = false

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
  * A back-end store for the current Bans, those Bans which have not expired.
  * @private
  * @property
  * @see {@link Ban}
  * @returns {Ban[]}
  */
  private currentBans = new Array<Ban>()

  /**
   * The LookupState for retrieving and displaying pre-defined
   * values in the UI
  * @private
   * @property
   * @returns {LookupState}
   */
  private lookupState: LookupState

  /**
   * The pagination used to manage the list of Bans in the UI
   * and swapping between pages of results.
   * @private
   * @property
   * @see {@link Pagination}
   * @returns {Pagination}
   */
  private pagination = new Pagination<Ban>()

  /**
   * The selected Person the current Bans belong to
   * @private
   * @property
   * @see {@link Person}
   * @returns {Person}
   */
  private person?: Person

  /**
   * The private repository used to retrieve data from the API
   * @private
   * @property
   * @see {@link BanRepository}
   * @returns {BanRepository}
   */
  private repo = new BanRepository()


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   * @param {Person} person - the Person who's state is being managed
   */
  constructor(store: Store<any>, person?: Person) {
    this.auth = new AuthState(store)
    this.lookupState = new LookupState(store)
    this.queryParams.pageSize = 10
    this.queryParams.sortBy ='fromDate'
    this.queryParams.sortDesc = true

    this.ban = new BanEditor(new Ban(), store, this.lookupState.banSuggestions)

    if (person != null) {
      this.person = person
      this.fetch()
      this.fetchCurrent()
    }
  }


  /**
   * The list of Bans for the current page
   * @property
   * @returns {Ban[]} The list of Bans for the current page
   */
  public get bans() {
    return this.pagination.page()
  }

  /**
   * The total number of Bans for this Person/Patron
   * @property
   * @returns {number}
   */
  public get count() {
    return this.pagination.totalCount
  }

  /**
  * The current Bans for this Person/Patron, i.e, those which should be
  * displayed on a Terminal Station.
  * @property
  * @see {@link Ban}
  * @returns {Ban[]}
  */
  public get current() {
    return this.currentBans
  }

  /**
   * Whether this Person/Patron has any current Bans.
   * @private
   * @returns {Ban[]}
   */
  public get hasCurrent() {
    return this.currentBans.length > 0
  }

  /**
   * Whether there are more Bans that can be retrieved or
   * they have all been retrieved already.
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return !this.pagination.hasAllPages
  }

  /**
  * Whether the current Person has more than one Patron account.
  * This will only be true when the NightKey user is an Admin who is not tied
  * to an Organisation and can therefore see multiple Patrons.
  * @property
  * @returns {boolean}
  */
  public get hasPatrons() {
    return this.person != null && this.person.patrons.length > 1
 }

  /**
   * Whether the current State has retrieved any Bans to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.count > 0
  }

  /**
   * Whether the current Person/Patron is currently banned.
   * @property
   * @returns {boolean}
   */
  public get isBanned() {
    return this.hasCurrent
  }

  /**
   * Whether the current Ban has been edited
   * @property
   * @returns {boolean}
   */
  public get isDirty() {
    return this.ban.isDirty
  }

  /**
   * Whether the current status is Loading
   * @property
   * @returns {boolean}
   */
  public get isLoading() {
    return this.status == ViewStatus.IN_PROGRESS
  }

  /**
   * The patrons for this Person
   * @property
   * @returns {Patron[]}
  */
  public get patrons() {
    return this.person != null ? this.person.patrons : []
  }

  /**
 * A key/value map to display Bans in a table
 * @function
 * @returns key/value pairs
 */
  public get tableFields() {
    const fields = [
      {
        class: 'wpx-50',
        key: 'isExpired',
        label: '',
        sortable: false
      },
      {
        class: 'font-weight-bold',
        formatter: (date: Date) => {
          return (date == null) ? '' : DateFormatter.format(date, DateStyle.Long)
        },
        key: 'fromDate',
        label: 'Banned date',
        sortable: true,
      },
      {
        class: 'font-weight-bold',
        key: 'toDate',
        label: 'Banned expiry',
        sortable: true,
      },
      {
        class: 'w-25',
        key: 'reason',
        label: 'Terminal ban comment',
        sortable: false
      },
      {
        class: 'w-25',
        key: 'internalReason',
        label: 'Internal ban notes',
        sortable: false
      },
      {
        key: 'createdBy',
        label: 'Banned by',
        sortable: true
      },
      {
        key: 'patron.organisation.name',
        label: 'Organisation',
        sortable: true
      },
      {
        class: 'text-center wpx-50',
        key: 'id',
        label: '',
        sortable: false
      }
    ]

    if (!this.showOrganisation) {
      fields.splice(fields.length - 2, 1)
    }

    return fields
  }

  /**
   * The total number of pages of resutls
   * @property
   * @returns {number}
   */
  public get totalPages() {
    return this.pagination.totalPages
  }

 
  /**
   * Clears the Bans currently retrieved and resets pagination
   * @function
   * @returns {void}
   */
  public clear() {
    this.currentBans = []
    this.pagination.reset()
    this.reset()
    this.status = ViewStatus.NONE
  }

  /**
  * Retrieves a list of Bans from the API.
  * If the current page is the first page, pagination will be reset to start again
  * otherwise the status is updating
  * @function
  * @returns {Ban[]} The list of Terminal Locations
  */
  public async fetch() {
    this.status = (this.queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getBans()
  }

  /**
   * Retrieves a list of current Bans from the API.
   * @function
   * @returns {Ban[]} The list of Terminal Locations
   */
  public async fetchCurrent() {
    try {
      this.queryParams.patronIds = this.person?.patrons.map(p => p.id)
      this.currentBans = await this.repo.getCurrentBans(this.person!.id, this.queryParams)
      return this.current
    }
    catch (error) {
      console.log(error)
    }

    return null
  }

  /**
   * Retrieves a list of Bans for the specified page.
   * If the current pages results have already been retrieved from the API it will
   * return them from memory, otherwise the results will be retrieved from the API
   * @function
   * @param {number} page - The page number
   * @returns {TerminalLocation[]} The list of Terminal Locations
   */
  public async paginate(page: number) {
    try {
      this.pagination.currentPage = page
      this.queryParams.currentPage = page

      if (this.pagination.hasPage(page)) {
        return this.bans
      }

      this.status = ViewStatus.UPDATING
      return await this.getBans()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Resets the editor and undoes all changes made
   * @function
   * @returns {void}
   */
  public reset() {
    this.ban.reset()
    this.status = ViewStatus.NONE

    if (this.person?.patrons.length == 1) {
      this.ban.patrons = this.person.patrons
    }
  }

  /**
   * Saves the edited Bans and updates the existing records
   * @function
   * @returns {void}
   */
  public async save() {
    try {
      this.status = ViewStatus.SAVING

      if (this.ban.edit.createdBy == null) {
        this.ban.edit.createdBy = this.auth.user.userName
      }

      await this.ban.save()
      this.update()
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Sets the current Ban for the state and assigns the Patron
   * @function
   * @returns {void}
   */
  public setBan(ban: Ban) {

    this.log(ban)
    this.ban.set(ban)

    if (ban.patron?.id > 0) {
      this.ban.patrons = this.person!.patrons.filter(p => p.id == ban.patron.id)
    }
    else if (this.person!.patrons.length == 1) {
      this.ban.patrons = this.person!.patrons
    }

  }

  /**
   * Retrieves a fresh list of Bans in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {TerminalComment[]} The list of sorted Bans
   */
  public async sort(context: ISortContext) {
    try {
      this.status = ViewStatus.SORTING
      this.queryParams.sortedBy(context)

      return await this.getBans()
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Retrieves a list of Bans from the API using the current state
   * Query Params that have been populated from the UI.  Once retrieved, the pagination
   * for the UI will be populated and the current page of results will be returned.
   * @private
   * @property
   * @returns {Ban[]} The list of Bans for the current page
   */
  private async getBans() {
    try {
      this.queryParams.patronIds = this.person!.patrons.map(p => p.id)
      const response = await this.repo.getBans(this.person!.id, this.queryParams)

      if (this.status == ViewStatus.SORTING) {
        this.pagination.reset(true)
      }

      this.pagination.set(this.queryParams.currentPage, response.result)
      this.pagination.totalPages = response.totalPages
      this.pagination.totalCount = response.totalCount
      this.status = ViewStatus.SUCCEEDED

      return this.bans
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Logs a search in the audit
   *@private
   * @function
   * @returns {void}
   */
  private async log(ban: Ban) {
    try {
      const repo = new AuditRepository()
      await repo.logBanViewed(ban)
    }
    catch (error) {
      console.log(error)
    }
  }


  /**
   * Refreshes the current screen by retrieving the latest set of Bans and
   * current Bans.
   * @property
   * @returns {void}
   */
  private async update() {
    this.fetch()
    await this.fetchCurrent()

    if (this.bansChanged != null) {
      this.bansChanged(this.current)
    }
  }
}

