import { DateFormatter } from '../formatters/dateFormatter'
import { IQueryParams } from './queryParams'

/**
 * A querystring representation of QueryParams for API GET requests
 * @interface
 * @see {@link QueryParam}
 */
export interface IQueryString {
  af?: number
  at?: number
  atid?: number
  bsid?: number[]
  co?: string
  pg: number
  df?: string
  dt?: string
  etid?: number[]
  exb: boolean
  hp: boolean
  gid?: number[]
  gn?: string
  incl: boolean
  nk?: number
  pid?: number[]
  poi?: string
  poids?: number[]
  pnid?: number
  prid?: number
  ps: number
  psid?: number[]
  ph?: string
  pc?: string
  q?: string
  id?: number[]
  sb: string
  sd: boolean
  stid?: string[]
  su?: string
  sn?: string
  tlid?: number[]
  vid?: number[]
  vt?: number
}

/**
 * A querystring representation of QueryParams for API GET requests
 * @class
 * @see {@link QueryParam}
 */
export class QueryString implements IQueryString {

  public af?: number
  public at?: number
  public atid?: number
  public bsid?: number[]
  public co?: string
  public pg: number
  public df?: string
  public dt?: string
  public etid?: number[]
  public exb: boolean
  public gid?: number[]
  public gn?: string
  public hp: boolean
  public nk?: number
  public pid?: number[]
  public poi?: string
  public poids?: number[]
  public pnid?: number
  public prid?: number
  public ps: number
  public psid?: number[]
  public ph?: string
  public pc?: string
  public q?: string
  public id?: number[]
  public incl: boolean
  public sb: string
  public sd: boolean
  public stid?: string[]
  public su?: string
  public sn?: string
  public tlid?: number[]
  public vid?: number[]
  public vt?: number

  /**
   * A querystring representation of QueryParams for API GET requests
   * @constructor
   * @param { IQueryParams } queryParams
   */
  constructor(queryParams?: IQueryParams) {
    this.af = queryParams?.ageFrom
    this.at = queryParams?.ageTo
    this.atid = queryParams?.auditType
    this.bsid = queryParams?.banStatuses ? [queryParams.banStatuses.id] : []
    this.co = queryParams?.company
    this.df = DateFormatter.join(queryParams?.dateFrom, queryParams?.timeFrom)
    this.dt = DateFormatter.join(queryParams?.dateTo, queryParams?.timeTo)
    this.etid = queryParams?.eventTypes?.map(e => e.id)
    this.exb = queryParams?.excludeBanned ?? false
    this.gid = queryParams?.gender?.map(g => g.id)
    this.gn = queryParams?.givenNames
    this.hp = queryParams?.hasPhone ?? false
    this.incl = queryParams?.includeInactive ?? true
    this.nk = queryParams?.nkReference
    this.pg = queryParams?.currentPage ?? 1
    this.pid = queryParams?.patronIds
    this.poi = queryParams?.proofOfId != null ? queryParams.proofOfId : undefined
    this.poids = queryParams?.proofOfIds?.map(p => p.id)
    this.pnid = queryParams?.personId
    this.prid = queryParams?.policeReportStatus
    this.ps = queryParams?.pageSize ?? 50
    this.psid = queryParams?.personStatuses?.map(p => p.id)
    this.ph = queryParams?.phoneNumber
    this.pc = queryParams?.postcode
    this.q = queryParams?.searchText
    this.id = queryParams?.selectedIds?.map(id => id)
    this.sb = queryParams?.sortBy ?? ''
    this.sd = queryParams?.sortDesc ?? false
    this.stid = queryParams?.states?.map(s => s.name)
    this.su = queryParams?.suburb
    this.sn = queryParams?.surname
    this.tlid = queryParams?.terminalLocations?.map(t => t.id)
    this.vid = queryParams?.venues?.map(v => v.id)
    this.vt = queryParams?.verificationType

    if (this.pg > 0 && this.ps > 0) {
      const start = (this.pg - 1) * this.ps
      this.id = queryParams?.selectedIds?.slice(start, start + this.ps)
    }
  }
}
