import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Media } from './media'
import { Person } from './person'
import { Organisation } from './organisation'

export interface IPatron extends IJsonIdentity {
  isBanned: boolean
  readonly isTagged: boolean
  latestPhoto: Media
  membershipCode?: string
  modifiedBy?: number
  modifiedDate: Date
  organisation: Organisation
  person?: Person
  shouldRescan: boolean
  taggedDate?: Date
  taggedComment?: string

  copy(): IPatron
}

export class Patron implements IPatron {

  public id: number
  public isBanned: boolean
  public latestPhoto: Media
  public membershipCode?: string
  public modifiedBy?: number
  public modifiedDate: Date
  public organisation: Organisation
  public person?: Person
  public shouldRescan: boolean
  public taggedComment?: string
  public taggedDate?: Date


  public get isTagged() {
    return this.taggedDate != null
  }

  constructor(patron?: IPatron) {
    this.id = patron?.id ?? 0
    this.isBanned = patron?.isBanned ?? false
    this.latestPhoto = new Media(patron?.latestPhoto)
    this.membershipCode = patron?.membershipCode
    this.modifiedBy = patron?.modifiedBy
    this.modifiedDate = DateFormatter.parse(patron?.modifiedDate, new Date())
    this.organisation = new Organisation(patron?.organisation)
    this.person = (patron?.person) ? new Person(patron?.person) : undefined
    this.shouldRescan = patron?.shouldRescan ?? false
    this.taggedDate = DateFormatter.parse(patron?.taggedDate)
    this.taggedComment = patron?.taggedComment
  }

  public copy(): Patron {
    return new Patron(this)
  }
}

