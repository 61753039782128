import { Store } from 'vuex'

import { ISortContext } from '@/ts/interfaces/sortContext'
import { QueryParams } from '@/ts/api/queryParams'
import { Person } from '@/ts/models/person'
import { ViewStatus } from '@/ts/enums/viewStatus'

import { getModule } from 'vuex-module-decorators'

import SelectedPersonsModule from '@/ts/store/search/children/selectedPersonsModule'

import _ from 'lodash'

export class SelectedPersonsState {

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link OrganisationsModule}
   * @returns {OrganisationsModule}
   */
  private module: SelectedPersonsModule
  

  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(SelectedPersonsModule, store)
    this.setStatus()
  }


  /**
   * The total number of People that have been selected
   * @property
   * @returns {number}
   */
  public get count() {
    return this.module.count
  }

  /**
   * Whether there are more Persons that can be retrieved or
   * they have all been retrieved already
   * @property
   * @returns {boolean}
   */
  public get hasMoreResults() {
    return this.module.iPersons.length < this.count
  }

  /**
   * Whether there are more than one Persons selected
   * @property
   * @returns {boolean}
   */
  public get hasMultipleSelection() {
    return this.module.iPersons.length > 1
  }

  /**
   * Whether the current state has retrieved any Persons to
   * indicate whether a search has already been peformed and the page already
   * has results loaded.
   * @property
   * @returns {boolean}
   */
  public get hasState() {
    return this.module.iPersons.length > 0
  }

  /**
   * The Ids of the People who have been selected
   * @property
   * @returns {number[]}
   */
  public get ids() {
    return this.module.selectedIds
  }

  public set ids(value: number[]) {
    this.module.setSelectedIds(value)
    this.getPersons(this.queryParams)
  }

  /**
   * Whether the select all option has been selected
   * @property
   * @returns {boolean}
   */
  public get isAllSelected() {
    return this.module.isAllSelected
  }

  /**
  * The total Persons who have been selected that are currently in the Store.
  * This might be less than the selected amount if not all records have been retrieved yet.
  * @property
  * @returns {number}
  */

  public get length() {
    return this.module.iPersons.length
  }

  /**
   * The list of Persons who have been selected
   * @property
   * @returns {Person[]} The list of Persons who have been selected
   */
  public get people() {
    return this.module.iPersons.map(i => new Person(i))
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }

  /**
   * The current index of the Person carousel slide.
   * @property
   * @returns {number}
   */
  public get slideIndex() {
    return this.module.slideIndex
  }

  public set slideIndex(value: number) {
    this.module.setSlideIndex(value)
  }


  /**
   * Clears the current state and resets all values
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
    this.status = ViewStatus.NONE
  }

  /**
   * Deselects a Person
   * @function
   * @returns {void}
   */
  public deselect(person: Person) {
    this.module.deselect(person)
    this.setStatus()
  }

  /**
   * Deselects all People
   * @function
   * @returns {void}
   */
  public deselectAll() {
    if (this.isAllSelected) {
      this.module.deselectAll()
      this.setStatus()
    }
  }

  /**
   * Retrieves a list of People from the Store.
   * @function
   * @returns {Person[]} The list of People
   */
  public async fetch(queryParams: QueryParams) {
    this.status = (queryParams.currentPage == 1) ? ViewStatus.IN_PROGRESS : ViewStatus.UPDATING
    return await this.getPersons(queryParams)
  }

  /**
   * The index of a selected Person
   * @function
   * @param {number} index - The index of the Person
   * @returns {number}
   */
  public idAtIndex(index: number) {
    return this.module.selectedIds[index]
  }

  /**
   * The index of a Person based on their Id
   * @function
   * @param {number} id - The Person Id
   * @returns {number}
   */
  public indexOf(id: number) {
    return this.module.selectedIds.indexOf(id)
  }

  /**
   * Whether a Person is selected
   * @function
   * @param {Person} person - The Person 
   * @returns {boolean}
   */
  public isSelected(person: Person | undefined) {
    return person != null && this.module.selectedIds.includes(person.id)
  }

  /**
 * Retrieves a Person by index
 * @function
 * @returns {number} index
 */
  public personAtIndex(index: number) {
    return this.people[index]
  }

  /**
   * Selects a Person
   * @function
   * @param {Person} person - The Person to select
   * @returns {void}
   */
  public select(person: Person) {
    if (!this.isSelected(person)) {
      this.module.select(person)
      this.setStatus()
    }
  }

  /**
   * Selects all People
   * @function
   * @returns {void}
   */
  public selectAll() {
    try {
      this.module.selectAll(this.queryParams)
    }
    catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a fresh list of People in the new specified order
   * @function
   * @param {ISortContext} context - The property to sort by and the sort order
   * @return {Person[]} The list of sorted People
   */
  public async sort(context: ISortContext) {
    this.status = ViewStatus.SORTING
    const queryParams = this.queryParams.sortedBy(context)
    return await this.getPersons(queryParams)
  }

  /**
   * Updates the current Person with a new Person,
   * or an updated version of the existing Person
   * @private
   * @function
   * @param {Person} person - The updated Person
   * @returns {void}
   */
  public update(person: Person) {
    this.module.update(person)
  }

  /**
   * Retrieves a list of Person from the Store using the current state
   * Query Params that have been populated from the UI.
   * @private
   * @function
   * @returns {Person[]} The list of Bans for the current page
   */
  private async getPersons(queryParams: QueryParams) {
    try {
      this.queryParams = queryParams

      await this.module.getPersons(queryParams)
      this.status = ViewStatus.SUCCEEDED

      return this.people
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }

    return []
  }

  /**
   * Sets the current status based on whether People have already been
   * retrieved or not
   * private
   * @function
   * @return {void}
   */
  private setStatus() {
    this.status = (this.module.iPersons.length > 0) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }
}
