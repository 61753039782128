import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { EventLog } from '@/ts/models/eventLog'
import { EventLogRepository } from '@/ts/repositories/eventLogRepository'
import { Person, IPerson } from '@/ts/models/person'
import { QueryParams } from '@/ts/api/queryParams'
import { ToolboxRepository } from '@/ts/repositories/toolboxRepository'
import { Verification, IVerification } from '@/ts/models/verification'
import { VerificationType } from '@/ts/enums/verificationType'


/**
 * Local session storage of the Toolbox
 * @module
 */
@Module({ name: 'VerifyModule', namespaced: true })
export default class VerifyModule extends VuexModule {

  /**
  * The total number of verifications
  * @property
  * @returns {number}
  */
  public count = 0

  /**
  * The list of verifications retrieved from the Store that need processing
  * @property
  * @see {@link Verification}
  * @returns {Verification}
  */
  public iVerifications = new Array<IVerification>()

  /**
  * The number of processed verifications
  * @property
  * @returns {number}
  */
  public progress = 0

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.count = 0
    this.iVerifications = []
    this.progress = 0

    const queryParams = new QueryParams()
    queryParams.verificationType = VerificationType.VERIFY
    this.queryParams = queryParams
  }


  /**
  * Resets all verifications in storage
  * @function
  * @returns {void}
  */
  @Mutation
  public clearResults() {
    this.iVerifications = []
    this.progress = 0    
  }


  /**
   * Stores the current Verification being processed.
   * @function
   * @returns {void}
   */
  @Mutation
  public add(verifications: Verification[]) {
    this.iVerifications.push(...verifications)
  }

  /**
   * Stores the total number of verifications processed.
   * @function
   * @returns {void}
   */
  @Mutation
  public setCount(count: number) {
    this.count = count
  }

  /**
   * Stores the Event Logs found which for the current Person.
   * @function
   * @returns {void}
  */
  @Mutation
  public setEvents(events: EventLog[]) {
    this.iVerifications[0].originalEventLogs = events
  }

  /**
   * Stores a copy of the Query Params
   * @function
   * @returns {void}
   */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
   * Swaps the verification mode at the current index and if set to "Merge" adds the Person
   * @function
   * @param {Person}
   * @returns {void}
   */
  @Mutation
  public swap(person: Person | undefined) {
    const verification = new Verification(this.iVerifications[0])
    verification.convert(person)
    this.iVerifications[0] = verification
  }
 

  /**
   * Updates and stores the current progress
   * @function
   * @param {Person}
   * @returns {void}
   */
  @Mutation
  public updateProgress() {
    if (this.progress < this.count) {
      this.progress += 1
      this.iVerifications.splice(0, 1)
    }
  }

  /**
   * Retrieves a list of events for the current Person and stores them
   * @function
   * @returns {void}
   */
  @Action
  public async getEvents() {
    const person = this.iVerifications[0].original

    const queryParams = new QueryParams()
    queryParams.pageSize = 3
    queryParams.patronIds = person.patrons.map(p => p.id)

    const repo = new EventLogRepository()
    const response = await repo.getEvents(person.id, queryParams)
    this.context.commit('setEvents', response.result)
  }

  /**
   * Retrieves a list of verifications found by the API and stores them
   * @function
   * @param {QueryParams}
   * @returns {void}
   */
  @Action
  public async getVerifications(queryParams: QueryParams) {
    const repo = new ToolboxRepository()
    const verifications = await repo.getVerifications(queryParams)
    
    this.context.commit('add', verifications.result)

    if (verifications.currentPage == 1) {
      this.context.commit('setCount', verifications.totalCount)
    }
  }
}
