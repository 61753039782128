import { Module, VuexModule, MutationAction, Mutation } from 'vuex-module-decorators'

import { AccessToken } from '@/ts/models/accessToken'
import { AuthRepository } from '@/ts/repositories/authRepository'
import { User } from '@/ts/models/user'
import { UserRole } from '@/ts/models/userRole'

import axios from 'axios'

/**
 * Local session storage for Authentication
 * @module
 */
@Module({ name: 'AuthModule', namespaced: true })
export default class AuthModule extends VuexModule {

  /**
    * The JWT token retrieved after a successful login to NightKey.
    * This token should be used as the Bearer token in all subsequent API requests.
    * @property
    * @see {@link AccessToken}
    * @returns {AccessToken}
    */
  public iAccessToken = new AccessToken()


  /**
   * The currently logged in NightKey User
   * @property
   * @see {@link User}
   * @returns {User}
   */
  public iUser = new User()

  /**
  * Whether the NightKey application user has successfully logged in.
  * @property
  * @returns {boolean}
  */
  public isAuthenticated = false

  /**
  * Whether the NightKey application user has validated their identity
  * using 2FA authentication via a code.
  * @property
  * @returns {boolean}
  */
  public isValidated = false

  /**
   * The username entered to login.
   * @property
   * @returns {string}
   */
  public username = ''


  /**
   * Logout the current user, clearing the Access Token, User and
   * all other details.
   * @function
   * @returns {void}
   */
  @Mutation
  public logout() {
    this.iAccessToken = new AccessToken()
    this.iUser = new User()
    this.isAuthenticated = false
    this.isValidated = false
    this.username = ''

    const repo = new AuthRepository()
    repo.logout()
 }

  @Mutation
  public setAuthenticated(authenticated: boolean) {
    this.isAuthenticated = authenticated
  }

  /**
   * Stores the username entered to login.
   * @property
   *  * @returns {string}
   */
  @Mutation
  public setUsername(username: string) {
    this.username = username
  }

  @Mutation
  public setValidated(validated: boolean) {
    this.isValidated = validated
  }


  /** ACTIONS */

  /**
   * Login a user with username and password and sets the Bearer Token for future requests
   * @function
   * @returns {AccessToken}
   */
  @MutationAction
  public async login(params: { username: string; password: string }) {
    const repo = new AuthRepository()
    const iAccessToken = await repo.login(params.username, params.password)
    axios.defaults.headers.common.Authorization = `Bearer ${iAccessToken?.token}`

    const iUser = await repo.user()
    return { iAccessToken, iUser }
  }

  /**
   * Login a user with username and accesscode and sets the Bearer Token for future requests
   * @function
   * @returns {AccessToken}
   */
  @MutationAction
  public async loginCode(params: { username: string; accessCode: number }) {
    const repo = new AuthRepository()
    const iAccessToken = await repo.loginCode(params.username, params.accessCode)
    axios.defaults.headers.common.Authorization = `Bearer ${iAccessToken?.token}`

    const iUser = await repo.user()
    return { iAccessToken, iUser }
  }
}
