import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IEventLogCode extends IJsonIdentity {
  abbr: string
  category: string
  code: string

  copy(): IEventLogCode
}

export class EventLogCode implements IEventLogCode {
  public id: number
  public abbr: string
  public category: string
  public code: string

  constructor(eventLogCode?: IEventLogCode) {
    this.id = eventLogCode?.id ?? 0
    this.abbr = eventLogCode?.abbr ?? ''
    this.category = eventLogCode?.category ?? ''
    this.code = eventLogCode?.code ?? ''
  }

  public copy(): EventLogCode {
    return new EventLogCode(this)
  }
}
