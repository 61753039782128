import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { EventLog } from '@/ts/models/eventLog'
import { ViewStatus } from '@/ts/enums/viewStatus'

import SelectedEventsModule from '@/ts/store/person/children/selectedEventsModule'


/**
 * State management of Terminal Location searches and UI
 * @class
 */
export class SelectedEventsState {

  /**
   * The default number of slides to show in the Events carousel
   * @property
   * @returns {number}
   */
  public slidesToShow = 10


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link PersonModule}
   * @returns {PersonModule}
   */
  private module: SelectedEventsModule


  /**
   * Instantiates a new State object pointing to the Vuex local session storage and instantiates
   * all children states.  If the person is not null, all children states will be re-populated with
   * results for that Person.
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>) {
    this.module = getModule(SelectedEventsModule, store)
  }


  /**
   * The list of selected Event Logs
   * @property
   * @returns {EventLog[]} The list of selected Event Logs
   */
  public get events() {
    return this.module.iEventLogs.map(i => new EventLog(i))
  }

  /**
   * The total number of Event Logs selected
   * @property
   * @returns {number}
   */
  public get count() {
    return this.module.count
  }

  /**
  * Whether the current State has any Event Logs in the Store
  * @property
  * @returns {boolean}
  */
  public get hasState() {
    return this.module.iEventLogs.length > 0
  }

  /**
   * The list of selected EventLog Ids
   * @property
   * @returns {number[]}
   */
  public get ids() {
    return this.module.selectedIds
  }

  /**
  * The total Event Logs that have currently been retrieved from the Store.
  * This might be less than the selected amount if not all records have been retrieved yet.
  * @property
  * @returns {number}
  */
  public get length() {
    return this.module.iEventLogs.length
  }

  /**
  * The current index of the Events carousel slide.
  * @property
  * @returns {number}
  */
  public get slideIndex() {
    return this.module.slideIndex
  }

  public set slideIndex(value: number) {
    this.module.setSlideIndex(value)
  }


  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public get status() {
    return this.module.status
  }

  public set status(status: ViewStatus) {
    this.module.setStatus(status)
  }

  /**
    * Clears the current state and resets all values
    * @function
    * @returns {void}
    */
  public clear() {
    this.module.clear()
  }

  /**
    * Clears the current selected Event Logs
    * @function
    * @returns {void}
    */
  public clearResults() {
    this.module.clearResults()
  }

  /**
   * Deselects an Event Log based on the ID and moves the carousel if necessary
   * @function
   * @returns {void}
   */
  public deselect(id: number) {
    if (this.ids.length > 1) {
      const selectedIndex = this.ids.indexOf(id)
      const moveToId = (selectedIndex == 0) ? this.ids[1] : this.ids[selectedIndex - 1]
      this.module.deselect(id)

      this.moveToSlide(moveToId)
    }
    else {
      this.module.deselect(id)
    }
  }

  /**
   * Whether an Event Log is selected based on Id
   * @function
   * @param {number} id - The Event Log Id
   * @returns {boolean}
   */
  public isSelected(id: number) {
    return this.ids.includes(id)
  }

  /**
   * Moves the carousel to the slide of the Event Log with the specified Id
   * @function
   * @param {number} id - The Event Log Id
   * @returns {void}
   */
  public moveToSlide(id: number) {
    let index = this.module.iEventLogs.findIndex(i => i.id == id)
    const halfway = Math.floor(this.slidesToShow / 2)

    index = (index > halfway) ? index - halfway : index
    this.slideIndex = index
  }

  /**
   * Selects an event and moves the carousel to that slide
   * @function
   * @param {number} id - The Event Log Id
   * @returns {void}
   */
  public async select(id: number) {
    try {
      this.status = ViewStatus.UPDATING
      await this.module.select(id)

      this.setStatus()
      this.moveToSlide(id)
    }
    catch (error) {
      this.status = ViewStatus.FAILED
    }
  }

  
  /**
   * Sets the current status based on whether Event Logs are in storage or not
   * @function
   * @return {void}
   */
  private setStatus() {
    this.status = (this.module.iEventLogs.length > 0) ? ViewStatus.SUCCEEDED : ViewStatus.NONE
  }

}

