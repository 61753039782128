import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { QueryParams } from '@/ts/api/queryParams'
import { Route } from '@/ts/models/route'
import { PoliceReportStatus } from '@/ts/enums/policeReportStatus'


/**
 * Local session storage of the Police Report
 * @module
 */
@Module({ name: 'PoliceReportModule', namespaced: true })
export default class PoliceReportModule extends VuexModule {

  /**
   * The current mode used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link PoliceReportStatus}
   * @returns {PoliceReportStatus}
   */
  public mode = PoliceReportStatus.REQUESTED

  /**
   * The current mode used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public queryParams = new QueryParams()

  /**
 * The Navigation Stack stored to assist a user in returning back to previous
 * screens.
 * @property
 * @returns {Route} The last route from the navigation stack
 */
  public returnRoutes = new Array<Route>()

  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.queryParams = new QueryParams()
  }

  /**
   * Stores a copy of the mode
   * @function
   * @returns {void}
   */
  @Mutation
  public setMode(mode: PoliceReportStatus) {
    this.mode = mode
  }

  /**
   * Stores a copy of the Query Params
   * @function
   * @returns {void}
   */
  @Mutation
  public setQueryParams(queryParams: QueryParams) {
    this.queryParams = queryParams.copy()
  }

  /**
 * Removes the last route from the navigation stack and returns it.
 * @function
 * @returns {Route} The last route from the navigation stack
 */
  @Mutation
  public popRoute() {
    return this.returnRoutes.pop()
  }

  /**
   * Adds a new route to the navigation stack.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  @Mutation
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }
}
