import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { Person } from '@/ts/models/person'
import { Route } from '@/ts/models/route'
import { Ban } from '@/ts/models/ban'

/**
 * Local session storage of a Person
 * @module
 */
@Module({ name: 'PersonModule', namespaced: true })
export default class PersonModule extends VuexModule {


  /**
   * The current Person.
   * @property
   * @returns {Person}
   */
  public iPerson = new Person()

  /**
   * The Navigation Stack stored to assist a user in returning back to previous
   * screens.
   * @property
   * @returns {Route} The last route from the navigation stack
   */
  public returnRoutes = new Array<Route>()

  /**
   * The Y scroll position of the current page
   * @property
   * @returns {number}
   */
  public scrollPosition = 0

  /**
  * The Tab Index selected for the carousel which appears in the UI to show events, duplicates etc...
  * @property
  * @returns {number}
  */
  public tabIndex = 0


  /**
   * Clears all routes from storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clearRoutes() {
    this.returnRoutes = []
  }

  /**
   * Removes the last route from the navigation stack and returns it.
   * @function
   * @returns {Route} The last route from the navigation stack
   */
  @Mutation
  public popRoute() {
    this.returnRoutes.pop()
  }

  /**
   * Adds a new route to the navigation stack.
   * @function
   * @param {Route} route - The route to add to the stack
   * @return {void}
   */
  @Mutation
  public pushRoute(route: Route) {
    this.returnRoutes.push(route)
  }

  /**
   * Stores the current Person being viewed.
   * @property
   * @returns {Person}
   */
  @Mutation
  public setPerson(person: Person) {
    this.iPerson = new Person(person)
  }

  /**
   * Stores the current scroll position
   * @function
   * @returns {void}
   */
  @Mutation
  public setScrollPosition(y: number) {
    this.scrollPosition = y
  }

  /**
   * Stores the current Tab Index for the displayed carousels
   * @function
   * @returns {void}
   */
  @Mutation
  public setTabIndex(index: number) {
    this.tabIndex = index
  }

  @Mutation
  public updateBans(bans: Ban[]) {
    for (const patron of this.iPerson.patrons) {
      patron.isBanned = bans.findIndex(b => b.patron.id == patron.id) > -1
    }
  }

  /**
   * Resets the entire storage
   * @function
   * @returns {void}
   */
  @Mutation
  public clear() {
    this.iPerson = new Person()
    this.returnRoutes = []
    this.tabIndex = 0
  }
}
