import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import { LookupState } from '@/ts/states/lookup/lookupState'
import { Organisation } from '@/ts/models/organisation'
import { Person } from '@/ts/models/person'
import { PersonState } from '@/ts/states/person/personState'
import { PersonStatusType } from '@/ts/enums/personStatusType'
import { QueryParams } from '@/ts/api/queryParams'
import { ToolboxRepository } from '@/ts/repositories/toolboxRepository'
import { Verification } from '@/ts/models/verification'
import { VerificationType } from '@/ts/enums/verificationType'
import { ViewStatus } from '@/ts/enums/viewStatus'

import VerifyModule from '@/ts/store/toolbox/children/verifyModule'

import _ from 'lodash'

export class VerifyState {

 /**
 * The LookupState for retrieving and displaying pre-defined
 * values in the UI
 * @property
 * @returns {LookupState}
 */
  public lookupState: LookupState

  /**
* The list of organisations which a Nightkey Admin user might want to filter by.
* This will not be populated when the Application User belongs to a single Organisation.
* @property
* @returns {Organisation[]}
*/
  public organisations?= new Array<Organisation>()

  /**
 * A custom list of States based on Organisations available
 * @property
 */
  public states = new Array<{ id: number; name: string }>()

  /**
* The current Verification
* @property
* @returns {Verification}
*/
  public verification = new Verification()


  /**
 * The current status
 * @property
 * @see {@link ViewStatus}
 * @returns {ViewStatus}
 */
  public status = ViewStatus.NONE


  /**
   * The Vuex module which stores data to a local session
   * @private
   * @property
   * @see {@link VerifyModule}
   * @returns {VerifyModule}
   */
  private module: VerifyModule


  /**
   * The LookupState for retrieving and displaying pre-defined
   * values in the UI
   * @property
   * @returns {LookupState}
  */
  private person: PersonState


  /** CONSTRUCTORS */

  /**
   * Instantiates a new State object pointing to the Vuex local session storage
   * and instantiates all child states
   * @constructor
   * @param {Store} store - the local Vuex Store
   */
  constructor(store: Store<any>, person: PersonState) {
    this.module = getModule(VerifyModule, store)
    this.lookupState = new LookupState(store)
    this.person = person

    const queryParams = new QueryParams()
    queryParams.verificationType = VerificationType.VERIFY
    this.queryParams = queryParams

    this.lookup()
  }

  /**
   * The total number of verifications in the Store which need processing
   * @property
   * @returns {boolean}
   */
  public get count() {
    return this.module.count
  }

   /**
   * Whether any Event Logs exist in the Verification record for the Original Person
   * @property
   * @returns {boolean}
   */
  public get hasEvents() {
    return (this.verification != null) ? this.verification.originalEventLogs.length > 0 : false
  }

  /**
    * Whether the current State has retrieved any Verifications of the type specified to
    * indicate whether a search has already been peformed and the page already
    * has results loaded.
    * @property
    * @returns {boolean}
    */
  public get hasState() {
    return this.module.iVerifications.length > 0
  }

  /**
   * The percentage of verifications which have been processed so far
   * @property
   * @returns {boolean}
   */
  public get progress() {
    return this.module.progress
  }

  /**
   * The current Query Parameters used for the search which are populated from
   * form fields within the UI.
   * @property
   * @see {@link QueryParams}
   * @returns {QueryParams}
   */
  public get queryParams() {
    return new QueryParams(this.module.queryParams)
  }

  public set queryParams(value: QueryParams) {
    this.module.setQueryParams(value)
  }

  /**
 * The list of verifications retrieved from the Store that need processing
 * @property
 * @see {@link Verification}
 * @returns {Verification}
 */
  public get verifications() {
    if (this.module.iVerifications.length > 0) {
      return this.module.iVerifications.map(v => new Verification(v))
    }

    return []
  }

  /**
   * Clears the entire state and resets current person
   * @function
   * @returns {void}
   */
  public clear() {
    this.module.clear()
  }

  /**
   * Clears all Verifications
   * @function
   * @returns {void}
   */
  public clearResults() {
    this.module.clearResults()
  }

  /**
 * Creates an ID Query on the current verification
 * @function
 * @returns {void}
 */
  public async createIdQuery() {
    this.status = ViewStatus.SUBMITTING
    const idQueryStatus = this.lookupState.personStatus(PersonStatusType.IDQUERY)!
    this.person.person.edit.personStatus = idQueryStatus
    this.person.showIdQuery = true
  }

  /**
   * Retrieves a list of Verifications from the Store using the current state
   * Query Params that have been populated from the UI. 
   * @function
   * @returns {void}
   */
  public async fetch(queryParams: QueryParams = this.queryParams) {
    try {
      this.status = ViewStatus.IN_PROGRESS
      this.queryParams = queryParams
      await this.module.getVerifications(queryParams)
      this.status = ViewStatus.SUCCEEDED
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Converts that Verification from a Merge to Verify, or Verify to Merge at the current index.
   * If converting from Verify to Merge, this typically means an existing person has been identified
   * as already existing so the Verification record is actually a Merge as it's a duplicate.
   * @function
   * @param {Person} person = An existing Person that has been identified matching and is thus, the original.
   * @returns {void}
   */
  public swap(person?: Person) {
    this.module.swap(person)
    this.person.setPerson(this.verification.original)
  }

  /**
   * Verifies the current Verification record as a Person, shifting the PersonStatus from New to Verified.
   * @function
   * @returns {void}
   */
  public async submit() {
    try {
      if (this.person.person.edit.validate()) {
        this.status = ViewStatus.SUBMITTING
        const repo = new ToolboxRepository()

        if (this.person.person.edit.is(PersonStatusType.NEW)) {
          this.person.person.edit.personStatus = this.lookupState.personStatus(PersonStatusType.VERIFIED)!
        }

        this.verification.original = this.person.person.edit
        this.verification.capitalize()

        await repo.verify(this.verification)

        this.status = ViewStatus.SUCCEEDED      }
      else {
        this.status = ViewStatus.FAILED
      }
    }
    catch (error) {
      console.log(error)
      this.status = ViewStatus.FAILED
    }
  }

  /**
   * Updates the progress of Verifications
   * @function
   * @returns {void}
   */
  public updateProgress() {
    this.module.updateProgress()
  }

  /**
  * Initiates the retrieval of look-up data to display in the UI from the API
  *@private
  * @function
  * @returns {void}
  */
  private async lookup() {
    this.organisations = await this.lookupState.getOrganisations()

    const stateNames = _.uniq(this.organisations?.map(o => o.state)).sort((a, b) => a.localeCompare(b))
    stateNames.forEach((name, idx) => {
      this.states.push({ id: idx, name })
    })
  }
}
