import { EventLogCodeType } from '../enums/eventLogCodeType'
import { DateFormatter, DateStyle } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { EventLogCode } from './eventLogCode'
import { Media } from './media'
import { Patron } from './patron'
import { TerminalLocation } from './terminalLocation'
import { TerminalStation } from './terminalStation'
import { User } from './user'

export interface IEventLog extends IJsonIdentity {
  codes: EventLogCode[]
  createdDate: Date
  readonly createdTime: string  
  readonly isReEntry: boolean
  operator?: User
  patron?: Patron
  photo?: Media
  terminalLocation?: TerminalLocation
  terminalStation?: TerminalStation
  timeFrameId: number
  username?: string

  copy(): IEventLog
}

export class EventLog implements IEventLog {

  public id: number
  public codes: EventLogCode[]
  public createdDate: Date
  public photo?: Media
  public operator?: User
  public patron?: Patron
  public terminalLocation?: TerminalLocation
  public terminalStation?: TerminalStation
  public timeFrameId: number
  public username?: string

  public get createdTime() {
    return DateFormatter.format(this.createdDate, DateStyle.Time).toLowerCase()
  }

  public get isReEntry() {
    return this.codes.findIndex(c => c.id == EventLogCodeType.REENTERED) > -1
  }

  constructor(eventLog?: IEventLog) {
    this.codes = eventLog ? eventLog.codes.map(c => new EventLogCode(c)) : []
    this.createdDate = DateFormatter.parse(eventLog?.createdDate, new Date())
    this.photo = new Media(eventLog?.photo)
    this.id = eventLog?.id ?? 0
    this.timeFrameId = eventLog?.timeFrameId ?? 0
    this.username = eventLog?.username

    if (eventLog?.operator != null) {
      this.operator = new User(eventLog?.operator)
    }

    if (eventLog?.patron != null) {
      this.patron = new Patron(eventLog?.patron)
    }

    if (eventLog?.terminalLocation != null) {
      this.terminalLocation = new TerminalLocation(eventLog?.terminalLocation)
    }

    if (eventLog?.terminalStation != null) {
      this.terminalStation = new TerminalStation(eventLog?.terminalStation)
    }
  }

  public copy(): EventLog {
    return new EventLog(this)
  }
}
