import { DateFormatter } from '../formatters/dateFormatter'
import { IJsonIdentity } from '../interfaces/jsonIdentity'
import { Patron } from './patron'

export interface INote extends IJsonIdentity { 
  comment: string
  createdBy?: string
  createdDate: Date
  modifiedBy?: string
  modifiedDate: Date
  patron: Patron

  copy(): INote
  isEqual(note: Note): boolean
}

export class Note implements INote {

  public comment: string
  public createdBy?: string
  public createdDate: Date
  public id: number
  public modifiedBy?: string
  public modifiedDate: Date
  public patron: Patron


  constructor(note?: INote) {
    this.comment = note?.comment ?? ''
    this.createdBy = note?.createdBy
    this.createdDate = DateFormatter.parse(note?.createdDate, new Date())
    this.id = note?.id ?? 0
    this.modifiedBy = note?.modifiedBy
    this.modifiedDate = DateFormatter.parse(note?.modifiedDate, new Date())
    this.patron = new Patron(note?.patron)
  }

  public copy(): Note {
    return new Note(this)
  }

  public isEqual(note: Note) {
    return this.comment === note.comment
      && this.createdBy === note.createdBy
      && this.createdDate === note.createdDate
      && this.id === note.id
      && this.patron.id === note.patron.id
  }
}
