import { ApiRepository } from './apiRepository'
import { Paths } from '../constants/paths'
import { QueryParams } from '../api/queryParams'
import { TerminalLocation, ITerminalLocation } from '../models/terminalLocation'


export class TerminalLocationRepository extends ApiRepository<ITerminalLocation, TerminalLocation> {

  constructor(path = Paths.TERMINAL_LOCATIONS, type: new (i?: ITerminalLocation) => TerminalLocation = TerminalLocation) {
    super(path, type)
  }

  public async getTerminalLocations(queryParams: QueryParams) {
    return this.getResponse(this.path, queryParams)
  }
}
