export class Paths {

  public static AUDITS = 'audits'
  public static BANS = 'bans'
  public static BAN_SUGGESTIONS = 'bans/suggestions'
  public static BUILD = 'build'
  public static CHANGE_PASSWORD = 'auth/password/change'
  public static COMMUNICATIONS = 'communications'
  public static COUNT = 'count'
  public static CURRENT = 'current'
  public static DUPLICATES = 'duplicates'
  public static EVENTLOGS = 'eventlogs'
  public static FINGERPIN = 'fingerpins'
  public static FORGOT_PASSWORD = 'auth/password/forgot'
  public static GENDERS = 'genders'
  public static ID_TYPES = 'proofofids/types'
  public static IDQUERY = 'query'
  public static IDS = 'ids'
  public static LAST = 'last'
  public static LATEST = 'latest'
  public static LOGIN = 'auth/login'
  public static LOGIN_ACCESS = 'auth/login/accesscode'
  public static LOGOUT = 'auth/logout'
  public static LOOKUPS = 'lookups'
  public static MEDIA = 'media'
  public static MERGE = 'merge'
  public static MERGERS = 'mergers'
  public static METADATA = 'metadata'
  public static NOTES = 'notes'
  public static ORGANISATIONS = 'organisations'
  public static PATRON = 'patron'
  public static PATRONS = 'patrons'
  public static PERSONS = 'persons'
  public static POLICEREPORTS = 'PoliceReports'
  public static POLICE_REPORTS_APPROVE = 'approve'
  public static POLICE_REPORTS_REJECT = 'reject'
  public static PROOF_OF_ID = 'poi'
  public static RESEND_CODE = 'auth/resend'
  public static RESET_PASSWORD = 'auth/password/reset'
  public static REPLACE = 'replace'
  public static REPORTS = 'reports/auth/token'
  public static UPDATE = 'update'
  public static SEARCH = 'search'
  public static SEND = 'send'
  public static STATUSES = 'statuses'
  public static SUGGESTIONS = 'suggestions'
  public static TERMINAL_COMMENTS = 'terminals/comments'
  public static TERMINAL_LOCATIONS = 'terminals/locations'
  public static TERMINAL_OPERATORS = 'terminals/operators'
  public static TERMINAL_STATIONS = 'terminals/stations'
  public static TIMEFRAME = 'timeframe'
  public static TOOLBOX = 'toolbox'
  public static TYPES = 'types'
  public static UNSUBSCRIBE = 'unsubscribe'
  public static URL = 'url'
  public static USER = 'auth/user'
  public static USER_ROLES = 'users/roles'
  public static USERS = 'users'
  public static VALIDATE = 'auth/validate'
  public static VERIFICATIONS = 'verifications'
  public static VERIFY = 'verify'
}
